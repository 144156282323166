import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePlatform, useCreatePlatform, useUpdatePlatform, useDeletePlatform } from '../../hooks/usePlatforms';
import { Platform, TARGET_FIELDS, VALID_ACTIONS, ActionValueMapping, BenefitNameMapping } from '../../types/platform';
import { FiPlus, FiX, FiEdit, FiSave, FiEye } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useBenefits } from '../../hooks/useBenefits';

interface PlatformFormProps {
    mode: 'create' | 'edit';
}

interface FieldMapping {
    sourceColumn: string;
    targetField: string;
    defaultValue?: string;
    isRequired: boolean;
}


const PlatformForm: React.FC<PlatformFormProps> = ({ mode }) => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [isEditMode, setIsEditMode] = useState(mode === 'create');
    const [platform, setPlatform] = useState<Partial<Platform>>({
        name: '',
        sftp: {
            enabled: false,
            sftpCredentials: {
                host: '',
                username: '',
                password: '',
                port: 22
            }
        },
        contacts: [],
        address: {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            county: '',
            postcode: '',
            country: ''
        },
        fieldMappings: {
            hasHeaderRow: true,
            mappings: []
        },
        benefitMappings: [],
        actionMappings: []
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeTab, setActiveTab] = useState('sftp');
    const [fieldMappings, setFieldMappings] = useState<FieldMapping[]>([]);
    const [hasHeaderRow, setHasHeaderRow] = useState(true);
    const [benefitMappings, setBenefitMappings] = useState<BenefitNameMapping[]>([]);
    const [actionMappings, setActionMappings] = useState<ActionValueMapping[]>([]);

    const { data: platformData, isLoading: platformLoading, refetch } = usePlatform(id || '');
    const { benefits, loadingBenefits } = useBenefits();
    const createPlatformMutation = useCreatePlatform();
    const updatePlatformMutation = useUpdatePlatform();
    const deletePlatformMutation = useDeletePlatform();

    // Load platform data if in edit mode
    useEffect(() => {
        if (mode === 'edit' && id && platformData) {
            setPlatform(platformData);

            // Set field mappings if available
            if (platformData.fieldMappings) {
                setFieldMappings(platformData.fieldMappings.mappings || []);
                setHasHeaderRow(platformData.fieldMappings.hasHeaderRow);
            }

            // Set benefit mappings if available
            if (platformData.benefitMappings) {
                setBenefitMappings(platformData.benefitMappings);
            }

            // Set action mappings if available
            if (platformData.actionMappings) {
                setActionMappings(platformData.actionMappings);
            }
        }
    }, [id, mode, platformData]);

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setPlatform(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setPlatform(prev => ({
            ...prev,
            address: {
                ...prev.address!,
                [name]: value
            }
        }));
    };

    const handleSftpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setPlatform(prev => ({
            ...prev,
            sftp: {
                ...prev.sftp!,
                [name]: type === 'checkbox' ? checked : value,
                sftpCredentials: prev.sftp?.sftpCredentials || {
                    host: '',
                    username: '',
                    password: '',
                    port: 22
                }
            }
        }));
    };

    const handleCredentialsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPlatform(prev => ({
            ...prev,
            sftp: {
                ...prev.sftp!,
                sftpCredentials: {
                    ...prev.sftp?.sftpCredentials!,
                    [name]: name === 'port' ? parseInt(value) : value
                }
            }
        }));
    };

    const handleAddContact = () => {
        setPlatform(prev => ({
            ...prev,
            contacts: [...(prev.contacts || []), { name: '', surname: '', email: '', phone: '' }]
        }));
    };

    const handleContactChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPlatform(prev => {
            const updatedContacts = [...(prev.contacts || [])];
            updatedContacts[index] = { ...updatedContacts[index], [name]: value };
            return { ...prev, contacts: updatedContacts };
        });
    };

    const handleRemoveContact = (index: number) => {
        setPlatform(prev => {
            const newContacts = [...(prev.contacts || [])];
            newContacts.splice(index, 1);
            return { ...prev, contacts: newContacts };
        });
    };

    const handleAddMapping = () => {
        setFieldMappings([
            ...fieldMappings,
            {
                sourceColumn: '',
                targetField: '',
                isRequired: false
            }
        ]);
    };

    const handleRemoveMapping = (index: number) => {
        const newMappings = [...fieldMappings];
        newMappings.splice(index, 1);
        setFieldMappings(newMappings);
    };

    const handleMappingChange = (index: number, field: keyof FieldMapping, value: string | boolean) => {
        const newMappings = [...fieldMappings];
        newMappings[index] = {
            ...newMappings[index],
            [field]: value
        };
        setFieldMappings(newMappings);
    };

    const handleAddBenefitMapping = () => {
        setBenefitMappings([
            ...benefitMappings,
            {
                platformBenefitName: '',
                benefitId: ''
            }
        ]);
    };

    const handleRemoveBenefitMapping = (index: number) => {
        const newMappings = [...benefitMappings];
        newMappings.splice(index, 1);
        setBenefitMappings(newMappings);
    };

    const handleBenefitMappingChange = (index: number, field: keyof BenefitNameMapping, value: string) => {
        const newMappings = [...benefitMappings];
        newMappings[index] = {
            ...newMappings[index],
            [field]: value
        };
        setBenefitMappings(newMappings);
    };

    const handleAddActionMapping = () => {
        setActionMappings([
            ...actionMappings,
            {
                platformValue: '',
                standardAction: 'CREATE'
            }
        ]);
    };

    const handleRemoveActionMapping = (index: number) => {
        const newMappings = [...actionMappings];
        newMappings.splice(index, 1);
        setActionMappings(newMappings);
    };

    const handleActionMappingChange = (index: number, field: keyof ActionValueMapping, value: string) => {
        const newMappings = [...actionMappings];
        newMappings[index] = {
            ...newMappings[index],
            [field]: field === 'standardAction' ? value as typeof VALID_ACTIONS[number] : value
        };
        setActionMappings(newMappings);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsSubmitting(true);

            // Prepare platform data with field mappings
            const platformData = {
                ...platform,
                fieldMappings: {
                    hasHeaderRow,
                    mappings: fieldMappings
                },
                benefitMappings: benefitMappings,
                actionMappings: actionMappings
            };

            if (mode === 'create') {
                // Generate a unique ID for new platforms if needed
                const newPlatform = {
                    ...platformData,
                    id: platformData.id || platformData.name?.toUpperCase().replace(/\s+/g, '') || ''
                };
                await createPlatformMutation.mutateAsync(newPlatform as Platform);
                toast.success('Platform created successfully');
                navigate('/platforms');
            } else if (id) {
                await updatePlatformMutation.mutateAsync(platformData as Platform);
                toast.success('Platform updated successfully');
                setIsEditMode(false);
                refetch();
            }
        } catch (error) {
            console.error("Error saving platform:", error);
            toast.error('Failed to save platform');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this platform?')) {
            deletePlatformMutation.mutate(id!, {
                onSuccess: () => navigate('/platforms')
            });
        }
    };

    const getTargetFieldOptions = () => {
        const options = [];

        for (const category in TARGET_FIELDS) {
            const fields = TARGET_FIELDS[category as keyof typeof TARGET_FIELDS];

            options.push(
                <optgroup key={category} label={category.charAt(0).toUpperCase() + category.slice(1)}>
                    {fields.map(field => (
                        <option key={`${category}.${field}`} value={`${category}.${field}`}>
                            {field}
                        </option>
                    ))}
                </optgroup>
            );
        }

        return options;
    };

    if (mode === 'edit' && platformLoading) {
        return <div className="p-6">Loading platform data...</div>;
    }

    // Field classes based on edit mode
    const fieldClass = isEditMode
        ? "mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        : "mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md shadow-sm p-2 text-gray-700";

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold">{mode === 'create' ? 'Create Platform' : platform.name}</h1>
                <div className="flex space-x-2">
                    {mode === 'edit' && (
                        <>{isEditMode &&
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="bg-red/60 hover:bg-red text-white px-4 py-2 rounded-md"
                            >
                                Delete Platform
                            </button>
                        }
                            <button
                                type="button"
                                onClick={toggleEditMode}
                                className={`flex items-center px-4 py-2 rounded-md ${isEditMode
                                    ? "bg-gray-500 hover:bg-gray-600 text-white"
                                    : "bg-blue-500 hover:bg-blue-600 text-white"
                                    }`}
                            >
                                {isEditMode ? (
                                    <>
                                        <FiEye className="mr-2" /> View Mode
                                    </>
                                ) : (
                                    <>
                                        <FiEdit className="mr-2" /> Edit Mode
                                    </>
                                )}
                            </button>

                        </>
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-8">
                {/* Always Visible Sections */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden p-6">
                    {/* Basic Information */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="id" className="block text-sm font-medium text-gray-700 mb-1">
                                    Platform ID
                                </label>
                                <input
                                    type="text"
                                    id="id"
                                    name="id"
                                    value={platform.id || ''}
                                    onChange={handleChange}
                                    className={fieldClass}
                                    placeholder="PLATFORMID"
                                    disabled={mode === 'edit' || !isEditMode}
                                    required
                                />
                                {mode === 'create' && (
                                    <p className="text-sm text-gray-500 mt-1">
                                        The ID will be automatically generated if left blank
                                    </p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                                    Platform Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={platform.name || ''}
                                    onChange={handleChange}
                                    className={fieldClass}
                                    placeholder="Platform Name"
                                    disabled={!isEditMode}
                                    required
                                />
                            </div>
                        </div>
                    </div>


                    {/* Contacts Section */}
                    <div className="mb-8">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Contacts</h2>
                            {isEditMode && (
                                <button
                                    type="button"
                                    onClick={handleAddContact}
                                    className="bg-blue-500 text-white p-2 rounded-md flex items-center text-sm"
                                >
                                    <FiPlus className="mr-1" /> Add Contact
                                </button>
                            )}
                        </div>

                        {(platform.contacts || []).length === 0 ? (
                            <div className="text-center p-4 border border-gray-200 rounded-md">
                                <p className="text-gray-500">No contacts added yet. {isEditMode ? 'Click "Add Contact" to add a contact.' : ''}</p>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                {(platform.contacts || []).map((contact, index) => (
                                    <div key={index} className="border border-gray-200 rounded-md p-4 relative">
                                        {isEditMode && (
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveContact(index)}
                                                className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                                            >
                                                <FiX className="h-5 w-5" />
                                            </button>
                                        )}
                                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={contact.name || ''}
                                                    onChange={(e) => handleContactChange(index, e)}
                                                    className={fieldClass}
                                                    placeholder="First Name"
                                                    disabled={!isEditMode}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Surname
                                                </label>
                                                <input
                                                    type="text"
                                                    name="surname"
                                                    value={contact.surname || ''}
                                                    onChange={(e) => handleContactChange(index, e)}
                                                    className={fieldClass}
                                                    placeholder="Surname"
                                                    disabled={!isEditMode}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={contact.email || ''}
                                                    onChange={(e) => handleContactChange(index, e)}
                                                    className={fieldClass}
                                                    placeholder="contact@example.com"
                                                    disabled={!isEditMode}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    Phone
                                                </label>
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    value={contact.phone || ''}
                                                    onChange={(e) => handleContactChange(index, e)}
                                                    className={fieldClass}
                                                    placeholder="+1 (123) 456-7890"
                                                    disabled={!isEditMode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Address Section */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4">Address</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700 mb-1">
                                    Address Line 1
                                </label>
                                <input
                                    type="text"
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={platform.address?.addressLine1 || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="Address Line 1"
                                    disabled={!isEditMode}
                                />
                            </div>

                            <div>
                                <label htmlFor="addressLine2" className="block text-sm font-medium text-gray-700 mb-1">
                                    Address Line 2
                                </label>
                                <input
                                    type="text"
                                    id="addressLine2"
                                    name="addressLine2"
                                    value={platform.address?.addressLine2 || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="Address Line 2"
                                    disabled={!isEditMode}
                                />
                            </div>

                            <div>
                                <label htmlFor="addressLine3" className="block text-sm font-medium text-gray-700 mb-1">
                                    Address Line 3
                                </label>
                                <input
                                    type="text"
                                    id="addressLine3"
                                    name="addressLine3"
                                    value={platform.address?.addressLine3 || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="Address Line 3"
                                    disabled={!isEditMode}
                                />
                            </div>

                            <div>
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
                                    City
                                </label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={platform.address?.city || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="City"
                                    disabled={!isEditMode}
                                />
                            </div>

                            <div>
                                <label htmlFor="county" className="block text-sm font-medium text-gray-700 mb-1">
                                    County
                                </label>
                                <input
                                    type="text"
                                    id="county"
                                    name="county"
                                    value={platform.address?.county || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="County"
                                    disabled={!isEditMode}
                                />
                            </div>

                            <div>
                                <label htmlFor="postcode" className="block text-sm font-medium text-gray-700 mb-1">
                                    Postcode
                                </label>
                                <input
                                    type="text"
                                    id="postcode"
                                    name="postcode"
                                    value={platform.address?.postcode || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="Postcode"
                                    disabled={!isEditMode}
                                />
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                                    Country
                                </label>
                                <input
                                    type="text"
                                    id="country"
                                    name="country"
                                    value={platform.address?.country || ''}
                                    onChange={handleAddressChange}
                                    className={fieldClass}
                                    placeholder="Country"
                                    disabled={!isEditMode}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {/* Tab Sections */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                    <div className="border-b border-gray-200">
                        <nav className="flex">
                            <button
                                type="button"
                                className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${activeTab === 'sftp'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }`}
                                onClick={() => setActiveTab('sftp')}
                            >
                                SFTP Settings
                            </button>
                            <button
                                type="button"
                                className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${activeTab === 'fieldMapping'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }`}
                                onClick={() => setActiveTab('fieldMapping')}
                            >
                                Field Mapping
                            </button>
                            <button
                                type="button"
                                className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${activeTab === 'benefitMapping'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }`}
                                onClick={() => setActiveTab('benefitMapping')}
                            >
                                Benefit Mapping
                            </button>
                            <button
                                type="button"
                                className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${activeTab === 'actionMapping'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }`}
                                onClick={() => setActiveTab('actionMapping')}
                            >
                                Action Mapping
                            </button>
                        </nav>
                    </div>

                    <div className="p-6">
                        {activeTab === 'sftp' && (
                            <div className="grid grid-cols-1 gap-6">
                                <div className="col-span-1">
                                    <h2 className="text-xl font-semibold mb-4">SFTP Configuration</h2>
                                </div>

                                <div className="col-span-1">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="enabled"
                                            name="enabled"
                                            checked={platform.sftp?.enabled || false}
                                            onChange={handleSftpChange}
                                            className="mr-2"
                                            disabled={!isEditMode}
                                        />
                                        <span className="block text-sm font-medium text-gray-700">Enable SFTP</span>
                                    </label>
                                </div>

                                {platform.sftp?.enabled && (
                                    <>
                                        <div>
                                            <label htmlFor="host" className="block text-sm font-medium text-gray-700 mb-1">
                                                SFTP Host
                                            </label>
                                            <input
                                                type="text"
                                                id="host"
                                                name="host"
                                                value={platform.sftp?.sftpCredentials?.host || ''}
                                                onChange={handleCredentialsChange}
                                                className={fieldClass}
                                                placeholder="sftp.example.com"
                                                disabled={!isEditMode}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="port" className="block text-sm font-medium text-gray-700 mb-1">
                                                SFTP Port
                                            </label>
                                            <input
                                                type="number"
                                                id="port"
                                                name="port"
                                                value={platform.sftp?.sftpCredentials?.port || 22}
                                                onChange={handleCredentialsChange}
                                                className={fieldClass}
                                                placeholder="22"
                                                disabled={!isEditMode}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                                                SFTP Username
                                            </label>
                                            <input
                                                type="text"
                                                id="username"
                                                name="username"
                                                value={platform.sftp?.sftpCredentials?.username || ''}
                                                onChange={handleCredentialsChange}
                                                className={fieldClass}
                                                placeholder="username"
                                                disabled={!isEditMode}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                                SFTP Password
                                            </label>
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={platform.sftp?.sftpCredentials?.password || ''}
                                                onChange={handleCredentialsChange}
                                                className={fieldClass}
                                                placeholder="••••••••"
                                                disabled={!isEditMode}
                                                required
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        {activeTab === 'fieldMapping' && (
                            <div className="grid grid-cols-1 gap-6">
                                <div className="col-span-1">
                                    <h2 className="text-xl font-semibold mb-4">Field Mapping Configuration</h2>
                                    <p className="text-gray-600 mb-6">
                                        Configure how data from this platform's files should be mapped to your system.
                                        These mappings will be used to process any uploaded application files.
                                    </p>

                                    <div className="mb-4">
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={hasHeaderRow}
                                                onChange={() => isEditMode && setHasHeaderRow(!hasHeaderRow)}
                                                className="mr-2"
                                                disabled={!isEditMode}
                                            />
                                            Files from this platform contain header rows
                                        </label>
                                    </div>

                                    <div className="mb-4 flex justify-between items-center">
                                        <h3 className="text-lg font-semibold">Field Mappings</h3>
                                        {isEditMode && (
                                            <button
                                                type="button"
                                                onClick={handleAddMapping}
                                                className="bg-blue-500 text-white p-2 rounded-md flex items-center text-sm"
                                            >
                                                <FiPlus className="mr-1" /> Add Mapping
                                            </button>
                                        )}
                                    </div>

                                    <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white border border-gray-200 rounded-md">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Source Column
                                                    </th>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Target Field
                                                    </th>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Default Value
                                                    </th>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Required
                                                    </th>
                                                    {isEditMode && (
                                                        <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Action
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fieldMappings.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={isEditMode ? 5 : 4} className="py-4 text-center text-gray-500">
                                                            No field mappings configured. {isEditMode ? 'Click "Add Mapping" to create one.' : ''}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    fieldMappings.map((mapping, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                            <td className="py-2 px-4 border-b">
                                                                <input
                                                                    type="text"
                                                                    value={mapping.sourceColumn}
                                                                    onChange={(e) => handleMappingChange(index, 'sourceColumn', e.target.value)}
                                                                    className={fieldClass}
                                                                    placeholder="Column name in file"
                                                                    disabled={!isEditMode}
                                                                />
                                                            </td>
                                                            <td className="py-2 px-4 border-b">
                                                                <select
                                                                    value={mapping.targetField}
                                                                    onChange={(e) => handleMappingChange(index, 'targetField', e.target.value)}
                                                                    className={fieldClass}
                                                                    disabled={!isEditMode}
                                                                >
                                                                    <option value="">Select a field</option>
                                                                    {getTargetFieldOptions()}
                                                                </select>
                                                            </td>
                                                            <td className="py-2 px-4 border-b">
                                                                <input
                                                                    type="text"
                                                                    value={mapping.defaultValue || ''}
                                                                    onChange={(e) => handleMappingChange(index, 'defaultValue', e.target.value)}
                                                                    placeholder="Default value (optional)"
                                                                    className={fieldClass}
                                                                    disabled={!isEditMode}
                                                                />
                                                            </td>
                                                            <td className="py-2 px-4 border-b text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={mapping.isRequired}
                                                                    onChange={(e) => handleMappingChange(index, 'isRequired', e.target.checked)}
                                                                    className="h-4 w-4"
                                                                    disabled={!isEditMode}
                                                                />
                                                            </td>
                                                            {isEditMode && (
                                                                <td className="py-2 px-4 border-b">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleRemoveMapping(index)}
                                                                        className="text-red-500 hover:text-red-700 p-1"
                                                                    >
                                                                        <FiX className="h-5 w-5" />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === 'benefitMapping' && (
                            <div className="grid grid-cols-1 gap-6">
                                <div className="col-span-1">
                                    <h2 className="text-xl font-semibold mb-4">Benefit Mapping Configuration</h2>
                                    <p className="text-gray-600 mb-6">
                                        Configure how benefit names from this platform should be mapped to your system.
                                        These mappings will be used to process any uploaded application files.
                                    </p>

                                    <div className="mb-4 flex justify-between items-center">
                                        <h3 className="text-lg font-semibold">Benefit Mappings</h3>
                                        {isEditMode && (
                                            <button
                                                type="button"
                                                onClick={handleAddBenefitMapping}
                                                className="bg-blue-500 text-white p-2 rounded-md flex items-center text-sm"
                                            >
                                                <FiPlus className="mr-1" /> Add Mapping
                                            </button>
                                        )}
                                    </div>

                                    <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white border border-gray-200 rounded-md">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Platform Benefit Name
                                                    </th>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Benefit ID
                                                    </th>
                                                    {isEditMode && (
                                                        <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Action
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {benefitMappings.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={isEditMode ? 3 : 2} className="py-4 text-center text-gray-500">
                                                            No benefit mappings configured. {isEditMode ? 'Click "Add Mapping" to create one.' : ''}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    benefitMappings.map((mapping, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                            <td className="py-2 px-4 border-b">
                                                                <input
                                                                    type="text"
                                                                    value={mapping.platformBenefitName}
                                                                    onChange={(e) => handleBenefitMappingChange(index, 'platformBenefitName', e.target.value)}
                                                                    className={fieldClass}
                                                                    placeholder="Platform benefit name"
                                                                    disabled={!isEditMode}
                                                                />
                                                            </td>
                                                            <td className="py-2 px-4 border-b">
                                                                <select
                                                                    value={mapping.benefitId}
                                                                    onChange={(e) => handleBenefitMappingChange(index, 'benefitId', e.target.value)}
                                                                    className={fieldClass}
                                                                    disabled={!isEditMode || loadingBenefits}
                                                                >
                                                                    <option value="">Select a benefit</option>
                                                                    {loadingBenefits ? (
                                                                        <option disabled>Loading benefits...</option>
                                                                    ) : benefits && benefits.length > 0 ? (
                                                                        benefits.map(benefit => (
                                                                            <option key={benefit.id} value={benefit.id}>
                                                                                {benefit.name} ({benefit.partnerName})
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option disabled>No benefits available</option>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            {isEditMode && (
                                                                <td className="py-2 px-4 border-b">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleRemoveBenefitMapping(index)}
                                                                        className="text-red-500 hover:text-red-700 p-1"
                                                                    >
                                                                        <FiX className="h-5 w-5" />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === 'actionMapping' && (
                            <div className="grid grid-cols-1 gap-6">
                                <div className="col-span-1">
                                    <h2 className="text-xl font-semibold mb-4">Action Mapping Configuration</h2>
                                    <p className="text-gray-600 mb-6">
                                        Configure how action values from this platform should be mapped to your system.
                                        These mappings will be used to process any uploaded application files.
                                    </p>

                                    <div className="mb-4 flex justify-between items-center">
                                        <h3 className="text-lg font-semibold">Action Mappings</h3>
                                        {isEditMode && (
                                            <button
                                                type="button"
                                                onClick={handleAddActionMapping}
                                                className="bg-blue-500 text-white p-2 rounded-md flex items-center text-sm"
                                            >
                                                <FiPlus className="mr-1" /> Add Mapping
                                            </button>
                                        )}
                                    </div>

                                    <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white border border-gray-200 rounded-md">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Platform Value
                                                    </th>
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Standard Action
                                                    </th>
                                                    {isEditMode && (
                                                        <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Action
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {actionMappings.length === 0 ? (
                                                    <tr>
                                                        <td colSpan={isEditMode ? 3 : 2} className="py-4 text-center text-gray-500">
                                                            No action mappings configured. {isEditMode ? 'Click "Add Mapping" to create one.' : ''}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    actionMappings.map((mapping, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                            <td className="py-2 px-4 border-b">
                                                                <input
                                                                    type="text"
                                                                    value={mapping.platformValue}
                                                                    onChange={(e) => handleActionMappingChange(index, 'platformValue', e.target.value)}
                                                                    className={fieldClass}
                                                                    placeholder="Platform value"
                                                                    disabled={!isEditMode}
                                                                />
                                                            </td>
                                                            <td className="py-2 px-4 border-b">
                                                                <select
                                                                    value={mapping.standardAction}
                                                                    onChange={(e) => handleActionMappingChange(index, 'standardAction', e.target.value as typeof VALID_ACTIONS[number])}
                                                                    className={fieldClass}
                                                                    disabled={!isEditMode}
                                                                >
                                                                    {VALID_ACTIONS.map(action => (
                                                                        <option key={action} value={action}>{action}</option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            {isEditMode && (
                                                                <td className="py-2 px-4 border-b">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleRemoveActionMapping(index)}
                                                                        className="text-red-500 hover:text-red-700 p-1"
                                                                    >
                                                                        <FiX className="h-5 w-5" />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={() => navigate('/platforms')}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                    >
                        Cancel
                    </button>
                    {isEditMode && (
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Saving...' : 'Save Platform'}
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default PlatformForm; 