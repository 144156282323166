import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { APPLICATIONS_CACHE_KEY, APPLICATIONS_CACHE_TIMESTAMP_KEY } from '../utils/util';
import { DateTime } from 'luxon';

// Type definitions
export interface ApiClientConfig {
    baseURL: string;
    timeout?: number;
    headers?: Record<string, string>;
}

// Add these interfaces at the top of the file
interface ApiResponse {
    success?: boolean;
    data?: any;
    message?: string;
}

interface ApiErrorResponse {
    message: string;
    status?: number;
    code?: string;
}

// Create a class for the API client
export class ApiClient {
    private instance: AxiosInstance;
    private isRefreshing = false;
    private failedQueue: any[] = [];
    private tokenCheckPromise: Promise<void> | null = null;
    private static activeRequests = 0;
    private static requestInProgressCallback: ((inProgress: boolean) => void) | null = null;

    constructor(config: ApiClientConfig) {
        this.instance = axios.create({
            baseURL: config.baseURL,
            timeout: config.timeout || 30000,
            headers: {
                'Content-Type': 'application/json',
                ...config.headers,
            },
        });

        this.setupInterceptors();
    }

    // Static method to set the callback
    public static setRequestInProgressCallback(callback: (inProgress: boolean) => void) {
        ApiClient.requestInProgressCallback = callback;
    }

    private static updateRequestCount(increment: boolean) {
        this.activeRequests = Math.max(0, this.activeRequests + (increment ? 1 : -1));
        this.requestInProgressCallback?.(this.activeRequests > 0);
    }

    private isTokenExpired(): boolean {
        const token = localStorage.getItem('sessionToken');
        if (!token) return true;

        try {
            const tokenPayload = JSON.parse(atob(token.split('.')[1]));
            const expiryDate = DateTime.fromSeconds(tokenPayload.exp);
            return expiryDate < DateTime.now();
        } catch (e) {
            console.error("Failed to parse token:", e);
            return true;
        }
    }

    private async checkTokenAndProceed(): Promise<void> {
        if (this.tokenCheckPromise) {
            return this.tokenCheckPromise;
        }

        this.tokenCheckPromise = new Promise<void>((resolve, reject) => {
            if (this.isTokenExpired()) {
                localStorage.removeItem('sessionToken');
                localStorage.removeItem('userData');
                localStorage.removeItem(APPLICATIONS_CACHE_KEY);
                localStorage.removeItem(APPLICATIONS_CACHE_TIMESTAMP_KEY);
                window.location.href = '/';
                reject(new Error('Token expired'));
            } else {
                resolve();
            }
        });

        try {
            await this.tokenCheckPromise;
        } finally {
            this.tokenCheckPromise = null;
        }
    }

    private setupInterceptors(): void {
        // Request interceptor
        this.instance.interceptors.request.use(
            async (config) => {
                ApiClient.updateRequestCount(true);

                // Skip token check for authentication endpoints
                if (config.url?.includes('authenticate')) {
                    return config;
                }

                try {
                    await this.checkTokenAndProceed();
                    const token = localStorage.getItem('sessionToken');
                    if (token) {
                        config.headers.Authorization = `Bearer ${token}`;
                    }
                    return config;
                } catch (error) {
                    ApiClient.updateRequestCount(false);
                    return Promise.reject(error);
                }
            },
            (error) => {
                ApiClient.updateRequestCount(false);
                return Promise.reject(error);
            }
        );

        // Response interceptor
        this.instance.interceptors.response.use(
            (response) => {
                ApiClient.updateRequestCount(false);
                return response;
            },
            (error: AxiosError<ApiErrorResponse>) => {
                ApiClient.updateRequestCount(false);

                // Handle rate limiting
                if (error.response?.status === 429) {
                    toast.error('Too many requests. Please try again later.', {
                        toastId: 'rate-limit-error',
                    });
                    return Promise.reject(error);
                }

                // Handle authentication errors
                if (error.response?.status === 401) {
                    const errorMessage = error.response.data?.message;
                    if (errorMessage === "EXPIRED" || errorMessage === "INVALID") {
                        // Clear auth data
                        localStorage.removeItem('sessionToken');
                        localStorage.removeItem('userData');

                        // Show a single notification
                        toast.info('Your session has expired. Please sign in again.', {
                            toastId: 'auth-expired',
                            autoClose: 5000,
                        });

                        // Redirect to login page
                        window.location.href = '/';
                        return Promise.reject(error);
                    }
                }

                // Handle other errors
                const errorMessage = error.response?.data?.message || 'An error occurred';
                toast.error(errorMessage, {
                    toastId: `error-${Date.now()}`,
                    autoClose: 3000,
                });

                return Promise.reject(error);
            }
        );
    }

    // Public methods for API calls
    public async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.instance.get<T>(url, config);
        return response.data;
    }

    public async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.instance.post<T>(url, data, config);
        return response.data;
    }

    public async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.instance.put<T>(url, data, config);
        return response.data;
    }

    public async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.instance.delete<T>(url, config);
        return response.data;
    }
}

// Create and export API client instances
export const dashboardApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/dashboard",
});

export const invoicingApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/invoice",
});

export const userApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/user",
});

export const applicationApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/application",
});

export const fulfillmentApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/fulfillment",
});

// Add these API client instances for the missing endpoints
export const sftpApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/sftp",
});

export const paymentApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/payment",
});

export const voucherApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/voucher",
});

export const salarySacrificeApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/salary-sacrifice",
});

export const fulfillmentBatchJobsApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/jobapi/fulfillment",
});
export const salarySacrificeBatchJobsApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/jobapi/salary-sacrifice",
});
export const invoicingBatchJobsApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/jobapi/invoicing",
});

// Add the benefits API client
export const benefitsApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/benefits",
});

// Add the providers API client
export const platformsApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/platforms",
});

// Add the analytics API client
export const analyticsAPI = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/analytics",
});

// Add any other API clients your application needs 