"use client";
import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { useUser } from "../../context/AppContext";

import { DateTime } from "luxon";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ApplicationHistory from "./ApplicationHistory";
import ResponsiveAppBar from "../../components/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import BatchProcessingToggle from '../../components/BatchProcessingToggle';
import CommunicationsTable from "../../components/CommunicationsTable/CommunicationsTable";
import LoadingIndicator from "../../components/LoadingIndicator";

const Application = () => {
  const {
    selectedApplication,
    setSelectedApplication,
    runningAllApplicationsQuery,
    setRunningAllApplicationsQuery,
    selectedCompany,
    resendHRApprovalEmail,
    disburseApplication,
    flattenedCompanies,
    companies,
    setSelectedCompany,
    cancelApplication,
    setApplicationBatchProcessing,
    reassignApplication,
    flattenedApplications,
    fetchAndSetApplication,
    resendInformationRequiredEmployeeEmail,
    getCompanyNamesAndIDs,
    companyNamesAndIDs
  } = useUser();
  const localStorageKey = "form_data";
  const [formData, setFormData] = useState<any>({ surname: "", email: "", reqError: "", twoFACode: "" });
  const [loading, setLoading] = useState(false);
  const [showCancellationConfirmation, setShowCancellationConfirmation] = useState(false);
  const routeParams = useParams();
  const navigate = useNavigate();

  // const [selectedApplication, setSelectedApplication] = useState<any>();
  const [formErrors, setFormErrors] = useState<any>({});
  const [initialised, setInitialised] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [selectedNewCompanyId, setSelectedNewCompanyId] = useState("");
  const [reassignLoading, setReassignLoading] = useState(false);

  useEffect(() => {
    if ((!initialised || !selectedApplication) && routeParams?.applicationid) {
      //   getApplication();
      if (flattenedApplications && flattenedApplications.length > 0 && flattenedApplications.filter((a: any) => a.id == routeParams?.applicationid)[0]) {
        setSelectedApplication(flattenedApplications.filter((a: any) => a.id == routeParams?.applicationid)[0]);
      } else {
        fetchAndSetApplication(routeParams?.applicationid);
      }
      setInitialised(true);
    }
  }, []);

  useEffect(() => {
    if (flattenedApplications && flattenedApplications.length > 0 && !selectedApplication) {
      console.log("THIS IS THE APPLICATION: " + "");
      setSelectedApplication(flattenedApplications.filter((a: any) => a.id == routeParams?.applicationid)[0]);
    }
  }, [flattenedApplications]);

  useEffect(() => {
    if (companies && companies.length > 0 && selectedApplication) {
      const foundCompany = companies.find((company: any) =>
        company.companyID === selectedApplication.company.companyID
      );

      if (foundCompany) {
        setSelectedCompany(foundCompany);
      } else {
        console.log("Company not found. All company IDs:",
          companies.map((c: any) => c.companyID)
        );
      }
    }
  }, [companies, selectedApplication]);

  useEffect(() => {
    if (selectedApplication) {
      document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + `Admin | ${selectedApplication?.id}`;
    }
  }, [selectedApplication]);



  const actionButton = (image: string, text: string, click: any, useGreenBG?: boolean) => {
    return (
      <div
        onClick={click}
        className={`flex flex-row w-full items-center justify-center py-1  rounded-lg h-full  ${useGreenBG
          ? `hover:bg-white border-2 bg-secondary/50 border-secondary`
          : `bg-lightgrey border border-white hover:bg-white`
          }  transition-all duration-200 hover:cursor-pointer`}
      >
        <img src={image} className="h-8 w-8 object-cover p-2" />
        <p className="text-base">{text}</p>
      </div>
    );
  };


  const variants = {
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.3 },
      },
    },
    visible: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.3, delay: 0.25 },
      },
    },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const handleBatchProcessingToggle = async (enabled: boolean) => {
    try {
      const updatedApplication = await setApplicationBatchProcessing(
        selectedApplication.id,
        enabled
      );
      setSelectedApplication(updatedApplication);
    } catch (error) {
      // Error already handled in the hook
    }
  };

  const batchProcessingToggle = selectedApplication && (
    <BatchProcessingToggle
      isEnabled={selectedApplication.processInBatch !== false}
      onToggle={handleBatchProcessingToggle}
      label="Batch Processing"
    />

  );

  return (
    <div className="flex flex-col w-full mx-2 md:mx-4 my-5">
      <ResponsiveAppBar />

      {
        selectedApplication && (
          <div className={`bg-white flex flex-col`}>
            <div className={`bg-white flex flex-col  w-full max-w-[1024px] mx-auto`}>

            </div>
            <div className={`p-8 w-full max-w-[900px] mx-auto font-montserrat bg-white z-1 flex flex-col   `}>

              <ProgressBar
                currentStep={
                  selectedApplication.status == "ACTIVE"
                    ? 4
                    : selectedApplication.status == "COMPLETED"
                      ? 4
                      : selectedApplication.status == "INFORMATION_REQUIRED"
                        ? 2
                        : selectedApplication.status == "AWAITING_ACTIVATION"
                          ? 3
                          : selectedApplication.status == "AWAITING_EMPLOYER_SIGNATURE"
                            ? 1
                            : 0
                }
                stepsCustom={[
                  "Processing",
                  "Employer signature",
                  "Confirm Email",
                  "Activating",
                  selectedApplication.status == "COMPLETED" ? "Completed" : "Active",
                ]}
                stepClicked={(index: any) => { }}
                cancelled={selectedApplication.status == "CANCELLED"}
                cancelledDateTime={selectedApplication.cancellationDateTime}
                cancellationReason={selectedApplication.cancellationReason}
              />
            </div>

            <div
              className={`px-4 md:p-0 mb-4 max-w-[1024px] mx-auto font-montserrat bg-white z-1 flex flex-col w-full opacity-100  duration-200 transform`}
            >
              <div className="border mt-2 w-full"></div>
              <div className="flex flex-col  gap-2 p-2 pt-4 ">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 w-full justify-center rounded-lg">

                  {selectedCompany &&
                    <button onClick={() => { navigate(`/companies/${selectedCompany.companyID}`) }}>
                      <img src={require("../../assets/company.png")} className="w-7 h-7" />
                      <p>View {selectedCompany?.name}</p>
                    </button>}
                  {(selectedApplication?.status == "AWAITING_EMPLOYER_SIGNATURE" || selectedApplication?.status == "PROCESSING") && (
                    <button

                      onClick={resendHRApprovalEmail}
                      disabled={loading}
                    >
                      <img src={require("../../assets/signature.png")} className="w-6 h-6" />

                      <p> Resend HR email for approval</p>
                    </button>
                  )}
                  {(selectedApplication?.status == "INFORMATION_REQUIRED" || selectedApplication?.status == "PROCESSING") && (
                    <button

                      onClick={async () => {
                        const response = await resendInformationRequiredEmployeeEmail(selectedApplication.id)
                        if (response.success) {
                          toast.success("Information required email resent");
                        }
                      }}
                      disabled={loading}
                    >
                      <img src={require("../../assets/info.png")} className="w-6 h-6" />

                      <p> Resend information required email</p>
                    </button>
                  )}

                  {selectedApplication?.docusealSubmission?.signedContractLink && (
                    <a
                      href={selectedApplication?.docusealSubmission?.signedContractLink}
                      download
                      className={`flex w-full flex-row gap-2 items-center justify-start cursor-pointer font-montserrat px-3 rounded-md py-2 font-semibold bg-white text-darkgrey shadow-md  ${runningAllApplicationsQuery && `bg-white`
                        }`}
                      target="_blank"
                      style={{ display: "inline-block", textDecoration: "none" }}
                    >
                      <div className="flex flex-row gap-2 h-full w-full items-center justify-center">
                        <img src={require("../../assets/download.png")} className="w-6 h-6" />
                        <p>Download signed contract</p>
                      </div>{" "}
                    </a>
                  )}


                  {(selectedApplication?.status == "AWAITING_ACTIVATION" || selectedApplication?.status == "ACTIVE" || selectedApplication?.status == "PENDING_PRODUCT_CHANGE") &&
                    (
                      <button
                        className="font-montserrat font-semibold  bg-white flex flex-row gap-2"
                        onClick={disburseApplication}
                        disabled={loading}
                      >
                        <img src={require("../../assets/send.png")} className="w-6 h-6" />

                        <p >
                          {selectedApplication.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ? "Send Voucher" : "Mark as Completed"}
                        </p>
                      </button>
                    )}
                  {batchProcessingToggle}

                </div>
              </div>
              <div className="border mt-2 w-full"></div>
              <div className="flex flex-col mt-2 md:mt-4 ">
                {selectedApplication.referrerDetails?.referrerFirstName && <div className="flex flex-row items-center justify-center mb-2 gap-2 border-green-500 border-2 rounded-md  bg-green-200">
                  <h4 className="text-center font-bold">This application was referred by {selectedApplication.referrerDetails.referrerFirstName} - {selectedApplication.referrerDetails.userCode}</h4>
                </div>}
                {selectedApplication.autoApproved && <div className="flex flex-row items-center justify-center mb-2 gap-2 border-purple-500 border-2 rounded-md px-2 bg-purple-200">
                  <img src={require("../../assets/aiLogo.png")} className="w-6 h-6" /><h4 className="text-left font-bold">This application was automatically approved.</h4>
                </div>}
                <div className="flex flex-row flex-wrap justify-between">

                  <div className="flex flex-col flex-wrap justify-between">
                    <p className="font-medium text-base text-primary break-all text-left">Application #</p>
                    <p className="text-2xl font-bold text-primary mb-2 break-all text-left">{selectedApplication.id}</p>
                  </div>

                  <div className="flex flex-col flex-wrap justify-between">
                    {/* <h2>{selectedApplication.status}</h2> */}
                    <p className="font-medium text-base text-primary break-all text-left">Submitted on</p>
                    <p className="text-2xl font-bold text-primary mb-2 break-all text-left ">
                      {" "}
                      {DateTime.fromISO(selectedApplication.creationDateTime).toFormat("dd LLLL yyyy HH:mm:ss a")}
                    </p>
                  </div>
                </div>
                <div id="product">
                  <p className="font-medium text-lg text-primary break-all text-left">Product</p>
                  {selectedApplication.userProviderAccountDetails &&
                    selectedApplication.status == "AWAITING_ACTIVATION" && (
                      <div className="bg-secondary rounded-xl p-4">
                        <h4>
                          The following subscription package will be activated on the account with email{" "}
                          <span className="underline font-bold">
                            {selectedApplication.userProviderAccountDetails.email}
                          </span>
                        </h4>
                      </div>
                    )}{" "}
                  {selectedApplication.status == "PENDING_PRODUCT_CHANGE" && (
                    <div className="ml-2">
                      <p className="text-red  italic text-left ">There is a change pending on the active product.</p>
                    </div>
                  )}
                  <div
                    className={`flex flex-row items-center justify-center flex-wrap gap-2 my-2 border p-2 rounded-xl bg-primary`}
                    key="outer"
                  >
                    <div
                      className={` flex flex-col w-full relative p-4   rounded-lg items-start  transform duration-100 bg-white`}

                      key="mainProduct"
                    >
                      <div className="flex flex-row items-center">
                        {selectedApplication.benefit?.imageURL && (
                          <div
                            className={`h-10 w-10 min-w-[60px] min-h-[60px] md:h-20 md:w-20 m-2 rounded-md overflow-hidden  flex-none`}
                          >
                            <img src={selectedApplication.benefit?.imageURL} className="w-full h-full object-cover" />
                          </div>
                        )}
                        {selectedApplication.benefit && (
                          <div className="flex flex-col items-start">
                            <p className="text-lg md:text-2xl text-primary text-left font-montserrat font-[1000]">
                              {selectedApplication.benefit.name}
                            </p>
                            {/* <h4>{selectedApplication.benefit.heading}</h4> */}
                            <p className="text-sm text-left font-montserrat font-normal">
                              {selectedApplication.benefit.description}
                            </p>
                          </div>
                        )}
                      </div>
                      {selectedApplication.status == "PENDING_PRODUCT_CHANGE" && (
                        <div className="ml-2 flex flex-row items-center my-2">
                          <img className="ml-2 w-5 h-5" src={require("../../assets/info.png")} />

                          <p className="text-red font-bold ml-2 text-sm italic">
                            This product will expire on{" "}
                            {DateTime.fromISO(
                              selectedApplication.benefit.expiryDate ?? selectedApplication.pendingBenefit.effectiveDate
                            ).toFormat("dd LLL yyyy")}
                          </p>
                        </div>
                      )}

                      {selectedApplication.salarySacrificeTerms && (
                        <div className="ml-2 flex flex-row items-center my-2">


                          <p className="text-blue-500 font-medium text-sm italic">
                            {selectedApplication.salarySacrificeTerms.numberOfSacrifices >= 0 ? `The applicant will repay this product over ${selectedApplication.salarySacrificeTerms.numberOfSacrifices} salary sacrifices.` : `Salary sacrifices for this product will continue until the employee cancels the application.`}
                          </p>
                        </div>
                      )}
                      {/* {{ selectedApplication.salarySacrificeTermsOptions }} */}
                      {/* {selected && <img src={require("../../assets/accept.png")} className="absolute top-2 right-2 w-5 h-5 md:w-6 md:h-6" />} */}
                    </div>
                    {selectedApplication.status == "PENDING_PRODUCT_CHANGE" && selectedApplication.pendingBenefit && (
                      <div
                        className={` flex flex-col max-w-1/2 relative p-2  rounded-lg items-start  transform duration-100 
   ${selectedApplication.status == "PENDING_PRODUCT_CHANGE" ? `bg-green-100` : `bg-white`}`}
                        key="mainProduct"
                      >
                        <div className="flex flex-row items-center">
                          {selectedApplication.pendingBenefit?.imageURL && (
                            <div
                              className={`h-10 w-10 min-w-[60px] min-h-[60px] md:h-20 md:w-20 m-2 rounded-md overflow-hidden  flex-none`}
                            >
                              <img src={selectedApplication.pendingBenefit?.imageURL} className="w-full h-full object-cover" />
                            </div>
                          )}
                          {selectedApplication.pendingBenefit && (
                            <div className="flex flex-col items-start">
                              <p className="text-lg md:text-2xl text-primary text-left font-montserrat font-[1000]">
                                {selectedApplication.pendingBenefit.name}
                              </p>
                              {/* <h4>{selectedApplication.pendingBenefit.heading}</h4> */}
                              <p className="text-sm text-left font-montserrat font-normal">
                                {selectedApplication.pendingBenefit.description}
                              </p>
                            </div>
                          )}
                        </div>

                        <div className="ml-2 flex flex-row items-center my-2">
                          <img className="ml-2 w-5 h-5" src={require("../../assets/info.png")} />

                          <p className="text-darkgrey font-bold ml-2 text-sm italic">
                            This product will activate on{" "}
                            {DateTime.fromISO(selectedApplication.pendingBenefit.effectiveDate).toFormat("dd LLL yyyy")}
                          </p>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
                <div className="flex flex-row md:grid grid-cols-3 items-start w-full my-2  px-4 py-2 rounded-md gap-4 flex-wrap ">
                  <div className="flex flex-col items-start  bg-blue-100 p-4 pt-2 rounded-md h-full">
                    {selectedApplication.employee && (
                      <>
                        <h4 className="font-bold text-base text-primary break-all text-left">Employee</h4>
                        <div>
                          <p className="font-semibold text-base text-left ml-2">
                            {selectedApplication.employee.firstName + " " + selectedApplication.employee.surname}
                          </p>
                          {selectedApplication.employee.phone && (
                            <>
                              <p className="font-semibold text-base text-left ml-2">
                                {selectedApplication.employee.phone}
                              </p>
                            </>
                          )}
                          {selectedApplication.employee.email && (
                            <>
                              <p className="font-semibold text-base text-left ml-2">
                                {selectedApplication.employee.email}
                              </p>
                            </>
                          )}
                          {/* <p className="font-semibold text-base text-left">
                        Email: <p className="text-left">{selectedApplication.company.email}</p>
                      </p>{" "} */}
                          {selectedApplication.employee.address && (
                            <>
                              <p className="font-semibold text-base text-left ml-2">
                                {selectedApplication.employee.address}
                              </p>
                            </>
                          )}
                          {selectedApplication.employee.postcode && (
                            <>
                              <p className="font-semibold text-base text-left ml-2">
                                {selectedApplication.employee.postcode}
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}</div>
                  <div className="flex flex-col items-start bg-blue-100 col-span-2 p-4 pt-2 rounded-md gap-3 h-full flex-wrap relative">
                    {selectedApplication.status == "PROCESSING" && <button
                      className="absolute top-2 right-2 hover:opacity-70 transition-opacity"
                      onClick={() => {
                        if (!companyNamesAndIDs || companyNamesAndIDs.length == 0) { getCompanyNamesAndIDs() }
                        setShowReassignModal(true)
                      }}
                    >
                      <img src={require("../../assets/edit.png")} className="w-5 h-5" />
                    </button>
                    }
                    <h4 className="font-bold text-base text-primary break-all text-left">Employer - {selectedCompany?.name}</h4>
                    {selectedApplication.company && (
                      <>
                        <div className="ml-2">
                          <div className="flex flex-row flex-wrap w-full space-x-1">
                            {selectedCompany?.hrContacts.map((contact: any) => {
                              return (
                                <div className="flex flex-col px-2  ">
                                  {" "}
                                  <p className="font-semibold text-base text-left ml-2">{contact.name} - <strong>HR</strong></p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.email}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.phone}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="ml-2">
                          <div className="flex flex-row flex-wrap w-full space-x-1">
                            {selectedCompany?.financeContacts?.map((contact: any) => {
                              return (
                                <div className="flex flex-col px-2  ">
                                  {" "}
                                  <p className="font-semibold text-base text-left ml-2">{contact.name} - <strong>FIN</strong></p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.email}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.phone}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="ml-2">
                          <div className="flex flex-row flex-wrap w-full space-x-1">
                            {selectedCompany?.payrollContacts?.map((contact: any) => {
                              return (
                                <div className="flex flex-col px-2  ">
                                  {" "}
                                  <p className="font-semibold text-base text-left ml-2">{contact.name} - <strong>PAYROLL</strong></p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.email}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.phone}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <div className="flex flex-col items-start w-full border px-4 py-2 rounded-md bg-purple-100">
                  <div className="flex flex-col items-start">

                  </div>
                </div> */}
                {selectedApplication.status != "CANCELLED" && (
                  <div className="mt-4 flex flex-row">
                    <div className="sm:w-2/3" />
                    <div className="w-full sm:w-1/3 ">
                      {actionButton(require("../../assets/cancel.png"), "Cancel this application", () => {
                        setShowCancellationConfirmation(true);
                      })}
                    </div>
                  </div>
                )}
              </div>{" "}

              <h3>Communications</h3>
              <CommunicationsTable applicationId={selectedApplication.id} />

              <ApplicationHistory historyList={selectedApplication?.activityLog ?? []} />
            </div>
            <AnimatePresence>
              {showCancellationConfirmation && (
                <motion.div
                  key="overlay"
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={overlayVariants}
                  transition={{ duration: 0.2 }}
                >
                  {loading ? (
                    <motion.div
                      className="flex flex-col bg-white p-8 rounded-lg items-center justify-center shadow-lg m-2 gap-2"
                      key="loading"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <CircularProgress size={50} style={{ color: "#86eb85" }} />
                      <p>We're cancelling this product...</p>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="content"
                      className="flex flex-col bg-white p-8 rounded-lg shadow-lg m-2 gap-2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      {/* <div className="w-full h-3" /> */}
                      <h2>Application Cancellation</h2>
                      <p>Are you sure you would like to proceed with cancelling this application?</p>
                      <p>This cannot be undone.</p>

                      {/* {actionButton(
                  "accept.png",
                  "Change my product to " + selectedProductToChangeTo.name,
                  () => {
                    setLoading(true);
                    applicationService
                      .modifyApplication(selectedProductToChangeTo.id, selectedApplication.id)
                      .then((response) => {
                        console.log(response);
                        toast.success("Your selected product has been updated!");
                        setTimeout(() => {
                          setLoading(false);
                          getApplication();
                          setSelectedProductToChangeTo(null);
                          setShowProductChangeOptions(false);
                        }, 3000);
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error("An error occurred while updating your product. Please try again.");
                        setLoading(false);
                      });
                  },
                  true
                )} */}
                      <div className="w-full h-3" />
                      {actionButton(
                        require("../../assets/back.png"),
                        "Nevermind, go back",
                        () => {
                          setShowCancellationConfirmation(false);
                        },
                        true
                      )}
                      {actionButton(require("../../assets/cancel.png"), "Proceed with cancellation", async () => {
                        setLoading(true);
                        cancelApplication(selectedApplication.id)
                          .then((res: any) => {
                            console.log(res.data);
                            setLoading(false);
                            if (res.data.success == true) {
                              toast.success("The application has been cancelled!");
                              setSelectedApplication(res.data.finalCancelledApplication);
                              setShowCancellationConfirmation(false);
                            } else {
                              if (res.data.reason == "This application has already been cancelled") {
                                toast.info(
                                  "The application has already been cancelled. Refresh the page to see the updated status."
                                );
                                setShowCancellationConfirmation(false);
                              }
                            }
                          })
                          .catch((err: any) => {
                            console.log(err);
                            setLoading(false);
                            setShowCancellationConfirmation(false);
                          });
                        //   applicationService
                        //     .cancelApplication(selectedApplication.id)
                        //     .then((response) => {
                        //       console.log(response);
                        //       toast.success("Your application has been cancelled!");
                        //       setTimeout(() => {
                        //         setLoading(false);
                        //         getApplication();
                        //         setShowCancellationConfirmation(false);
                        //       }, 3000);
                        //     })
                        //     .catch((err) => {
                        //       console.log(err);
                        //       toast.error("An error occurred while updating your product. Please try again.");
                        //       setLoading(false);
                        //     });
                      })}
                    </motion.div>
                  )}
                </motion.div>
              )}
              {showReassignModal && (
                // <LoadingIndicator /> :
                <motion.div
                  key="overlay"
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={overlayVariants}
                  transition={{ duration: 0.2 }}
                >
                  {(!companyNamesAndIDs || companyNamesAndIDs.length == 0) ? <motion.div
                    key="content"
                    className="flex flex-col bg-white p-8 rounded-lg shadow-lg m-2 gap-4 max-w-md w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <LoadingIndicator />
                  </motion.div> :
                    <motion.div
                      key="content"
                      className="flex flex-col bg-white p-8 rounded-lg shadow-lg m-2 gap-4 max-w-md w-full"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h2 className="text-xl font-bold">Reassign Application</h2>
                      <p className="text-gray-600">Select a new company to reassign this application to:</p>

                      <select
                        className="w-full p-2 border rounded-md"
                        value={selectedNewCompanyId}
                        onChange={(e) => setSelectedNewCompanyId(e.target.value)}
                      >
                        <option value="">Select a company...</option>
                        {companyNamesAndIDs
                          .sort((a: any, b: any) => a.name.localeCompare(b.name))
                          .map((company: any) => (
                            <option key={company.companyID} value={company.companyID}>
                              {company.companyID} - {company.name}
                            </option>
                          ))}
                      </select>

                      <div className="flex justify-end gap-2 mt-4">
                        <button
                          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                          onClick={() => {
                            setShowReassignModal(false);

                            setSelectedNewCompanyId("");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="px-4 py-2 bg-primary text-white rounded-md hover:opacity-80 disabled:opacity-50"
                          disabled={!selectedNewCompanyId || reassignLoading}
                          onClick={async () => {
                            setReassignLoading(true);
                            try {
                              await reassignApplication(selectedApplication.id, selectedNewCompanyId);
                              toast.success("Application reassigned successfully");
                              setShowReassignModal(false);
                              // getApplication(); // Refresh the application data
                            } catch (error) {
                              toast.error("Failed to reassign application");
                            }
                            setReassignLoading(false);
                            setSelectedNewCompanyId("");
                          }}
                        >
                          {reassignLoading ? <CircularProgress size={20} /> : "Reassign"}
                        </button>
                      </div>
                    </motion.div>
                  }
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )

      }
    </div >
  );
};

export default Application;
