import { useCallback, useState, useEffect } from "react";
import { auth } from "../firebaseConfig";
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, signOut as firebaseSignOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { dashboardApi } from "../services/apiClient";
import { DateTime } from "luxon";

export const useAuth = () => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<any>(() => {
        const savedUserData = localStorage.getItem("userData");
        return savedUserData ? JSON.parse(savedUserData) : undefined;
    });
    const [signingIn, setSigningIn] = useState(false);
    const [logoutInProgress, setLogoutInProgress] = useState(false);

    const dashboardApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/dashboard";
    let tokenCheckPromise: Promise<unknown> | null = null;

    useEffect(() => {
        let mounted = true;

        const initAuth = async () => {
            const sessionToken = localStorage.getItem("sessionToken");
            const savedUserData = localStorage.getItem("userData");

            if (sessionToken && savedUserData && mounted) {
                const userData = JSON.parse(savedUserData);
                setCurrentUser(userData);
            } else if (mounted) {
                handleLogout();
            }
        };

        initAuth();

        return () => {
            mounted = false;
        };
    }, []);

    const getSessionToken = async (user: any) => {
        console.log(user)
        if (user) {
            setSigningIn(true);
            const token = await auth.currentUser?.getIdToken();

            try {
                const response = await dashboardApi.get('/authenticate', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                localStorage.setItem("sessionToken", response.sessionToken);
                setCurrentUser(response.data);
                localStorage.setItem("userData", JSON.stringify(response.data));
                navigate("/applications");
                setSigningIn(false);
            } catch (error) {
                console.error(error);
                toast("Invalid login attempt.", { type: "error" });
                setSigningIn(false);
            }
        }
    };

    const handleLogout = () => {
        setCurrentUser(null);
        setLogoutInProgress(true);
        firebaseSignOut(auth)
            .then(() => {
                toast("You have been signed out!", { type: "info" });
                localStorage.removeItem("sessionToken");
                localStorage.removeItem("cachedApplications");
                localStorage.removeItem("applicationsTimestamp");
                setCurrentUser(null);
                setSigningIn(false);
                navigate("/login");
            })
            .catch(error => {
                console.error("Error signing out: ", error);
            })
            .finally(() => {
                setTimeout(() => {
                    setLogoutInProgress(false);
                }, 5000);
            });
    };

    const signInWithGoogle = useCallback(() => {
        setSigningIn(true);
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                getSessionToken(user);
            })
            .catch((error) => {
                console.error("SignIn with Google error:", error.message);
                setSigningIn(false);
            });
    }, []);

    const signInWithEmailAndPassword = (email: string, password: string) => {
        setSigningIn(true);
        firebaseSignInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                getSessionToken(user);
            })
            .catch((error) => {
                setSigningIn(false);
                toast("Login failed.", { type: "error" });
                console.error(error.code, error.message);
            });
    };

    const checkTokenAndProceed = async () => {
        if (tokenCheckPromise) {
            console.log("Check already in progress - cancelling this one")
            return tokenCheckPromise; // If a check is already in progress, return the same promise
        }

        tokenCheckPromise = new Promise(async (resolve, reject) => {

            if (isTokenExpired()) {

                handleLogout();
                reject(new Error('Token expired'));
            } else {
                resolve(null); // Token is valid, proceed with requests
            }
        });

        try {
            await tokenCheckPromise;
        } finally {
            tokenCheckPromise = null; // Reset after the check is complete
        }
    };

    const isTokenExpired = () => {
        console.log("Checking if token is expired")

        const token = localStorage.getItem('sessionToken');
        if (!token) return true;
        console.log("Checking if token is expired2")

        try {
            const tokenPayload = JSON.parse(atob(token.split('.')[1]));
            const expiryDate = DateTime.fromSeconds(tokenPayload.exp);
            return expiryDate < DateTime.now();
        } catch (e) {
            console.error("Failed to parse token:", e);
            return true;
        }
    };

    return {
        currentUser,
        setCurrentUser,
        signingIn,
        setSigningIn,
        signInWithGoogle,
        signInWithEmailAndPassword,
        signOut: handleLogout,
        logoutInProgress,
        checkTokenAndProceed,

    };
}; 