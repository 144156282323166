import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import "./App.css";
import Layout from "./components/Layout/Layout";

import AllApplications from "./screens/AllApplications/AllApplications";
import { UserProvider } from "./context/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Companies from "./screens/Companies/Companies";
import Application from "./screens/Application/Application";
import ThinkingIndicator from "./components/ThinkingIndicator";
import React from "react";
import Fulfillments from "./screens/Fulfillments/Fulfillments";
import ViewFulfillment from "./screens/ViewFulfillment/ViewFulfillment";
import CompanyPage from "./screens/CompanyPage/CompanyPage";
import Login from "./screens/Login/Login";
import BatchJobs from "./screens/BatchJobs/BatchJobs";
import Benefits from './screens/Benefits/Benefits';
import BenefitForm from './screens/Benefits/BenefitForm';
import Analytics from './screens/Analytics/Analytics';
import Platforms from './screens/Platforms/Platforms';
import PlatformForm from './screens/Platforms/PlatformForm';
import PlatformCompanies from './screens/Platforms/PlatformCompanies';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />

              <Route path="/applications" element={<AllApplications />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/fulfillments" element={<Fulfillments />} />
              <Route path="/batch-jobs" element={<BatchJobs />} />

              <Route path="/fulfillments/:fulfillmentid" element={<ViewFulfillment />} />
              <Route path="/companies/:companyid" element={<CompanyPage />} />
              <Route path="/application/:applicationid" element={<Application />} />
              <Route path="/benefits" element={<Benefits />} />
              <Route path="/benefits/create" element={<BenefitForm mode="create" />} />
              <Route path="/benefits/edit/:id" element={<BenefitForm mode="edit" />} />\
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/platforms" element={<Platforms />} />
              <Route path="/platforms/create" element={<PlatformForm mode="create" />} />
              <Route path="/platforms/edit/:id" element={<PlatformForm mode="edit" />} />
              <Route path="/platforms/:id/companies" element={<PlatformCompanies />} />
            </Route>
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            limit={5}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <React.Fragment>
            <ThinkingIndicator />
          </React.Fragment>
        </UserProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
