import React, { useState } from 'react';
import { dashboardApi } from '../services/apiClient';
import { toast } from 'react-toastify';

interface Provider {
    id: string;
    name: string;
}

interface ProviderSelectorProps {
    selectedProviderId: string | null;
    onSelect: (providerId: string | null) => void;
    disabled?: boolean;
    showSelector: boolean;
}

const ProviderSelector: React.FC<ProviderSelectorProps> = ({
    selectedProviderId,
    onSelect,
    disabled = false,
    showSelector = false
}) => {
    const [providers, setProviders] = useState<Provider[]>([]);
    const [loading, setLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const fetchProviders = async () => {
        if (hasLoaded) return;

        setLoading(true);
        try {
            const response = await dashboardApi.get('/providers');
            if (response.success) {
                setProviders(response.providers);
                setHasLoaded(true);
            } else {
                toast.error('Failed to load providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            toast.error('Failed to load providers');
        } finally {
            setLoading(false);
        }
    };

    if (!showSelector) {
        return (
            <div className="text-sm text-gray-800">
                {selectedProviderId || 'None'}
            </div>
        );
    }

    if (showSelector && !hasLoaded && !loading) {
        fetchProviders();
    }

    return (
        <div className="flex items-center gap-2">
            <select
                value={selectedProviderId || ''}
                onChange={(e) => onSelect(e.target.value === '' ? null : e.target.value)}
                disabled={disabled || loading}
                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            >
                <option value="">No Provider</option>
                {providers.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                        {provider.name}
                    </option>
                ))}
            </select>
            {loading && (
                <div className="animate-spin h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"></div>
            )}
        </div>
    );
};

export default ProviderSelector; 