import React, { useState, useEffect, useMemo } from 'react';
import { Switch, ThemeProvider, createTheme } from '@mui/material';
import { toast } from 'react-toastify';
import ProviderSelector from './ProviderSelector';
import { useUser } from '../context/AppContext';
import { renderActiveIndicator, renderContactItem } from './DynamicTable/DynamicTable';
import EditContactsModal from './EditContactsModal';

// Create a theme with Montserrat font
const montserratTheme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: 'Montserrat, sans-serif',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Montserrat, sans-serif',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Montserrat, sans-serif',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    fontFamily: 'Montserrat, sans-serif',
                },
            },
        },
    },
});

interface CompanyOverviewCardProps {
    company: any;
    onEdit: (updatedCompany: any) => Promise<void>;
}

interface AddressFormData {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    county: string;
    country: string;
    postcode: string;
    [key: string]: string;  // Index signature to allow string indexing
}

const fontStyles = {
    fontFamily: 'Montserrat, sans-serif',
};


const CompanyOverviewCard: React.FC<CompanyOverviewCardProps> = ({
    company,
    onEdit,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [editingProvider, setEditingProvider] = useState(false);
    const [formData, setFormData] = useState({
        name: company.name || '',
        companyID: company.companyID || '',
        active: company.active || false,
        domains: company.domains || [],
        processInBatch: company.processInBatch || false,
        providerID: company.providerID || null,
        benefitsLinked: company.benefitsLinked || [],
    });

    const [addressFormData, setAddressFormData] = useState<AddressFormData>({
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        county: '',
        country: '',
        postcode: '',
    });

    const [newDomain, setNewDomain] = useState('');
    const [saving, setSaving] = useState(false);
    const [loadingBenefits, setLoadingBenefits] = useState(false);
    const [isEditingBenefits, setIsEditingBenefits] = useState(false);
    const { benefits: contextBenefits } = useUser();
    const [selectedBenefitIds, setSelectedBenefitIds] = useState<string[]>(company.benefitsLinked || []);

    useEffect(() => {
        if (company.addressObject) {
            setAddressFormData({
                addressLine1: company.addressObject.addressLine1 || '',
                addressLine2: company.addressObject.addressLine2 || '',
                addressLine3: company.addressObject.addressLine3 || '',
                county: company.addressObject.county || '',
                country: company.addressObject.country || '',
                postcode: company.addressObject.postcode || company.postcode || '',
            });
        } else {
            // If addressObject doesn't exist, try to parse from address string
            const postcode = company.postcode || '';

            setAddressFormData({
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                county: '',
                country: '',
                postcode: postcode,
            });
        }

        // Reset form data when company changes
        setFormData({
            name: company.name || '',
            companyID: company.companyID || '',
            active: company.active || false,
            domains: company.domains || [],
            processInBatch: company.processInBatch || false,
            providerID: company.providerID || null,
            benefitsLinked: company.benefitsLinked || [],
        });

        // Load benefits if the company has benefitsLinked
        if (company.benefitsLinked && company.benefitsLinked.length > 0) {
            fetchBenefits();
        }
    }, [company]);

    const fetchBenefits = () => {
        if (!company.benefitsLinked || company.benefitsLinked.length === 0) return;

        setLoadingBenefits(true);
        try {
            // Filter benefits to only include those linked to this company
            // const linkedBenefits = Object.values(allBenefits)
            //     .filter((benefit) => company.benefitsLinked.includes(benefit.id));
            // setBenefits(linkedBenefits as Benefit[]);
        } catch (error) {
            console.error('Error filtering benefits:', error);
        } finally {
            setLoadingBenefits(false);
        }
    };

    const handleBenefitsChange = (benefitIds: string[]) => {
        setFormData({
            ...formData,
            benefitsLinked: benefitIds
        });
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            // Create an object with only the changed fields
            const changedFields: any = {};

            // Compare each field and only include if changed
            if (formData.name !== company.name) changedFields.name = formData.name;
            if (formData.active !== company.active) changedFields.active = formData.active;
            if (formData.processInBatch !== company.processInBatch) changedFields.processInBatch = formData.processInBatch;
            if (formData.providerID !== company.providerID) changedFields.providerID = formData.providerID;

            // For arrays, do a deep comparison
            if (JSON.stringify(formData.domains) !== JSON.stringify(company.domains)) {
                changedFields.domains = formData.domains;
            }
            if (JSON.stringify(formData.benefitsLinked) !== JSON.stringify(company.benefitsLinked)) {
                changedFields.benefitsLinked = formData.benefitsLinked;
            }

            // For address, only include if something changed
            const currentAddress = company.addressObject || {};
            const hasAddressChanges = Object.keys(addressFormData).some(
                key => addressFormData[key] !== currentAddress[key]
            );
            if (hasAddressChanges) {
                changedFields.addressObject = addressFormData;
            }

            // Only proceed with update if there are changes
            if (Object.keys(changedFields).length > 0) {
                const updatedCompany = {
                    companyID: company.companyID,  // Always include the ID
                    ...changedFields
                };

                // Call the onEdit function passed from the parent
                await onEdit(updatedCompany);
                setIsEditing(false);
                toast.success('Company details updated successfully');
            } else {
                setIsEditing(false);
                toast.info('No changes to save');
            }
        } catch (error) {
            console.error('Error saving company details:', error);
            toast.error('Failed to update company details');
        } finally {
            setSaving(false);
        }
    };

    const handleSaveAddress = async () => {
        setSaving(true);
        try {
            // Create the full address string
            const addressParts = [
                addressFormData.addressLine1,
                addressFormData.addressLine2,
                addressFormData.addressLine3,
                addressFormData.county,
                addressFormData.country,
                addressFormData.postcode
            ].filter(part => part.trim() !== '');

            const fullAddress = addressParts.join(', ');

            await onEdit({
                ...company,
                address: fullAddress,
                postcode: addressFormData.postcode,
                addressObject: {
                    addressLine1: addressFormData.addressLine1,
                    addressLine2: addressFormData.addressLine2,
                    addressLine3: addressFormData.addressLine3,
                    county: addressFormData.county,
                    country: addressFormData.country,
                    postcode: addressFormData.postcode,
                },
            });

            setShowAddressModal(false);
        } catch (error) {
            console.error('Failed to update address', error);
        } finally {
            setSaving(false);
        }
    };

    const handleAddDomain = () => {
        if (newDomain && !formData.domains.includes(newDomain)) {
            setFormData({
                ...formData,
                domains: [...formData.domains, newDomain],
            });
            setNewDomain('');
        }
    };

    const handleRemoveDomain = (domain: string) => {
        setFormData({
            ...formData,
            domains: formData.domains.filter((d: string) => d !== domain),
        });
    };

    const copyUniqueLink = () => {
        navigator.clipboard.writeText(
            `${process.env.REACT_APP_CYCLESAVER_FE_URL}/subscriptions?code=${company?.companyID}`
        );
        toast.success("Link copied to clipboard.");
    };

    // Function to handle benefits modal open/close
    const toggleBenefitsModal = () => {
        setIsEditingBenefits(!isEditingBenefits);
        // Reset selected benefits to current company benefits when opening modal
        if (!isEditingBenefits) {
            setSelectedBenefitIds(company.benefitsLinked || []);
        }
    };

    // Function to handle benefit selection/deselection
    const handleBenefitToggle = (benefitId: string) => {
        setSelectedBenefitIds(prev =>
            prev.includes(benefitId)
                ? prev.filter(id => id !== benefitId)
                : [...prev, benefitId]
        );
    };

    // Function to save selected benefits
    const handleSaveBenefits = async () => {
        try {
            // Make sure we're actually sending a change by comparing with the current benefitsLinked
            if (JSON.stringify(selectedBenefitIds) !== JSON.stringify(company.benefitsLinked)) {
                const updatedCompany = {
                    companyID: company.companyID,
                    benefitsLinked: selectedBenefitIds
                };
                await onEdit(updatedCompany);
                toast.success('Benefits updated successfully');
            } else {
                toast.info('No changes to save');
            }
            setIsEditingBenefits(false);
        } catch (error) {
            console.error("Error saving benefits:", error);
            toast.error('Failed to update benefits');
        }
    };

    // Get benefit details for the company's benefit IDs
    const companyBenefits = useMemo(() => {
        if (!contextBenefits || !company.benefitsLinked) return [];
        return contextBenefits.filter((benefit: any) => company.benefitsLinked?.includes(benefit.id));
    }, [contextBenefits, company.benefitsLinked]);
    const [editContactsModalOpen, setEditContactsModalOpen] = useState(false);
    const { updateCompanyDetails,
    } = useUser();
    const editContactsButton = (
        <button
            onClick={() => setEditContactsModalOpen(true)}
            className="flex items-center gap-1 px-2 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors text-xs"
        >
            <img src={require("../assets/edit.png")} className="w-4 h-4" />
            Edit Contacts
        </button>
    );



    return (
        <ThemeProvider theme={montserratTheme}>
            <div className="bg-white rounded-xl shadow-md p-4 mb-4 transition-all duration-300 hover:shadow-lg" style={fontStyles}>
                <div className="flex justify-start items-center mb-3 gap-5">
                    <h2 className="text-2xl font-[800] text-gray-800" style={fontStyles}>
                        {isEditing ? (
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-lg font-semibold"
                                style={fontStyles}
                            />
                        ) : (
                            company.name || 'Company'
                        )}
                    </h2>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={copyUniqueLink}
                            className="flex items-center gap-1 px-2 py-1 bg-gray-50 text-gray-600 rounded-md hover:bg-gray-100 transition-colors text-xs"
                            style={fontStyles}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                            </svg>
                            Copy link
                        </button>
                        {!isEditing ? (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="flex items-center gap-1 px-2 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors text-xs"
                                style={fontStyles}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                </svg>
                                Edit
                            </button>
                        ) : (
                            <div className="flex gap-2">
                                <button
                                    onClick={() => setIsEditing(false)}
                                    className="px-2 py-1 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors text-xs"
                                    style={fontStyles}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="px-2 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-xs"
                                    style={fontStyles}
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4" style={fontStyles}>
                    {/* Column 1: ID, Registration date, Address */}
                    <div>
                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Company ID</span>
                            <p className="text-sm text-gray-800 font-medium">{company.companyID}</p>
                        </div>

                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Registration Date</span>
                            <p className="text-xs text-gray-800">
                                {company.registrationDateTime
                                    ? new Date(company.registrationDateTime).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })
                                    : 'Not available'}
                            </p>
                        </div>

                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Address</span>
                            <div className="flex flex-col">
                                <div className="text-xs text-gray-800">
                                    {company.addressObject?.addressLine1 || company.address || 'No address'}
                                </div>
                                {company.addressObject?.addressLine2 && (
                                    <div className="text-xs text-gray-800">
                                        {company.addressObject.addressLine2}
                                    </div>
                                )}
                                <div className="text-xs text-gray-800">
                                    {company.addressObject?.postcode || company.postcode || ''}
                                </div>
                                {isEditing ? (
                                    <button
                                        onClick={() => setShowAddressModal(true)}
                                        className="text-xs text-blue-600 hover:text-blue-800 mt-1"
                                    >
                                        Edit Address
                                    </button>
                                ) : null}
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Status</span>
                            <div className="flex items-center">
                                {isEditing ? <><Switch
                                    checked={isEditing ? formData.active : company.active}
                                    onChange={(e) => isEditing && setFormData({ ...formData, active: e.target.checked })}
                                    disabled={!isEditing}
                                    color="primary"
                                    size="small"
                                />
                                    <span className={`ml-1 text-xs ${(isEditing ? formData.active : company.active) ? 'text-green-600' : 'text-red-600'}`}>
                                        {(isEditing ? formData.active : company.active) ? 'Active' : 'Inactive'}
                                    </span></> : renderActiveIndicator(company, false)}
                            </div>
                        </div>

                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Batch Processing</span>
                            <div className="flex items-center">
                                <Switch
                                    checked={isEditing ? formData.processInBatch : (company.processInBatch)}
                                    onChange={(e) => isEditing && setFormData({ ...formData, processInBatch: e.target.checked })}
                                    disabled={!isEditing}
                                    color="primary"
                                    size="small"
                                />
                                <span className="ml-1 text-xs">
                                    {(isEditing ? formData.processInBatch : (company.processInBatch)) ? 'Enabled' : 'Disabled'}
                                </span>
                            </div>
                        </div>
                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Domains</span>
                            {isEditing ? (
                                <div className="mt-1">
                                    <div className="flex mb-1">
                                        <input
                                            type="text"
                                            value={newDomain}
                                            onChange={(e) => setNewDomain(e.target.value)}
                                            placeholder="Add new domain"
                                            className="flex-1 px-2 py-1 border border-gray-300 rounded-l-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                        />
                                        <button
                                            onClick={handleAddDomain}
                                            className="px-2 py-1 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 transition-colors text-xs"
                                        >
                                            Add
                                        </button>
                                    </div>
                                    <div className="flex flex-wrap gap-1 mt-1">
                                        {formData.domains.map((domain: string, index: number) => (
                                            <div key={index} className="flex items-center bg-gray-100 px-2 py-0.5 rounded-full">
                                                <span className="text-xs text-gray-800">{domain}</span>
                                                <button
                                                    onClick={() => handleRemoveDomain(domain)}
                                                    className="ml-1 text-gray-500 hover:text-red-500"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-wrap gap-1 mt-1">
                                    {company.domains && company.domains.length > 0 ? (
                                        company.domains.map((domain: string, index: number) => (
                                            <div key={index} className="bg-gray-100 px-2 py-0.5 rounded-full">
                                                <span className="text-xs text-gray-800">{domain}</span>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-xs text-gray-500">No domains linked</p>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <span className="text-xs text-gray-500">Benefit Platform</span>
                            <div className="flex items-center">
                                {isEditing ? (
                                    <div className="flex items-center gap-2">
                                        <ProviderSelector
                                            selectedProviderId={formData.providerID}
                                            onSelect={(providerId) => setFormData({ ...formData, providerID: providerId })}
                                            showSelector={editingProvider}
                                        />
                                        {!editingProvider && (
                                            <button
                                                onClick={() => setEditingProvider(true)}
                                                className="text-xs text-blue-600 hover:text-blue-800"
                                            >
                                                Change
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <p className="text-sm text-gray-800">{company.providerID || 'None'}</p>
                                )}
                            </div>
                        </div>

                    </div>

                    {/* Column 2: Active status, Batch processing, Provider */}
                    <div className='flex flex-col gap-2'>

                        <div className="rounded-md pt-0 " key="hr">

                            <div className="flex flex-row gap-3 flex-wrap">
                                {company?.hrContacts?.map((contact: any) => renderContactItem(contact, "HR"))}
                            </div>
                        </div>
                        <div className=" rounded-md pt-0 " key="finance">
                            <div className="flex flex-row gap-3 flex-wrap">
                                {company?.financeContacts?.map((contact: any) => renderContactItem(contact, "FIN"))}
                            </div>
                        </div>
                        <div className=" rounded-md pt-0 " key="payroll">
                            <div className="flex flex-row gap-3 flex-wrap">
                                {company?.payrollContacts?.map((contact: any) => renderContactItem(contact, "PAYROLL"))}
                            </div>
                        </div>
                        <div className="flex flex-row justify-between items-center mb-2" key="edit">
                            {editContactsButton}
                        </div>
                    </div>

                    {/* Column 3: Domains and Benefits linked */}
                    <div>


                        {/* Benefits Section */}
                        <div className="">
                            <div className="flex justify-between items-center mb-2">
                                <span className="text-xs text-gray-500">Benefits</span>
                                <button
                                    onClick={toggleBenefitsModal}
                                    className="flex items-center gap-1 px-2 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors text-xs"
                                >
                                    Edit Benefits
                                </button>
                            </div>

                            {companyBenefits.length > 0 ? (
                                <div className="flex flex-wrap gap-2">
                                    {companyBenefits.map((benefit: any) => (
                                        <div
                                            key={benefit.id}
                                            className="flex items-center bg-gray-100 rounded-full px-3 py-1"
                                        >
                                            {benefit.imageURL && (
                                                <img
                                                    src={benefit.imageURL}
                                                    alt={benefit.name}
                                                    className="w-4 h-4 mr-2 rounded-full"
                                                />
                                            )}
                                            <span className="text-xs">{benefit.name}</span>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-gray-500">No benefits linked</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-5 w-full flex-wrap mb-4 border-separate">

                </div>
                <EditContactsModal
                    open={editContactsModalOpen}
                    onClose={() => setEditContactsModalOpen(false)}
                    company={company}
                    onSave={updateCompanyDetails}
                />
            </div>

            {/* Address Edit Modal */}
            {showAddressModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" style={fontStyles}>
                    <div className="bg-white rounded-lg p-6 w-full max-w-2xl" style={fontStyles}>
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-semibold">Edit Address</h3>
                            <button
                                onClick={() => setShowAddressModal(false)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="md:col-span-2">
                                <label className="block text-xs font-medium text-gray-700 mb-1">Address Line 1</label>
                                <input
                                    type="text"
                                    value={addressFormData.addressLine1}
                                    onChange={(e) => setAddressFormData({ ...addressFormData, addressLine1: e.target.value })}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                    placeholder="Street address, P.O. box, company name"
                                />
                            </div>

                            <div className="md:col-span-2">
                                <label className="block text-xs font-medium text-gray-700 mb-1">Address Line 2</label>
                                <input
                                    type="text"
                                    value={addressFormData.addressLine2}
                                    onChange={(e) => setAddressFormData({ ...addressFormData, addressLine2: e.target.value })}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                    placeholder="Apartment, suite, unit, building, floor, etc."
                                />
                            </div>

                            <div className="md:col-span-2">
                                <label className="block text-xs font-medium text-gray-700 mb-1">Address Line 3</label>
                                <input
                                    type="text"
                                    value={addressFormData.addressLine3}
                                    onChange={(e) => setAddressFormData({ ...addressFormData, addressLine3: e.target.value })}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                    placeholder="Additional address information"
                                />
                            </div>

                            <div>
                                <label className="block text-xs font-medium text-gray-700 mb-1">County/State/Province</label>
                                <input
                                    type="text"
                                    value={addressFormData.county}
                                    onChange={(e) => setAddressFormData({ ...addressFormData, county: e.target.value })}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                    placeholder="County or state"
                                />
                            </div>

                            <div>
                                <label className="block text-xs font-medium text-gray-700 mb-1">Country</label>
                                <input
                                    type="text"
                                    value={addressFormData.country}
                                    onChange={(e) => setAddressFormData({ ...addressFormData, country: e.target.value })}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                    placeholder="Country"
                                />
                            </div>

                            <div className="md:col-span-2">
                                <label className="block text-xs font-medium text-gray-700 mb-1">Postcode/ZIP</label>
                                <input
                                    type="text"
                                    value={addressFormData.postcode}
                                    onChange={(e) => setAddressFormData({ ...addressFormData, postcode: e.target.value })}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
                                    placeholder="Postcode or ZIP"
                                />
                            </div>
                        </div>

                        <div className="flex justify-end mt-4 gap-2">
                            <button
                                onClick={() => setShowAddressModal(false)}
                                className="px-3 py-1 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors"
                                disabled={saving}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSaveAddress}
                                className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center gap-1"
                                disabled={saving}
                            >
                                {saving ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Saving...
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Benefits Selection Modal */}
            {isEditingBenefits && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
                    <div className="bg-white rounded-lg p-6 max-w-[640px] w-full">
                        <div className="flex justify-between items-center mb-4">
                            <h2>Manage Company Benefits</h2>
                            <button
                                onClick={toggleBenefitsModal}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ✕
                            </button>
                        </div>

                        {loadingBenefits ? (
                            <p>Loading benefits...</p>
                        ) : (
                            <>
                                <div className="max-h-[400px] overflow-y-auto">
                                    <div className="grid grid-cols-2 gap-3">
                                        {contextBenefits?.map(benefit => (
                                            <div
                                                key={benefit.id}
                                                className={`border relative rounded-md p-3 cursor-pointer flex items-center ${selectedBenefitIds.includes(benefit.id)
                                                    ? 'border-blue-500 bg-blue-50'
                                                    : 'border-gray-200'
                                                    }`}
                                                onClick={() => handleBenefitToggle(benefit.id)}
                                            >
                                                {benefit.active && <div className='absolute top-2 right-2 bg-green-500 text-white text-xs p-2 rounded-full'></div>}
                                                {benefit.imageURL && (
                                                    <img
                                                        src={benefit.imageURL}
                                                        alt={benefit.name}
                                                        className="w-8 h-8 mr-2 rounded-full"
                                                    />
                                                )}
                                                <span className='text-sm font-montserrat'>{benefit.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex justify-end gap-3 mt-4">
                                    <button
                                        onClick={toggleBenefitsModal}
                                        className="border border-gray-300 px-4 py-2 rounded-md"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveBenefits}
                                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                                    >
                                        Save Benefits
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

        </ThemeProvider>
    );
};

export default CompanyOverviewCard; 