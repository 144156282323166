import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Platform } from '../types/platform';
import { getPlatforms, getPlatform, createPlatform, updatePlatform, deletePlatform } from '../services/platformsApi';
import { toast } from 'react-toastify';

export const usePlatforms = () => {
    return useQuery<Platform[], Error>({
        queryKey: ['platforms'],
        queryFn: getPlatforms,
    });
};

export const usePlatform = (id: string) => {
    return useQuery<Platform, Error>({
        queryKey: ['platform', id],
        queryFn: () => getPlatform(id),
        enabled: !!id,
    });
};

export const useCreatePlatform = () => {
    const queryClient = useQueryClient();

    return useMutation<Platform, Error, Omit<Platform, 'id'>>({
        mutationFn: createPlatform,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['platforms'] });
        },
        onError: (error) => {
            toast.error(`Failed to create platform: ${error.message}`);
        }
    });
};

export const useUpdatePlatform = () => {
    const queryClient = useQueryClient();

    return useMutation<Platform, Error, Platform>({
        mutationFn: updatePlatform,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['platforms'] });
            queryClient.invalidateQueries({ queryKey: ['platform', data.id] });
        },
        onError: (error) => {
            toast.error(`Failed to update platform: ${error.message}`);
        }
    });
};

export const useDeletePlatform = () => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, string>({
        mutationFn: deletePlatform,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['platforms'] });
            toast.success('Platform deleted successfully');
        },
        onError: (error) => {
            toast.error(`Failed to delete platform: ${error.message}`);
        }
    });
}; 