import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
import { useUser } from "../../context/AppContext";
import ResponsiveAppBar from "../../components/Navbar";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { mapApplicationStatus, mapDebitOrderStatus } from "../../utils/util";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import Accordion from "../../components/Accordion/Accordion";
import DateRangePicker from "../../components/DateRangePicker";
import SearchBox from "../../components/SearchBox";
import MonthlyPeriodSelector from "../../components/MonthlyPeriodSelector/MonthlyPeriodSelector";
import ApplicationHistory from "../Application/ApplicationHistory";
import LoadingIndicator from "../../components/LoadingIndicator";
import BatchProcessingToggle from '../../components/BatchProcessingToggle';
import CommunicationsTable from "../../components/CommunicationsTable/CommunicationsTable";
import EditContactsModal from '../../components/EditContactsModal';
import CompanyOverviewCard from "../../components/CompanyOverviewCard";
import { getStatusStyles } from "../../utils/util";
import ApplicationStatusComponent from "../../components/ApplicationStatusComponent";


const CompanyPage = () => {
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" | undefined }>({
    key: "",
    direction: undefined,
  });
  const navigate = useNavigate();

  const {
    runningAllApplicationsQuery,

    viewApplication,
    selectedCompany,
    setSelectedCompany,
    getCompanyDetails,
    companyDetails,

    flattenedCompanies,
    gettingCompanies,
    setSelectedApplication,
    companyApplications,
    createContactInXero,
    issueInvoiceForCompany,
    companyPendingInvoiceItems,
    sendRequestToAddPaymentDetails,
    companySalarySacrificeRecurringItems,
    sendUnregisteredCompanyOnboardingInstructions,
    getCurrentSalarySacrificeReport,
    runningApplicationExportQuery,
    runningInvoicingQuery,
    companyXeroConfiguration,
    uploadCSVApplicationsForCompany,
    disburseProductsForApplicationsByCompanyID,
    getPastSalarySacrificeReport,
    requestInProgress,
    getSFTPFileForCompany,
    fulfillments,
    gettingFulfillments,
    sendSpecificSalarySacrificeReport,
    setCompanyToManualPayment,
    setCompanyBatchProcessing,
    archiveCompany,
    updateCompanyDetails,
  } = useUser();
  const [searchQuery, setSearchQuery] = useState("");

  const [dashboardFilterStartDate, setAllApplicationsFilterStartDate] = useState<DateTime | null>(null);
  const [dashboardFilterEndDate, setAllApplicationsFilterEndDate] = useState<DateTime>(DateTime.now());
  const [initialising, setInitialising] = useState(true);
  const routeParams = useParams();
  const [handlingRequest, setHandlingRequest] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadingCSV, setUploadingCSV] = useState(false);
  const [runningReportDownload, setRunningReportDownload] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(DateTime.now().monthShort.toString());
  const [selectedYear, setSelectedYear] = useState<string>(DateTime.now().year.toString());

  const [isEditingContacts, setIsEditingContacts] = useState(false);
  const [editContactsModalOpen, setEditContactsModalOpen] = useState(false);

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].includes(selectedFile.type)
    ) {
      setFile(selectedFile);
    } else {
      alert("Please select a CSV or Excel file.");
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    try {
      setUploadingCSV(true);

      const response = await uploadCSVApplicationsForCompany(selectedCompany.companyID, file);
      if (response && response.data && response.data.success) {
        toast.success("Applications uploaded and processed successfully.");
        getCompanyDetails(selectedCompany.companyID);
      } else {
        toast.error("Something went wrong.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file.");
    } finally {
      setUploadingCSV(false);
    }
  };

  useEffect(() => {
    console.log("Running dashboard query changed to " + runningAllApplicationsQuery);
  }, [runningAllApplicationsQuery]);

  useEffect(() => {
    console.log(companyDetails);
    setSortConfig({ key: "creationDateTime", direction: "desc" });
    requestSort("creationDateTime");
  }, [companyApplications]);


  useEffect(() => {
    const compID = routeParams?.companyid;
    console.log("Company ID", compID, flattenedCompanies, selectedCompany);
    if (flattenedCompanies && compID !== companyDetails?.company?.companyID) {
      getCompanyDetails(compID);
    }
  }, [selectedCompany]);

  useEffect(() => {
    document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + `Admin | ${selectedCompany?.name ?? "Company"}`;
  }, [selectedCompany]);

  const requestSort = (key: string | null) => {
    if (key && companyApplications) {
      let direction: "asc" | "desc" = "asc";
      if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
      const sorted = [...companyApplications].sort((a, b) => {
        const valueA = getValue(a, key)?.toString().toLowerCase();
        const valueB = getValue(b, key)?.toString().toLowerCase();
        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sorted);
    }
  };

  const getValue = (object: any, path: string) => {
    const keys = path.split(".");
    let value = object;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return undefined;
      }
    }
    return value;
  };

  const handleBatchProcessingToggle = async (enabled: boolean) => {
    try {
      await setCompanyBatchProcessing(
        selectedCompany.companyID,
        enabled
      );
    } catch (error) {
      // Error already handled in the hook
    }
  };

  const batchProcessingToggle = selectedCompany && (
    <BatchProcessingToggle
      isEnabled={selectedCompany.processInBatch !== false}
      onToggle={handleBatchProcessingToggle}
      label="Batch Processing"
    />

  );



  return (
    <div className="min-h-screen flex-col  bg-white">
      <ResponsiveAppBar />
      {!selectedCompany ? <div className=" flex w-full min-h-screen 2xl:gap-5  p-4   bg-white 2xl:px-6 2xl:pt-10 flex-col 2xl:flex-row items-center justify-center"> <LoadingIndicator /></div> :
        <div className=" flex w-full min-h-screen 2xl:gap-5  p-4   bg-white 2xl:px-6 2xl:pt-10 flex-col 2xl:flex-row">
          {/* <Sidebar /> */}
          <div className="flex flex-col w-full 2xl:w-5/7 ">
            <div className="grid grid-cols-1 gap-4">
              <CompanyOverviewCard
                company={selectedCompany}
                onEdit={updateCompanyDetails}
              />

              {/* <div className="flex flex-row gap-5 w-full flex-wrap mb-4 border-separate">

                <div className="rounded-md pt-0 " key="hr">

                  <div className="flex flex-row gap-3 flex-wrap">
                    {selectedCompany?.hrContacts?.map((contact: any) => renderContactItem(contact, "HR"))}
                  </div>
                </div>
                <div className=" rounded-md pt-0 " key="finance">
                  <div className="flex flex-row gap-3 flex-wrap">
                    {selectedCompany?.financeContacts?.map((contact: any) => renderContactItem(contact, "FIN"))}
                  </div>
                </div>
                <div className=" rounded-md pt-0 " key="payroll">
                  <div className="flex flex-row gap-3 flex-wrap">
                    {selectedCompany?.payrollContacts?.map((contact: any) => renderContactItem(contact, "PAYROLL"))}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2" key="edit">
                  {editContactsButton}
                </div>
              </div> */}
            </div>
            <div className="flex flex-col border bg-white rounded-lg p-2   mb-2">
              <div className="flex flex-row flex-wrap rounded-md  gap-1">
                {/* {selectedCompany.active && batchProcessingToggle} */}


                {selectedCompany && selectedCompany?.active == false &&
                  <div className="flex flex-row gap-2">
                    <button
                      className="text-sm"
                      disabled={handlingRequest}
                      onClick={async () => {
                        setHandlingRequest(true);
                        await sendUnregisteredCompanyOnboardingInstructions(selectedCompany.companyID);
                        setHandlingRequest(false);
                      }}
                    >
                      <img src={require("../../assets/send.png")} className="w-5 h-5" />
                      Send onboarding email
                    </button>

                    <button
                      className="text-sm text-red-600"
                      disabled={handlingRequest}
                      onClick={async () => {
                        const confirmed = window.confirm(
                          "Are you sure you want to archive this company? This action cannot be undone."
                        );
                        if (confirmed) {
                          setHandlingRequest(true);
                          try {
                            await archiveCompany(selectedCompany.companyID);
                            navigate(-1);
                          } catch (error) {
                            // Error handling is already done in archiveCompany
                          } finally {
                            setHandlingRequest(false);
                          }
                        }
                      }}
                    >
                      <img src={require("../../assets/archive.png")} className="w-5 h-5" />
                      Archive Company
                    </button>
                  </div>
                }
              </div>
            </div>

            <SearchBox
              value={searchQuery}
              onChange={(e: any) => setSearchQuery(e.target.value)}
              placeholder={"Type to search company data."}
            />
            <Accordion
              headings={[
                "Applications",
                "Fulfillments",
                "Outstanding invoice items",
                "Past Invoices",
                "Upcoming Salary Sacrifices",
                "Past Salary Sacrifice Reports",
                "Payment Configuration",
                "Xero Status",
                "Communications",
              ]}

              items={[
                <div className="flex flex-col px-2">
                  <div className="flex flex-row flex-wrap gap-2 items-center justify-start mb-2">
                    <button
                      disabled={runningInvoicingQuery}
                      className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                      onClick={async () => {
                        const confirmed = window.confirm(
                          `This will send out Lime and Santander vouchers to all ACTIVE/AWAITING_ACTIVATION applications FOR THIS COMPANY ONLY, and export CSVs for each benefit that needs to be sent to the partner for activation by email. Are you sure you want to continue?`
                        );
                        if (confirmed) {
                          // Perform the action here
                          disburseProductsForApplicationsByCompanyID(selectedCompany.companyID);

                          console.log("Action confirmed and performed.");
                        } else {
                          // Action was canceled
                          console.log("Action canceled.");
                        }
                      }}
                    >
                      {runningInvoicingQuery ? (
                        <CircularProgress size={20} style={{ color: "#86eb85" }} />
                      ) : (
                        <img src={require("../../assets/sent.png")} className="w-6 h-6" />
                      )}
                      Fulfill new & active applications
                    </button>
                    {selectedCompany?.providerID &&
                      <>
                        <button
                          className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                          onClick={getSFTPFileForCompany}
                          disabled={requestInProgress}
                        >

                          <img src={require("../../assets/sftp.png")} className="w-6 h-6" />

                          Download SFTP file
                        </button>
                        <div className="flex flex-grow flex-row flex-wrap rounded-md gap-2 border px-2 py-1 relative items-center  border-lightgrey">

                          <input type="file" accept=".csv, .xls, .xlsx" className="text-sm" onChange={handleFileChange} />
                          <button
                            className="flex flex-row gap-2 flex-grow items-center justify-center max-w-[300px] bg-white  px-4 py-1  rounded-md text-sm"
                            onClick={handleUpload}
                            disabled={uploadingCSV}
                          >
                            {uploadingCSV ? (
                              <CircularProgress size={20} style={{ color: "#86eb85" }} />
                            ) : (
                              <img src={require("../../assets/csv.png")} className="w-6 h-6" />
                            )}
                            Upload
                          </button>
                        </div>

                      </>
                    }

                  </div>
                  <DynamicTable
                    cols={["ID", "Date", "Status", "Application ID", "Benefit", "Employee"]}
                    onRowClicked={(id: any) => {
                      const selected = companyApplications.find((c: any) => c.id == id);
                      console.log(selected);
                      viewApplication(selected);
                      // localStorage.setItem("selectedCompanyID", companies[index].companyID);
                      // setSelectedCompany(selected);
                      // setSelectedApplication(selected);
                      // navigate(`/companies/${selectedCompany.companyID}/application/${id}`);
                    }}
                    rows={companyApplications?.map((application: any) => {
                      return [
                        application.id,
                        <p>
                          {application.creationDateTime}

                        </p>,
                        <ApplicationStatusComponent application={application} />,
                        <p>
                          <strong>
                            {" "}
                            <strong>***</strong>
                          </strong>
                          {application.id.substring(application.id.indexOf("_") + 1)}
                        </p>,
                        <div className="flex flex-row items-center justify-start gap-3 mx-1">
                          <img src={application.benefit.imageURL} className="w-8 h-8 rounded-md" />
                          <p>{application.benefit.name}</p>
                        </div>,
                        <p className="font-medium">{`${application.employee.firstName} ${application.employee.surname}`}</p>,
                      ];
                    })}
                    loading={gettingCompanies}
                    idIndexInColumns={0}
                    showID={false}
                    searchQueryInput={searchQuery}
                  />
                </div>,
                <DynamicTable
                  tableId="FulfillmentsTable"
                  cols={["FulfillmentID", "Application", "Fulf.#", "Company", "Date", "Product", "Value", "Invoice", "Sacrifice"]}
                  filterableColumns={["Application", "Product", "Date", "Company", "Invoice", "Sacrifice"]}
                  showExport={true}
                  rows={fulfillments?.filter((f: any) => f.companyID == selectedCompany?.companyID)?.map((fulfillment: any) => {
                    return [
                      fulfillment.fulfillmentID,
                      "***" + fulfillment.applicationID.split("_")[1],
                      fulfillment.fulfillmentNumber,
                      fulfillment.companyID,
                      fulfillment.fulfilledDateTime,
                      // renderActiveIndicator(fulfillment.active),
                      // fulfillment.fulfillmentReference,
                      fulfillment.benefitID,
                      "£" + fulfillment.invoicingAmountPounds,
                      !fulfillment.invoiced ? <div className="flex flex-grow h-full w-full items-center gap-2">
                        <div className="h-3 w-3 rounded-full bg-red"></div><p>None</p>
                      </div> : <div className="flex flex-grow h-full w-full items-center gap-2">
                        <div className="h-3 w-3 rounded-full bg-green-500"></div><p className="text-xs">#{fulfillment.invoicingDetails?.internalInvoiceID || "NONE"}</p>
                      </div>,
                      <div className="flex flex-grow h-full w-full items-center gap-2">

                        <div className={`h-3 w-3 rounded-full ${fulfillment.salarySacrificeDetails.status == "INPROGRESS" ? `bg-blue-500` : fulfillment.salarySacrificeDetails.status == "OUTSTANDING" ? `bg-red` : fulfillment.salarySacrificeDetails.status == "COMPLETED" ? `bg-green-600` : `bg-orange-500`}`}>
                        </div>
                        <p className="text-xs">{fulfillment.salarySacrificeDetails.status}</p>
                      </div>
                      // !fulfillment.sacrificed ? (
                      //           <div className="flex flex-grow h-full w-full items-center gap-2">
                      //             {(fulfillment.salarySacrificeDetails?.numberOfSalarySacrificesRequired > fulfillment.salarySacrificeDetails?.salarySacrificeCountRemaining) ?
                      //               <>
                      //                 <div className="h-3 w-3 rounded-full bg-blue-500">
                      //                 </div>
                      //                 <p>In Progress</p>
                      //               </>
                      //               : <>
                      //                 <div className="h-3 w-3 rounded-full bg-red">
                      //                 </div>
                      //                 <p>None</p>
                      //               </>
                      //             }
                      //           </div>)
                      //           : (<div className="flex flex-grow h-full w-full items-center gap-2">
                      //             <div className="h-3 w-3 rounded-full bg-green-500"></div> <p>Completed</p>
                      //           </div>)

                    ]
                  })}
                  onRowClicked={(fulfillmentID: any) => {

                    // const selected = fulfillments.find((c: any) => c.fulfillmentID == fulfillmentID);
                    // console.log(selected);
                    // setSelectedFulfillment(selected)
                    // // // localSt orage.setItem("selectedCompanyID", companies[index].companyID);
                    // // setSelectedCompany(selected);
                    // navigate(`/fulfillments/${fulfillmentID}`);
                  }}
                  idIndexInColumns={0}
                  showID={false}
                  // classNames={["", "", "", "", "", ""]}
                  loading={gettingFulfillments}
                />
                ,
                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-end mb-2">
                    <button
                      disabled={runningInvoicingQuery}
                      className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                      onClick={() => {
                        const confirmed = window.confirm(
                          `Are you sure you want to issue an invoice for the ${companyPendingInvoiceItems?.length} pending invoice items?`
                        );
                        if (confirmed) {
                          // Perform the action here
                          issueInvoiceForCompany(selectedCompany.companyID);
                          console.log("Action confirmed and performed.");
                        } else {
                          // Action was canceled
                          console.log("Action canceled.");
                        }
                      }}
                    >
                      {runningInvoicingQuery ? (
                        <CircularProgress size={20} style={{ color: "#86eb85" }} />
                      ) : (
                        <img src={require("../../assets/paper.png")} className="w-6 h-6" />
                      )}
                      Issue Invoice for these items
                    </button>
                  </div>
                  <DynamicTable
                    cols={[
                      "Billed Date",
                      "Fulfillment ID",
                      "Product",
                      "Employee",
                      "Item Value",
                      "Commission",
                      "Final Total",
                    ]}
                    rows={companyPendingInvoiceItems?.map((item: any) => {
                      return [
                        item.fulfilledDateTime,
                        mapApplicationStatus(item.fulfillmentID),
                        <p className="font-bold">{`${item.productDescription}`}</p>,

                        <p className="font-bold">{`${item.employeeFirstName} ${item.employeeSurname}`}</p>,
                        <p className="font-bold">{`£${item.invoicingAmountPounds}`}</p>,
                        <p className="font-bold">{`£${item.companyFeeAmount} (${item.companyFeeRate * 100}%)`}</p>,
                        <p className="font-bold">{`£${item.totalFinalAmount}`}</p>,


                      ];
                    })}
                    onRowClicked={() => { }}
                    loading={gettingCompanies}
                    idIndexInColumns={-1}
                    showID={false}
                    searchQueryInput={searchQuery}
                  />
                </div>,
                <DynamicTable
                  cols={["Xero Inv#", "Internal #", "Created Date", "Due Date", "Sent To", "Final Total", "View"]}
                  rows={companyDetails?.issuedInvoices?.map((item: any) => {
                    var inv = item.xeroInvoiceDetails;
                    return [
                      inv.invoiceNumber,
                      inv.reference,
                      item.createdAt,
                      ,
                      <div>
                        <p>{inv.contact?.firstName + " " + inv.contact?.lastName}</p>
                        <p>{inv.contact?.emailAddress}</p>
                      </div>,
                      "£" + inv.total,
                      <a
                        href={`https://go.xero.com/app/!0!9hs/invoicing/view/${inv.invoiceID}`}
                        target="_blank"
                        className="flex flex-row items-center justify-center gap-1 bg-white px-2 py-1 rounded-md"
                      >
                        <img src={require("../../assets/openInNewTab.png")} className="w-6 h-6" />
                        <p>View in Xero</p>
                      </a>,
                    ];
                  })}
                  onRowClicked={(companyID: any) => {
                    // toast.success("Redirected to Xero in new tab.", { autoClose: 2000 });
                  }}
                  loading={gettingCompanies}
                  idIndexInColumns={-1}
                  showID={false}
                  searchQueryInput={searchQuery}
                />,
                <div className="flex flex-col ">
                  <div className="flex flex-row items-center justify-end gap-2 border border-lightgrey rounded-xl ml-auto p-2 mr-4">
                    <MonthlyPeriodSelector
                      selectedMonth={selectedMonth}
                      setSelectedMonth={setSelectedMonth}
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                    />
                    <button
                      disabled={runningInvoicingQuery}
                      className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                      onClick={() => {
                        const confirmed = window.confirm(
                          `Are you sure you want to generate the salary sacrifice report for the current month? This does NOT send it to the company.`
                        );
                        if (confirmed) {
                          getCurrentSalarySacrificeReport(selectedCompany?.companyID, selectedMonth, selectedYear);
                          // Perform the action here
                          // (selectedCompany.companyID);
                          console.log("Action confirmed and performed.");
                        } else {
                          // Action was canceled
                          console.log("Action canceled.");
                        }
                      }}
                    >
                      {runningInvoicingQuery ? (
                        <CircularProgress size={20} style={{ color: "#86eb85" }} />
                      ) : (
                        <img src={require("../../assets/paper.png")} className="w-6 h-6" />
                      )}
                      Create Report
                    </button>
                    <button
                      disabled={runningInvoicingQuery}
                      className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                      onClick={() => {
                        const confirmed = window.confirm(
                          `This will generate AND send the report to the company. Are you sure you want to proceed?`
                        );
                        if (confirmed) {
                          sendSpecificSalarySacrificeReport(selectedCompany?.companyID, selectedMonth, selectedYear);
                          // Perform the action here

                        } else {
                          // Action was canceled
                          console.log("Action canceled.");
                        }
                      }}
                    >
                      {runningInvoicingQuery ? (
                        <CircularProgress size={20} style={{ color: "#86eb85" }} />
                      ) : (
                        <img src={require("../../assets/paper.png")} className="w-6 h-6" />
                      )}
                      Send Report
                    </button>
                  </div>
                  <DynamicTable
                    cols={[
                      "Started",
                      "Status",
                      "Application ID",
                      "Product",
                      "Employee",
                      "Item Value",
                      "Start period",
                      "End Period",
                      "Cancelled Period",
                    ]}
                    rows={companySalarySacrificeRecurringItems?.map((item: any) => {
                      return [
                        item.dateFirstBilled,
                        mapApplicationStatus(item.status),
                        item.applicationID,
                        <p className="font-bold">{`${item.productDescription}`}</p>,

                        <p className="font-bold">{`${item.employeeFirstName} ${item.employeeSurname}`}</p>,
                        <p className="font-bold">{`£${item.sacrificeAmountPounds}`}</p>,

                        <p className="font-bold">{`${DateTime.fromISO(item.startDateOfSacrifice).toFormat(
                          "MMM yyyy"
                        )} `}</p>,
                        <p className="font-bold">{`${item.endDateOfSacrifice
                          ? DateTime.fromISO(item.endDateOfSacrifice).toFormat("MMM yyyy")
                          : "None"
                          } `}</p>,
                        <p className="font-bold">{`${item.cancelDateOfSacrifice
                          ? DateTime.fromISO(item.cancelDateOfSacrifice).toFormat("MMM yyyy")
                          : "None"
                          } `}</p>,
                      ];
                    })}
                    onRowClicked={(companyID: any) => {
                      // const selected = companies.find((c: any) => c.companyID == companyID);
                      // console.log(selected);
                      // // localStorage.setItem("selectedCompanyID", companies[index].companyID);
                      // setSelectedCompany(selected);
                      // navigate(`/companies/${companyID}`);
                    }}
                    loading={gettingCompanies}
                    idIndexInColumns={-1}
                    showID={false}
                    searchQueryInput={searchQuery}
                  />
                </div>,
                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-end mb-2"></div>
                  <DynamicTable
                    cols={["Period", "Date Issued", "Total Value", "Actions"]}
                    rows={companyDetails?.salarySacrificeReports?.map((item: any) => {
                      return [
                        <p className="font-bold">{item.id}</p>,
                        item.timestampGenerated,

                        <p className="font-bold">{`£${item.totalValue.toFixed(2)}`}</p>,
                        <div className="grid grid-cols-2 gap-2">
                          <button
                            disabled={runningReportDownload}
                            className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                            onClick={async () => {
                              // Perform the action here
                              await getPastSalarySacrificeReport(selectedCompany.companyID, item.id);
                            }}
                          >
                            {runningReportDownload ? (
                              <CircularProgress size={20} style={{ color: "#86eb85" }} />
                            ) : (
                              <img src={require("../../assets/download.png")} className="w-6 h-6" />
                            )}
                            Download
                          </button>
                          <button
                            disabled={runningReportDownload}
                            className="flex flex-row gap-2 items-center justify-center max-w-[300px] bg-white  px-4 py-2  rounded-md text-sm"
                            onClick={async () => {
                              // Perform the action here
                              await sendSpecificSalarySacrificeReport(selectedCompany.companyID, item.id.split("_")[0], item.id.split("_")[1]);
                            }}
                          >
                            {runningReportDownload ? (
                              <CircularProgress size={20} style={{ color: "#86eb85" }} />
                            ) : (
                              <img src={require("../../assets/send.png")} className="w-6 h-6" />
                            )}
                            Send
                          </button></div>,
                      ];
                    })}
                    onRowClicked={(companyID: any) => {
                      // const selected = companies.find((c: any) => c.companyID == companyID);
                      // console.log(selected);
                      // // localStorage.setItem("selectedCompanyID", companies[index].companyID);
                      // setSelectedCompany(selected);
                      // navigate(`/companies/${companyID}`);
                    }}
                    loading={gettingCompanies}
                    idIndexInColumns={-1}
                    showID={false}
                    searchQueryInput={searchQuery}
                  />
                </div>,
                selectedCompany?.paymentDetails?.status == "fulfilled" ? (
                  <div className="bg-white p-2 mx-4 mb-4 rounded-lg">
                    <h3>Direct Debit has been configured for the company</h3>
                    <p>BRQ: {selectedCompany?.paymentDetails?.brq}</p>
                    <p>BRF: {selectedCompany?.paymentDetails?.brf}</p>
                    <p>GoCardless Customer ID: {selectedCompany?.paymentDetails?.cust}</p>
                    <p>
                      Status: <strong>{mapDebitOrderStatus(selectedCompany?.paymentDetails?.status)}</strong>
                    </p>
                    <p>
                      Last Updated:{" "}
                      {DateTime.fromISO(selectedCompany?.paymentDetails?.timestamp).toFormat("yyyy-MM-dd hh:mm a")}
                    </p>
                    <div>
                      <h3>History:</h3>
                      <div className="flex flex-row flex-wrap gap-4">
                        {selectedCompany?.paymentDetails?.statusUpdateHistory?.reverse().map((h: any) => (
                          <div className="border p-3 rounded-md">
                            <p>Date ENDED: {DateTime.fromISO(h.endDateTime).toFormat("yyyy-MM-dd HH:mm")}</p>
                            <p>Status: {h.status}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col w-full justify-center items-center p-4 md:p-10">
                    {selectedCompany?.paymentDetails?.status == "pending" ? (
                      <>
                        {" "}
                        <h3 className="text-center">
                          A request has been sent to the company to add their payment details on{" "}
                          {DateTime.fromISO(selectedCompany?.paymentDetails?.timestamp).toFormat("dd MMM yyyy hh:mm a")}.
                        </h3>
                        <p>Click below to send a mail to add payment details to the HR and Finance people</p>
                      </>
                    ) : selectedCompany?.paymentDetails?.status == "manual" ? (
                      <>
                        {" "}
                        <h3>The company is set up for manual payment</h3>
                        <p>Click below to send a mail to add payment details to the HR and Finance people</p>
                      </>
                    ) : (
                      <>
                        {" "}
                        <h3>No payment details have been added by this company.</h3>
                        <p>Click below to send a mail to add payment details to the HR and Finance people</p>
                      </>
                    )}

                    <br />
                    <button
                      className={`${handlingRequest && `bg-lightgrey`}`}
                      disabled={handlingRequest}
                      onClick={async () => {
                        setHandlingRequest(true);
                        await sendRequestToAddPaymentDetails(selectedCompany.companyID);
                        setHandlingRequest(false);
                      }}
                    >
                      <img src={require("../../assets/send.png")} className="w-8" />
                      Send request to add direct debit details
                    </button>

                    {selectedCompany?.paymentDetails?.status != "manual" &&
                      <>
                        <h2 className="my-4">OR</h2>
                        <button
                          className={`${handlingRequest && `bg-lightgrey`}`}
                          disabled={handlingRequest}
                          onClick={async () => {
                            setHandlingRequest(true);
                            try {
                              await setCompanyToManualPayment(selectedCompany.companyID);
                              setHandlingRequest(false);
                            } catch (error: any) {
                              toast.error("Failed to set company to manual payment: " + error);
                              setHandlingRequest(false);
                            }
                          }}
                        >
                          <img src={require("../../assets/cash.png")} className="w-8" />

                          Set as a manual payment client
                        </button>
                      </>
                    }
                  </div>
                ),
                companyXeroConfiguration ? (
                  <div className="bg-white p-2 mx-4 mb-4 rounded-lg">
                    <h2>Xero Contact details</h2>
                    <p>Contact ID: {companyXeroConfiguration?.contacts[0].clientContactID}</p>
                    <p>Contact Name: {companyXeroConfiguration?.contacts[0].clientContactName}</p>
                    <p>Contact Email: {companyXeroConfiguration?.contacts[0].clientContactEmail}</p>
                  </div>
                ) : (
                  <div className="flex flex-col w-full justify-center items-center p-4 md:p-10">
                    <div>
                      {" "}
                      <h3>The company has not been added to Xero</h3>
                      <button
                        disabled={runningApplicationExportQuery}
                        className="flex flex-row w-full gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md text-sm"
                        onClick={() => {
                          createContactInXero(selectedCompany?.companyID);
                        }}
                      >
                        {runningApplicationExportQuery ? (
                          <CircularProgress size={20} style={{ color: "#86eb85" }} />
                        ) : (
                          <img src={require("../../assets/add-user.png")} className="w-6 h-6" />
                        )}
                        Create contact in Xero
                      </button>
                    </div>
                  </div>
                ), <div className="flex flex-col w-full justify-center py-2 px-2 md:px-4">
                  <CommunicationsTable companyId={selectedCompany.companyID} />
                </div>
              ]}
            />

          </div>
          <div className="w-full 2xl:w-2/7 ">
            <ApplicationHistory historyList={selectedCompany?.activityLog ?? []} heading={"Notes"} />

          </div>


        </div>
      }

      {selectedCompany && <EditContactsModal
        open={editContactsModalOpen}
        onClose={() => setEditContactsModalOpen(false)}
        company={selectedCompany}
        onSave={updateCompanyDetails}
      />
      }
    </div>
  );
};

export default CompanyPage;
