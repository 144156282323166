import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { DateTime } from "luxon";

function ProgressBar({ currentStep, stepClicked, stepsCustom, cancelled, cancelledDateTime, cancellationReason }: any) {
  var steps = ["Company Details", "Personal Details", "Review", "Submitted"];
  if (stepsCustom) {
    steps = stepsCustom;
  }
  const progressValue = (currentStep / (steps.length - 1)) * 100;

  return (
    <div className={`md:px-16 pt-2 md:pb-4`}>
      {cancelled ? (
        <div className="flex flex-col items-center justify-start">
          <img src={require("../../assets/cancel.png")} className="w-20 h-20 mb-4" />
          <p className="text-2xl font-bold">
            CANCELLED ON {DateTime.fromISO(cancelledDateTime).toFormat("dd LLL yyyy @ hh:mm a ")}
          </p>
          <p className="text-lg">Reason: {cancellationReason ?? "None"}</p>
        </div>
      ) : (
        <Box sx={{ position: "relative" }}>
          {/* Desktop View */}
          <div className="hidden md:block">
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                backgroundColor: "#EEEEEE",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#86eb85",
                },
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: "#86eb85",
                },
              }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="absolute"
              width="100%"
              top="1"
              height="100%"
            >
              {steps.map((step, index) => (
                <div
                  key={step}
                  onClick={() => stepClicked(index + 1)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "absolute",
                    left: `${(index / (steps.length - 1)) * 100}%`,
                    top: "-18px",
                    transform: `translateX(-50%)`,
                  }}
                >
                  <Box
                    width={36}
                    height={36}
                    borderRadius="50%"
                    bgcolor={index <= currentStep ? "#86eb85" : "#ddd"}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={1}
                    border={index == currentStep ? 3 : 0}
                    borderColor={"#555555"}
                  >
                    <p className={`text-primary font-semibold`}>{index + 1}</p>
                  </Box>
                  {index == currentStep ? (
                    <p className="text-sm md:text-base font-[800] my-1">{step}</p>
                  ) : (
                    <p className="text-center text-sm my-1 md:text-base">{step}</p>
                  )}
                </div>
              ))}
            </Box>
          </div>

          {/* Mobile View */}
          <div className="md:hidden flex flex-col relative px-4">
            <div
              className="absolute left-[30px] top-[32px] w-[4px] bg-[#EEEEEE]"
              style={{
                height: `calc(100% - 60px)`
              }}
            >
              <div
                className="w-full bg-[#86eb85]"
                style={{
                  height: `${progressValue}%`,
                  transition: 'height 0.3s ease-in-out'
                }}
              />
            </div>
            {steps.map((step, index) => (
              <div
                key={step}
                className="flex flex-row items-center mb-3 relative"
                onClick={() => stepClicked(index + 1)}
              >
                <Box
                  width={32}
                  height={32}
                  borderRadius="50%"
                  bgcolor={index <= currentStep ? "#86eb85" : "#ddd"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={1}
                  border={index == currentStep ? 3 : 0}
                  borderColor={"#555555"}
                >
                  <p className={`text-primary font-semibold`}>{index + 1}</p>
                </Box>
                <div className="ml-4">
                  {index == currentStep ? (
                    <p className="text-lg font-[800]">{step}</p>
                  ) : (
                    <p className="text-sm">{step}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Box>
      )}
    </div>
  );
}

export default ProgressBar;
