// Login.tsx
import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput"; // Make sure the path matches your file structure
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";

import ResponsiveAppBar from "../../components/Navbar";
import Toolbar from "@mui/material/Toolbar";
import StatCard from "../../components/StatCard";
import { BarChart } from "../../components/charts/BarChart";
import { PieChart } from "../../components/charts/PieChart";
import { useUser } from "../../context/AppContext";
import { colors, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { DocusealForm } from "@docuseal/react";
import Backdrop from "@mui/material/Backdrop";
import DynamicTable, { renderContactItem, renderActiveIndicator } from "../../components/DynamicTable/DynamicTable";
import { useNavigate } from "react-router-dom";
import { mapDebitOrderStatus } from "../../utils/util";

const Companies = () => {
  const {
    companies,
    gettingCompanies,
    setSelectedCompany,
    usePaginatedCompanies
  } = useUser();



  const navigate = useNavigate();

  const [initialising, setInitialising] = useState(true);
  const [showContract, setShowContract] = useState(false);
  useEffect(() => {
    document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + `Admin | Companies`;
  }, []);
  // Memoize the filters object to prevent unnecessary re-renders
  const filters = React.useMemo(() => ({
  }), []); // Empty dependency array since these don't change

  // Use the paginated companies hook
  const {
    data: paginatedCompaniesData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: loadingPaginatedCompanies,
  } = usePaginatedCompanies(filters);

  // Flatten the pages data for rendering
  const flattenedCompanies = React.useMemo(() => {
    if (!paginatedCompaniesData?.pages) return [];
    return paginatedCompaniesData.pages.flatMap((page: any) => {
      // Each page contains the data array directly now
      return page.data || [];
    });
  }, [paginatedCompaniesData]);

  var titleTextStyle = {
    color: "#555", // You can set the title color
    fontName: "Montserrat",
    fontSize: 22, // Adjust as needed
    bold: true, // true or false
    italic: false, // true or false
  };

  var smallTitleTextStyle = {
    ...titleTextStyle,
    fontSize: 16, // Adjust as needed
  };
  return (
    <div className="min-h-screen flex-col flex-grow bg-white p-4">
      {/* <ResponsiveAppBar /> */}
      <h2 className="ml-2">COMPANIES</h2>

      <div className="flex-grow flex w-full h-full pt-1 bg-white">
        <DynamicTable
          tableId="CompaniesTable"
          cols={["ID", "Date Registered", "Status", "Name", "Address", "#Apps"]}
          filterableColumns={["Status", "#Apps"]}
          showExport={true}
          rows={flattenedCompanies?.map((company: any) => [
            <p className="text-xs ">{company.companyID}</p>,
            <p className="text-xs ">{company.registrationDateTime}</p>,
            renderActiveIndicator(company),

            <p className="text-xs ">{company.name}</p>,
            <p className="text-xs ">{company.addressObject ? company.addressObject.postcode : company.address}</p>,
            <p className="text-xs ">{company.countOfApplications ?? 0}</p>
          ])}
          onRowClicked={(companyID: string) => {
            const selected = flattenedCompanies.find((c: any) => c.companyID === companyID);
            setSelectedCompany(selected);
            navigate(`/companies/${companyID}`);
          }}
          loading={loadingPaginatedCompanies}
          idIndexInColumns={0}
          showID={true}
          rowExpansionComponents={flattenedCompanies?.map((company: any) => {
            return <div className="flex flex-row flex-wrap gap-2 my-2 overflow-hidden">
              <div className="border-r border-lightgrey/50  p-2">
                {company.hrContacts?.map((contact: any) => renderContactItem(contact, "HR"))}
              </div>
              {company.financeContacts && company.financeContacts.length > 0 && <div className="border-r border-lightgrey/50  p-2">
                {company.financeContacts?.map((contact: any) => renderContactItem(contact, "FIN"))}
              </div>}
              {company.payrollContacts && company.payrollContacts.length > 0 && <div className="border-r border-lightgrey/50  p-2">
                {company.payrollContacts?.map((contact: any) => renderContactItem(contact, "PAYROLL"))}
              </div>}

              {/* <div className="border-r border-lightgrey/50  p-2">
                <p className="font-bold text-base">Salary Sacrifice Terms</p>
                {application.salarySacrificeTerms?.numberOfSacrifices > 0 ? <p className="">{application.salarySacrificeTerms?.numberOfSacrifices} months</p> : <p>Ongoing until cancelled</p>}

              </div>
              {application.activityLog && application.activityLog.length > 0 &&
                <div className="border-r border-lightgrey/50  p-2 max-w-[300px]">
                  <p className="font-bold text-base">Latest activity</p>
                  <p className="text-sm">{application.activityLog[application.activityLog.length - 1].action}</p>

                </div>
              } */}
              {company.activityLog && company.activityLog.length > 0 &&
                <div className="border-r border-lightgrey/50  p-2 max-w-[300px]">
                  <p className="font-bold text-xs">Latest activity</p>
                  <p className="text-xs">{company.activityLog[company.activityLog.length - 1].action}</p>
                </div>
              }
            </div>
          })}
          pagination={true}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </div>
    </div>
  );
};

export default Companies;
