export interface PlatformContact {
    id?: string;
    name: string;
    surname: string;
    email: string;
    phone?: string;
    position?: string;
}

// Common field categories and their fields
export const TARGET_FIELDS = {
    employee: [
        'firstName',
        'surname',
        'email',
        'phone',
        'employeeID',
        'postcode'
    ],
    benefit: [
        'id',
        'name',
    ],
    metadata: [
        'action',
        'source',
        'benefitStartDate',
        'benefitEndDate',
        'notes',
        'externalId',
        'duration'
    ]
};

// Valid action values for standardization
export const VALID_ACTIONS = [
    'CREATE',
    'CANCEL',
    'PAUSE',
    'END'
];

export interface SftpCredentials {
    host: string;
    username: string;
    password: string;
    port: number;
}

export interface PlatformAddress {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    county?: string;
    postcode: string;
    country: string;
}

export interface FieldMapping {
    sourceColumn: string;
    targetField: string;
    defaultValue?: string;
    isRequired: boolean;
}

export interface BenefitNameMapping {
    platformBenefitName: string;
    benefitId: string;
}

export interface ActionValueMapping {
    platformValue: string;
    standardAction: typeof VALID_ACTIONS[number];
}

export interface Platform {
    id: string;
    name: string;
    sftp?: {
        enabled: boolean;
        sftpCredentials?: SftpCredentials;
    };
    contacts?: PlatformContact[];
    address?: PlatformAddress;
    active?: boolean;
    notes?: string;
    website?: string;
    createdAt?: string;
    updatedAt?: string;
    fieldMappings?: {
        hasHeaderRow: boolean;
        mappings: FieldMapping[];
    };
    benefitMappings?: BenefitNameMapping[];
    actionMappings?: ActionValueMapping[];
} 