import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { DateTime } from "luxon";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";
import { useUser } from '../../context/AppContext';

const BatchJobs = () => {
    const navigate = useNavigate();
    const {
        batchJobs,
        invoicingJobs,
        salarySacrificeJobs,
        loadingJobs,
        loadingInvoicingJobs,
        loadingSalSacJobs,
        runningBatch,
        runningInvoicing,
        runningSalSac,
        fetchBatchJobs,
        fetchInvoicingJobs,
        fetchSalarySacrificeJobs,
        runBatchJob,
        runInvoicingJob,
        runSalarySacrificeJob,
        currentJobId,
    } = useUser();

    useEffect(() => {
        fetchBatchJobs();
        fetchInvoicingJobs();
        fetchSalarySacrificeJobs();
    }, []);

    useEffect(() => {
        document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + `Admin | Batch Jobs`;
    }, []);
    const SummaryComponent = ({ jobs, title }: { jobs: any[], title: string }) => {
        if (!jobs.length) return null;

        const latestJob = jobs[0]; // Assuming jobs are sorted by date
        const totalProcessed = latestJob.completedCompanies?.length || 0;
        const totalSkipped = latestJob.skippedCompanies?.length || 0;
        const totalErrors = latestJob.errors?.length || 0;

        const SummaryItem = ({ title, figure }: any) => {
            return <div className="flex flex-col items-start border p-2 justify-center rounded-md border-lightgrey ">
                <h2>{figure}</h2>
                <p className="text-center">{title}</p>
            </div>
        }

        return (
            <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold">{title} Summary</h3>
                <div className="grid grid-cols-2 md:flex flex-row gap-2">
                    <SummaryItem title="Last Run" figure={DateTime.fromISO(latestJob.createdAt).toFormat("yyyy-MM-dd HH:mm")} />
                    <SummaryItem title="Companies Processed" figure={totalProcessed} />
                    <SummaryItem title="Companies Skipped" figure={totalSkipped} />
                    <SummaryItem title="Errors" figure={totalErrors} />
                </div>
            </div>
        );
    };

    const generateExpandableContent = (job: any, navigate: any, type: 'fulfillment' | 'invoicing' | 'salarySacrifice') => {
        if (type === 'fulfillment') {
            // Combine disbursed and skipped applications into one array
            const allApplications = [
                ...(job.fulfilledApplications || []).map((detail: any) => ({
                    ...detail,
                    status: 'FULFILLED',
                    reason: null
                })),
                ...(job.skippedApplications || []).map((skip: any) => ({
                    ...skip,
                    status: 'SKIPPED'
                }))
            ];

            return (
                <div className="border-2 rounded-xl border-grey-100 bg-lightgrey/50 my-2 gap-2 flex flex-col">
                    <h3 className="text-lg font-semibold mx-4">Job Report for {DateTime.fromISO(job.createdAt).toFormat("yyyy-MM-dd HH:mm")}</h3>

                    <div className="mx-2 md:mx-4 px-2 py-1 rounded-xl">
                        <DynamicTable
                            loading={loadingJobs}
                            showSearchBox={true}
                            tableId={`BatchJob-${job.id}-Applications`}
                            cols={[
                                "Status",
                                "Company",
                                "Application",
                                "Benefit",
                                "Fulfillment #",
                                "Time",
                                "Reason"
                            ]}
                            filterableColumns={["Status", "Company", "Application", "Benefit"]}
                            showExport={true}
                            rows={allApplications.map((app) => [
                                app.status,
                                app.companyID,
                                app.applicationID,
                                app.benefitName,
                                app.fulfillmentNumber,
                                DateTime.fromISO(app.timestamp).toFormat("HH:mm:ss"),
                                app.reason || '-'
                            ])}
                            onRowClicked={(applicationID: string) => {
                                navigate(`/application/${applicationID}`);
                            }}
                            rowClasses={allApplications.map((app) =>
                                app.status === 'FULFILLED' ? 'bg-green-100' : 'bg-orange-100'
                            )}
                            idIndexInColumns={2} // Application ID column
                            showID={true}
                        />

                        {job.errors?.length > 0 && (
                            <div className="mt-4 bg-red-100 p-4 rounded-xl">
                                <h4 className="font-medium mb-2">Errors</h4>
                                <DynamicTable
                                    loading={loadingJobs}
                                    showSearchBox={true}
                                    tableId={`BatchJob-${job.id}-Errors`}
                                    cols={[
                                        "Company",
                                        "Error",
                                        "Time"
                                    ]}
                                    filterableColumns={["Company", "Error"]}
                                    showExport={true}
                                    rows={job.errors.map((error: any) => [
                                        error.companyID,
                                        error.error,
                                        DateTime.fromISO(error.timestamp).toFormat("HH:mm:ss")
                                    ])}
                                    onRowClicked={(index: number) => {
                                        navigate(`/companies/${job.errors[index].companyID}`);
                                    }}
                                    // rowClasses={job.errors.map(() => 'hover:bg-red-200')}
                                    idIndexInColumns={0} // Company ID column
                                    showID={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        } else if (type === 'invoicing') {
            // Invoicing expandable content
            return (
                <div className="border-2 rounded-xl border-grey-100 bg-lightgrey/50 my-2 gap-2 flex flex-col">
                    <h3 className="text-lg font-semibold mx-4">Job Report for {DateTime.fromISO(job.createdAt).toFormat("yyyy-MM-dd HH:mm")}</h3>

                    <div className="mx-2 md:mx-4 px-2 py-1 rounded-xl">
                        {/* Completed Companies Table */}
                        <h4 className="font-medium mb-2">Processed Companies</h4>
                        <DynamicTable
                            loading={loadingInvoicingJobs}
                            showSearchBox={true}
                            tableId={`InvoicingJob-${job.id}-Completed`}
                            cols={[
                                "Company",
                                "Invoice Number",
                                "Items",
                                "Total Amount",
                                "Time"
                            ]}
                            filterableColumns={["Company", "Invoice Number"]}
                            showExport={true}
                            rows={job.completedCompanies?.map((company: any) => [
                                company.companyID,
                                company.invoiceNumber,
                                company.itemsProcessed,
                                `£${company.totalAmount.toFixed(2)}`,
                                DateTime.fromISO(company.timestamp).toFormat("HH:mm:ss")
                            ]) || []}
                            onRowClicked={(companyID: string) => {
                                navigate(`/companies/${companyID}`);
                            }}
                            rowClasses={job.completedCompanies?.map(() => 'bg-green-100') || []}
                            idIndexInColumns={0}
                            showID={true}
                        />

                        {/* Skipped Companies Table */}
                        {job.skippedCompanies?.length > 0 && (
                            <div className="mt-4">
                                <h4 className="font-medium mb-2">Skipped Companies</h4>
                                <DynamicTable
                                    loading={loadingInvoicingJobs}
                                    showSearchBox={true}
                                    tableId={`InvoicingJob-${job.id}-Skipped`}
                                    cols={[
                                        "Company",
                                        "Reason",
                                        "Time"
                                    ]}
                                    filterableColumns={["Company", "Reason"]}
                                    showExport={true}
                                    rows={job.skippedCompanies.map((company: any) => [
                                        company.companyID,
                                        company.reason,
                                        DateTime.fromISO(company.timestamp).toFormat("HH:mm:ss")
                                    ])}
                                    onRowClicked={(companyID: string) => {
                                        navigate(`/companies/${companyID}`);
                                    }}
                                    rowClasses={job.skippedCompanies.map(() => 'bg-orange-100')}
                                    idIndexInColumns={0}
                                    showID={true}
                                />
                            </div>
                        )}

                        {/* Errors Table */}
                        {job.errors?.length > 0 && (
                            <div className="mt-4 bg-red-100 p-4 rounded-xl">
                                <h4 className="font-medium mb-2">Errors</h4>
                                <DynamicTable
                                    loading={loadingInvoicingJobs}
                                    showSearchBox={true}
                                    tableId={`InvoicingJob-${job.id}-Errors`}
                                    cols={[
                                        "Company",
                                        "Error",
                                        "Time"
                                    ]}
                                    filterableColumns={["Company", "Error"]}
                                    showExport={true}
                                    rows={job.errors.map((error: any) => [
                                        error.companyID,
                                        error.error,
                                        DateTime.fromISO(error.timestamp).toFormat("HH:mm:ss")
                                    ])}
                                    onRowClicked={(companyID: string) => {
                                        navigate(`/companies/${companyID}`);
                                    }}
                                    rowClasses={job.errors.map(() => 'bg-red-100')}
                                    idIndexInColumns={0}
                                    showID={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        } else if (type === 'salarySacrifice') {
            return (
                <div className="border-2 rounded-xl border-grey-100 bg-lightgrey/50 my-2 gap-2 flex flex-col">
                    <h3 className="text-lg font-semibold mx-4">
                        Job Report for {DateTime.fromISO(job.createdAt).toFormat("yyyy-MM-dd HH:mm")} - {job.month} {job.year}
                    </h3>

                    <div className="mx-2 md:mx-4 px-2 py-1 rounded-xl">
                        {/* Completed Companies Table */}
                        <h4 className="font-medium mb-2">Processed Companies</h4>
                        <DynamicTable
                            loading={false}
                            showSearchBox={true}
                            tableId={`SalSacJob-${job.id}-Completed`}
                            cols={[
                                "Company",
                                "Items Processed",
                                "Total Value",
                                "Time"
                            ]}
                            filterableColumns={["Company"]}
                            showExport={true}
                            rows={job.completedCompanies?.map((company: any) => [
                                company.companyID,
                                company.itemsProcessed,
                                `£${company.totalValue.toFixed(2)}`,
                                DateTime.fromISO(company.timestamp).toFormat("HH:mm:ss")
                            ]) || []}
                            onRowClicked={(companyID: string) => {
                                navigate(`/companies/${companyID}`);
                            }}
                            rowClasses={job.completedCompanies?.map(() => 'bg-green-100') || []}
                            idIndexInColumns={0}
                            showID={true}
                        />

                        {/* Skipped Companies Table */}
                        {job.skippedCompanies?.length > 0 && (
                            <div className="mt-4">
                                <h4 className="font-medium mb-2">Skipped Companies</h4>
                                <DynamicTable
                                    loading={false}
                                    showSearchBox={true}
                                    tableId={`SalSacJob-${job.id}-Skipped`}
                                    cols={[
                                        "Company",
                                        "Reason",
                                        "Time"
                                    ]}
                                    filterableColumns={["Company", "Reason"]}
                                    showExport={true}
                                    rows={job.skippedCompanies.map((company: any) => [
                                        company.companyID,
                                        company.reason,
                                        DateTime.fromISO(company.timestamp).toFormat("HH:mm:ss")
                                    ])}
                                    onRowClicked={(companyID: string) => {
                                        navigate(`/companies/${companyID}`);
                                    }}
                                    rowClasses={job.skippedCompanies.map(() => 'bg-orange-100')}
                                    idIndexInColumns={0}
                                    showID={true}
                                />
                            </div>
                        )}

                        {/* Errors Table */}
                        {job.errors?.length > 0 && (
                            <div className="mt-4 bg-red-100 p-4 rounded-xl">
                                <h4 className="font-medium mb-2">Errors</h4>
                                <DynamicTable
                                    loading={false}
                                    showSearchBox={true}
                                    tableId={`SalSacJob-${job.id}-Errors`}
                                    cols={[
                                        "Company",
                                        "Error",
                                        "Time"
                                    ]}
                                    filterableColumns={["Company", "Error"]}
                                    showExport={true}
                                    rows={job.errors.map((error: any) => [
                                        error.companyID,
                                        error.error,
                                        DateTime.fromISO(error.timestamp).toFormat("HH:mm:ss")
                                    ])}
                                    onRowClicked={(companyID: string) => {
                                        navigate(`/companies/${companyID}`);
                                    }}
                                    rowClasses={job.errors.map(() => 'bg-red-100')}
                                    idIndexInColumns={0}
                                    showID={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    };

    // Add this component to show active job progress
    const ActiveJobProgress = () => {
        if (!currentJobId) return null;

        const currentJob = batchJobs.find(job => job.id === currentJobId);
        if (!currentJob) return null;

        const progress = currentJob.progress || {};
        const total = progress.totalCompanies || 0;
        const processed = progress.processedCount || 0;
        const percentage = total ? Math.round((processed / total) * 100) : 0;

        return (
            <div className="mb-4 p-4 bg-blue-50 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">Active Job Progress</h3>
                <div className="flex flex-col gap-2">
                    <div className="w-full bg-gray-200 rounded-full h-4">
                        <div
                            className="bg-blue-600 h-4 rounded-full transition-all duration-500"
                            style={{ width: `${percentage}%` }}
                        />
                    </div>
                    <div className="flex justify-between text-sm">
                        <span>{processed} of {total} companies processed</span>
                        <span>{percentage}%</span>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mt-2">
                        <div className="text-center">
                            <div className="font-semibold">{progress.processedCount || 0}</div>
                            <div className="text-sm text-gray-600">Processed</div>
                        </div>
                        <div className="text-center">
                            <div className="font-semibold">{progress.skippedCount || 0}</div>
                            <div className="text-sm text-gray-600">Skipped</div>
                        </div>
                        <div className="text-center">
                            <div className="font-semibold">{progress.errorCount || 0}</div>
                            <div className="text-sm text-gray-600">Errors</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="h-screen flex-col flex-grow bg-white">
            <div className="flex-grow flex flex-col w-full h-full bg-white">
                <div className="my-4 px-4 w-full flex flex-col gap-4">
                    {/* Fulfillment Section */}
                    <div className="border-2 rounded-xl border-grey-100 p-4">
                        <div className="flex flex-row justify-between items-center mb-4">
                            <h2>FULFILLMENT BATCH JOBS</h2>
                            <Button
                                variant="contained"
                                onClick={runBatchJob}
                                disabled={runningBatch}
                                className="bg-primary hover:bg-primary/80"
                            >
                                {runningBatch ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    "Run Fulfillment Batch"
                                )}
                            </Button>
                        </div>

                        {/* Add the progress component */}
                        <ActiveJobProgress />

                        <SummaryComponent jobs={batchJobs} title="Fulfillment" />

                        <div className="mt-4">
                            <DynamicTable
                                showSearchBox={false}
                                tableId="BatchJobsTable"
                                cols={[
                                    "Date",
                                    "Companies Processed",
                                    "Processed",
                                    "Skipped",
                                    "Errors",
                                    "Duration"
                                ]}
                                filterableColumns={["Date"]}
                                showExport={true}
                                rows={batchJobs?.map((job) => [
                                    job.createdAt,
                                    job.totalCompaniesProcessed,
                                    job.fulfilledApplications?.length || 0,
                                    job.skippedApplications?.length || 0,
                                    job.errors?.length || 0,
                                    job.duration?.formatted
                                ])}
                                rowExpansionComponents={batchJobs?.map((job) => generateExpandableContent(job, navigate, 'fulfillment'))}
                                onRowClicked={(index: number) => {
                                    console.log("Selected job:", batchJobs[index]);
                                }}
                                idIndexInColumns={0}
                                showID={true}
                                loading={loadingJobs}
                            />
                        </div>
                    </div>

                    {/* Invoicing Section */}
                    <div className="border-2 rounded-xl border-grey-100 p-4">
                        <div className="flex flex-row justify-between items-center mb-4">
                            <h2>INVOICING BATCH JOBS</h2>
                            <Button
                                variant="contained"
                                onClick={runInvoicingJob}
                                disabled={runningInvoicing}
                                className="bg-primary hover:bg-primary/80"
                            >
                                {runningInvoicing ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    "Run Invoicing Batch"
                                )}
                            </Button>
                        </div>

                        <SummaryComponent jobs={invoicingJobs} title="Invoicing" />

                        <div className="mt-4">
                            <DynamicTable
                                showSearchBox={false}
                                tableId="InvoicingJobsTable"
                                cols={[
                                    "Date",
                                    "Companies Processed",
                                    "Companies Skipped",
                                    "Errors",
                                    "Status"
                                ]}
                                filterableColumns={["Date", "Status"]}
                                showExport={true}
                                rows={invoicingJobs?.map((job) => [
                                    job.createdAt,
                                    job.completedCompanies?.length || 0,
                                    job.skippedCompanies?.length || 0,
                                    job.errors?.length || 0,
                                    job.status
                                ])}
                                rowExpansionComponents={invoicingJobs?.map((job) => generateExpandableContent(job, navigate, 'invoicing'))}
                                onRowClicked={(index: number) => {
                                    console.log("Selected job:", invoicingJobs[index]);
                                }}
                                idIndexInColumns={0}
                                showID={true}
                                loading={loadingInvoicingJobs}
                            />
                        </div>
                    </div>

                    {/* Salary Sacrifice Section */}
                    <div className="border-2 rounded-xl border-grey-100 p-4">
                        <div className="flex flex-row justify-between items-center mb-4">
                            <h2>SALARY SACRIFICE BATCH JOBS</h2>
                            <Button
                                variant="contained"
                                onClick={runSalarySacrificeJob}
                                disabled={runningSalSac}
                                className="bg-primary hover:bg-primary/80"
                            >
                                {runningSalSac ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    "Run Salary Sacrifice Batch"
                                )}
                            </Button>
                        </div>

                        <SummaryComponent jobs={salarySacrificeJobs} title="Salary Sacrifice" />

                        <div className="mt-4">
                            <DynamicTable
                                showSearchBox={false}
                                tableId="SalarySacrificeJobsTable"
                                cols={[
                                    "Date",
                                    "Month",
                                    "Year",
                                    "Companies Processed",
                                    "Companies Skipped",
                                    "Errors",
                                    "Status"
                                ]}
                                filterableColumns={["Date", "Month", "Year", "Status"]}
                                showExport={true}
                                rows={salarySacrificeJobs?.map((job) => [
                                    job.createdAt,
                                    job.month,
                                    job.year,
                                    job.completedCompanies?.length || 0,
                                    job.skippedCompanies?.length || 0,
                                    job.errors?.length || 0,
                                    job.status
                                ])}
                                rowExpansionComponents={salarySacrificeJobs?.map((job) =>
                                    generateExpandableContent(job, navigate, 'salarySacrifice')
                                )}
                                onRowClicked={(index: number) => {
                                    console.log("Selected job:", salarySacrificeJobs[index]);
                                }}
                                idIndexInColumns={0}
                                showID={true}
                                loading={loadingSalSacJobs}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BatchJobs; 