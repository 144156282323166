// Button.tsx
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { AnimatePresence, motion } from "framer-motion";
import { useUser } from "../context/AppContext";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface StatCardProps {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: string;
  loading?: boolean;
  value: string;
  prevValue?: string;
  percentChange?: number;
  positiveDirection?: string;
  arrowDirection?: string;
  description: string;
  unit?: string;
  showComparison?: boolean;
}

const StatCard: React.FC<StatCardProps> = ({
  children,
  onClick,
  className,
  type,
  loading,
  value,
  arrowDirection,
  prevValue,
  percentChange,
  positiveDirection,
  unit,
  description,
  showComparison = false,
}) => {
  // Default classes for the button's base styling
  const baseClasses =
    "min-h-[90px] min-w-[200px] relative max-w-[400px] bg-white border hover:bg-secondary font-montserrat text-xl  mt-2 text-darkgrey font-semibold py-2 px-4 rounded-[15px] focus:outline-none focus:shadow-outline transition-all duration-200 hover:text-primary";

  // Combine base classes with any additional classes passed via props
  const statCardClasses = `${baseClasses} ${className || ""}`;
  const { runningAllApplicationsQuery } = useUser();

  // Determine if percent change is positive, negative, or zero
  const getChangeArrow = () => {
    if (!percentChange || percentChange === 0) return null;
    return percentChange > 0 ? "UP" : "DOWN";
  };

  // Determine color of percent change
  const getChangeColor = () => {
    if (!percentChange || percentChange === 0) return "text-gray-500";
    return percentChange > 0 ? "text-green-500" : "text-red";
  };

  return (
    <div onClick={onClick} className={statCardClasses}>
      <AnimatePresence mode="wait">
        {loading ? (
          <motion.div
            className="w-full h-[100px] px-4 flex items-center justify-center py-4"
            key="loader"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <CircularProgress size={24} style={{ color: "blue" }} />
          </motion.div>
        ) : (
          <motion.div
            className="w-full h-[100px] flex flex-row "
            key="content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="flex flex-col  h-full px-4 py-2 justify-center">
              <div className="text-darkgrey flex flex-row items-end ">
                <h1 className="text-darkgrey my-0">{value}</h1>
                <p className="ml-1 my-1">{unit}</p>
              </div>

              {/* Show comparison with previous period if enabled */}
              {showComparison && prevValue && (
                <div className="text-darkgrey flex flex-row items-center text-sm">
                  <span className="mr-1">vs</span>
                  <span>{prevValue}{unit}</span>
                  {percentChange !== undefined && (
                    <span className={`ml-2 ${getChangeColor()}`}>
                      {percentChange > 0 ? "+" : ""}{percentChange}%
                      {getChangeArrow() === "UP" ? " ↑" : getChangeArrow() === "DOWN" ? " ↓" : ""}
                    </span>
                  )}
                </div>
              )}

              {/* Original prevValue display logic (keep for backward compatibility) */}
              {!showComparison && prevValue && (
                <div className="text-darkgrey flex flex-row items-end ">
                  <h4 className="text-darkgrey my-0 text-sm">From {prevValue}</h4>
                  <p className="text-xs ml-0.5 mt-0.5 ">{unit}</p>
                </div>
              )}

              <h3 className="text-darkgrey text-sm">{description}</h3>
            </div>
            {arrowDirection && (
              <div className="w-1/3 h-full flex items-center justify-center">
                {arrowDirection == "UP" ? (
                  <ArrowUpwardIcon
                    sx={{ color: positiveDirection == arrowDirection ? "green" : "red", width: "50px", height: "50px" }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    sx={{ color: positiveDirection == arrowDirection ? "green" : "red", width: "50px", height: "50px" }}
                  />
                )}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default StatCard;
