import { DateTime } from 'luxon';

/**
 * Checks if the user is authenticated by verifying token existence and validity
 */
export const isAuthenticated = (): boolean => {
    const token = localStorage.getItem('sessionToken');
    return !!token && !isTokenExpired(token);
};

/**
 * Gets the authentication token if it exists and is valid
 */
export const getAuthToken = (): string | null => {
    const token = localStorage.getItem('sessionToken');
    if (!token || isTokenExpired(token)) {
        return null;
    }
    return token;
};

/**
 * Checks if a JWT token is expired
 */
export const isTokenExpired = (token: string): boolean => {
    try {
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const expiryDate = DateTime.fromSeconds(tokenPayload.exp);
        return expiryDate < DateTime.now();
    } catch (e) {
        console.error("Failed to parse token:", e);
        return true;
    }
};

/**
 * Gets the user data from localStorage
 */
export const getUserData = (): any | null => {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null;
};