// components/LoadingIndicator.tsx
import { DateTime } from "luxon";
import { getStatusStyles } from "../utils/util";
const LoadingIndicator = ({ application }: any) => (
  // <div className={"w-full h-full bg-red fixed top-0 left-0 flex items-center justify-center"}>
  <div className="flex flex-row items-start justify-start">
    <p className={`flex-wrap text-wrap p-1 px-2 border-2 rounded-lg relative ${getStatusStyles(application.status)}`}>
      {application.autoApproved && <img src={require("../assets/aiLogo.png")} className="w-4 h-4 absolute top-1/2 left-[-8px] -translate-y-1/2" />}
      <p>{(application.status.replaceAll("_", " ").split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase()).join(' '))}</p>
      {application.status == "PAUSED" && <p className="text-sm text-gray-500">Resuming: {(application.pauseConfiguration?.earlyResumeRequestedDate || application.pauseConfiguration?.pauseEndDate) ? DateTime.fromISO(application.pauseConfiguration?.earlyResumeRequestedDate ?? application.pauseConfiguration?.pauseEndDate).toFormat('dd LLL yyyy') : "N/A"}</p>}
    </p>
  </div>
  // </div>
);

export default LoadingIndicator;
