import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { usePlatform } from '../../hooks/usePlatforms';
import { Platform } from '../../types/platform';
import { FiUploadCloud, FiArrowLeft, FiSearch, FiCheckCircle, FiX, FiRefreshCw } from 'react-icons/fi';
import DynamicTable from '../../components/DynamicTable/DynamicTable';
import { toast } from 'react-toastify';
import { useUser } from '../../context/AppContext';
import readXlsxFile from 'read-excel-file';
import Papa from 'papaparse';
import { getCompaniesByPlatformId, processApplications } from '../../services/platformsApi';
import PlatformFieldMapping from './PlatformFieldMapping';
import { FieldMapping } from '../../types/platform';
import { useBenefits } from '../../hooks/useBenefits';
import { Benefit } from '../../types/benefit';

// Define interfaces for our component
interface ProcessedRow {
    rowData: any[];
    valid: boolean;
    errors?: string[];
}

interface MappedApplication {
    employee: Record<string, any>;
    benefit: Record<string, any>;
    metadata: Record<string, any>;
    rawData: Record<string, any>;
    benefitId?: string;
    isValid: boolean;
    errors: string[];
}

interface UploadState {
    fileName: string;
    fileType: string;
    fileSize: number;
    rawData: any[];
    headers: string[];
    mappedData: MappedApplication[];
    hasHeaderRow: boolean;
    fieldMappings?: FieldMapping[];
}

enum UploadStep {
    Upload,
    FieldMapping,
    Preview,
    Processing
}

const PlatformCompanies: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { data: platform, isLoading: platformLoading } = usePlatform(id || '');
    const [companies, setCompanies] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<any>(null);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [processingFile, setProcessingFile] = useState(false);
    const [uploadState, setUploadState] = useState<UploadState | null>(null);
    const [currentStep, setCurrentStep] = useState<UploadStep>(UploadStep.Upload);
    const [availableBenefits, setAvailableBenefits] = useState<Benefit[]>([]);
    const [isProcessing, setIsProcessing] = useState(false);

    // Get benefits from the useBenefits hook
    const { benefits, loadingBenefits } = useBenefits();

    // Fetch companies when platform ID is available
    useEffect(() => {
        if (id) {
            setIsLoading(true);

            // Use the getCompaniesByPlatformId function from platformsApi
            getCompaniesByPlatformId(id)
                .then(data => {
                    setCompanies(data);
                    setIsLoading(false);
                })
                .catch((error: Error) => {
                    console.error('Error fetching companies:', error);
                    toast.error('Failed to load companies for this platform');
                    setIsLoading(false);
                });
        }
    }, [id]);

    // Load company-specific benefits when a company is selected
    useEffect(() => {
        if (selectedCompanyId && benefits) {
            const company = companies.find(c => c.companyID === selectedCompanyId);
            setSelectedCompany(company);

            if (company && company.benefitsLinked && Array.isArray(company.benefitsLinked)) {
                const companyBenefits = benefits.filter(benefit =>
                    company.benefitsLinked.includes(benefit.id)
                );
                console.log('Available benefits for company:', companyBenefits);

                // Log all platform benefit mappings
                if (platform?.benefitMappings?.length) {
                    console.log('Platform benefit mappings:', platform.benefitMappings);

                    // Check which mappings have benefitIds that exist in companyBenefits
                    const validMappings = platform.benefitMappings.filter(mapping =>
                        companyBenefits.some(benefit => benefit.id === mapping.benefitId)
                    );

                    console.log('Valid benefit mappings for this company:', validMappings);
                    console.log('Invalid benefit mappings:', platform.benefitMappings.filter(mapping =>
                        !companyBenefits.some(benefit => benefit.id === mapping.benefitId)
                    ));
                }

                setAvailableBenefits(companyBenefits);

                // After setting available benefits, check mapped data for benefit IDs that don't exist
                if (uploadState && uploadState.mappedData && uploadState.mappedData.length > 0) {
                    console.log('Current mapped data with benefit IDs:',
                        uploadState.mappedData.map(app => ({
                            name: `${app.employee.firstName} ${app.employee.surname}`,
                            benefitName: app.benefit.name,
                            benefitId: app.benefitId
                        }))
                    );

                    const updatedMappedData = [...uploadState.mappedData];
                    let hasChanges = false;

                    updatedMappedData.forEach(app => {
                        if (app.benefitId) {
                            const exists = companyBenefits.some(b => b.id === app.benefitId);
                            console.log(`Checking if benefit ID ${app.benefitId} exists in available benefits: ${exists}`);

                            if (!exists) {
                                console.warn(`Benefit ID ${app.benefitId} not found in available benefits - clearing it`);
                                delete app.benefitId;
                                hasChanges = true;
                            }
                        }
                    });

                    // Update the state if any benefit IDs were removed
                    if (hasChanges) {
                        console.log('Updating state to remove invalid benefit IDs');
                        setUploadState(prev => prev ? {
                            ...prev,
                            mappedData: updatedMappedData
                        } : null);
                    }
                }
            } else {
                setAvailableBenefits([]);
            }
        }
    }, [selectedCompanyId, benefits, companies, uploadState, platform?.benefitMappings]);

    const parseFileData = (file: File): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                if (file.name.endsWith('.csv')) {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        Papa.parse(event.target?.result as string, {
                            complete: (results) => {
                                resolve({
                                    rawData: results.data,
                                    headers: results.data[0] as string[],
                                    fileType: 'csv'
                                });
                            },
                            error: (error: Error) => {
                                reject(error);
                            }
                        });
                    };

                    reader.onerror = () => {
                        reject(new Error('Failed to read CSV file'));
                    };

                    reader.readAsText(file);
                } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                    readXlsxFile(file).then((rows: any[][]) => {
                        resolve({
                            rawData: rows,
                            headers: rows[0] as string[],
                            fileType: 'excel'
                        });
                    }).catch((error: Error) => {
                        reject(error);
                    });
                } else {
                    reject(new Error('Unsupported file type'));
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            processFile(file);
        }
    };

    const processFile = async (file: File) => {
        setProcessingFile(true);

        try {
            const parsedData = await parseFileData(file);

            setUploadState({
                fileName: file.name,
                fileSize: file.size,
                fileType: parsedData.fileType,
                rawData: parsedData.rawData,
                headers: parsedData.headers,
                mappedData: [],
                hasHeaderRow: true
            });

            // Check if platform has field mappings defined
            if (platform?.fieldMappings?.mappings && platform.fieldMappings.mappings.length > 0) {
                // Safe to use non-null assertion since we've checked above
                const platformMappings = platform!.fieldMappings!.mappings;
                const useHeaderRow = platform!.fieldMappings!.hasHeaderRow ?? true;

                const mappedApplications = mapDataToApplications(
                    parsedData.rawData,
                    platformMappings,
                    useHeaderRow,
                    benefits || []
                );

                // Update state with mappings and data
                setUploadState(prev => {
                    if (!prev) return prev;
                    return {
                        ...prev,
                        hasHeaderRow: useHeaderRow,
                        fieldMappings: platformMappings,
                        mappedData: mappedApplications
                    };
                });

                // Skip directly to preview step
                setCurrentStep(UploadStep.Preview);
                toast.info('Using platform\'s field mappings');
            } else {
                // If no mappings found, go to mapping step
                setCurrentStep(UploadStep.FieldMapping);
                toast.warning('This platform has no field mappings defined. Please create mappings now.');
            }
        } catch (error) {
            toast.error(`Failed to parse file: ${error instanceof Error ? error.message : 'Unknown error'}`);
        } finally {
            setProcessingFile(false);
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            processFile(e.dataTransfer.files[0]);
        }
    };

    const handleUploadClick = (companyId: string) => {
        setSelectedCompanyId(companyId);
        setShowUploadModal(true);
        setCurrentStep(UploadStep.Upload);
    };

    const closeUploadModal = () => {
        setShowUploadModal(false);
        setUploadState(null);
        setCurrentStep(UploadStep.Upload);
        setSelectedCompanyId(null);
        setSelectedCompany(null);
    };

    const handleMappingComplete = (mappings: FieldMapping[]) => {
        if (uploadState) {
            // Map the data using the provided mappings
            const mappedApplications = mapDataToApplications(
                uploadState.rawData,
                mappings,
                uploadState.hasHeaderRow,
                benefits || [] // Pass all benefits since company-specific ones may not be loaded yet
            );

            // Update uploadState with new mappings and mapped data
            setUploadState({
                ...uploadState,
                fieldMappings: mappings,
                mappedData: mappedApplications
            });

            // Move to preview step
            setCurrentStep(UploadStep.Preview);
        }
    };

    const mapDataToApplications = (
        rawData: any[],
        mappings: FieldMapping[],
        hasHeaderRow: boolean,
        allBenefits: Benefit[] = []
    ): MappedApplication[] => {
        // Skip the header row if it exists
        const dataToProcess = hasHeaderRow ? rawData.slice(1) : rawData;

        // Get headers directly from the raw data if we have a header row
        const headers = hasHeaderRow ? rawData[0] as string[] : [];

        console.log('mapDataToApplications debug:');
        console.log('hasHeaderRow:', hasHeaderRow);
        console.log('Headers from file:', headers);
        console.log('Platform benefit mappings:', platform?.benefitMappings);
        console.log('Platform action mappings:', platform?.actionMappings);
        console.log('Available benefits count:', allBenefits.length);

        return dataToProcess.map((row) => {
            const application: MappedApplication = {
                employee: {},
                benefit: {},
                metadata: {},
                rawData: {},
                isValid: true,
                errors: []
            };

            // Process each mapping
            for (const mapping of mappings) {
                // Find the index of the source column in the headers array
                const sourceIdx = hasHeaderRow
                    ? headers.findIndex(h => h === mapping.sourceColumn)
                    : uploadState?.headers.findIndex(h => h === mapping.sourceColumn) ?? -1;

                const value = sourceIdx >= 0 ? row[sourceIdx] : null;

                let finalValue = value;

                // Use default value if field is empty and default exists
                if ((finalValue === null || finalValue === undefined || finalValue === '') && mapping.defaultValue) {
                    finalValue = mapping.defaultValue;
                }

                // Check if required field is missing
                if (mapping.isRequired && (finalValue === null || finalValue === undefined || finalValue === '')) {
                    application.isValid = false;
                    application.errors.push(`Required field "${mapping.sourceColumn}" is missing`);
                    continue;
                }

                // Skip if no value
                if (finalValue === null || finalValue === undefined || finalValue === '') {
                    continue;
                }

                // Store the raw value for display
                application.rawData[mapping.sourceColumn] = finalValue;

                // Map to correct section and field
                const [section, field] = mapping.targetField.split('.');
                if (section && field && application[section as keyof Pick<MappedApplication, 'employee' | 'benefit' | 'metadata'>]) {
                    // Special handling for phone numbers
                    if (section === 'employee' && field === 'phone' && finalValue) {
                        // Convert to string if it's not already
                        let phoneValue = finalValue.toString().trim();

                        // Remove any non-digit characters
                        phoneValue = phoneValue.replace(/\D/g, '');

                        // Check if it's a UK number without country code
                        if (phoneValue.length === 10 && phoneValue.startsWith('7')) {
                            // Add +44 prefix for 7XXXXXXXXX format
                            phoneValue = `+44${phoneValue}`;
                            console.log(`Formatted phone number without leading 0: ${finalValue} -> ${phoneValue}`);
                        } else if (phoneValue.length === 11 && phoneValue.startsWith('07')) {
                            // Replace leading 0 with +44 for 07XXXXXXXXX format
                            phoneValue = `+44${phoneValue.substring(1)}`;
                            console.log(`Formatted phone number with leading 0: ${finalValue} -> ${phoneValue}`);
                        } else if (!phoneValue.startsWith('+')) {
                            // Add + if there's no + but number has country code
                            phoneValue = `+${phoneValue}`;
                            console.log(`Added + prefix to phone number: ${finalValue} -> ${phoneValue}`);
                        }

                        application.employee[field] = phoneValue;
                    }
                    // Special handling for action field - map platform-specific values to standard actions
                    else if (section === 'metadata' && field === 'action' && platform?.actionMappings?.length) {
                        // Convert value to lowercase string for comparison and trim whitespace
                        const normalizedValue = finalValue.toString().toLowerCase().trim();

                        const actionMapping = platform.actionMappings.find(m => {
                            // Normalize platform action value for comparison
                            const normalizedPlatformValue = m.platformValue.toLowerCase().trim();
                            return normalizedPlatformValue === normalizedValue;
                        });

                        if (actionMapping) {
                            console.log(`Mapped action value: "${finalValue}" -> "${actionMapping.standardAction}"`);
                            application.metadata[field] = actionMapping.standardAction;
                        } else {
                            console.log(`No action mapping found for "${finalValue}"`);
                            application.metadata[field] = finalValue;
                        }
                    }
                    // Special handling for benefit name - map to benefit ID if mapping exists
                    else if (section === 'benefit' && field === 'name' && platform?.benefitMappings?.length) {
                        // Convert value to lowercase string for comparison and trim whitespace
                        const normalizedValue = finalValue?.toString().toLowerCase().trim();
                        console.log(`Trying to map benefit name: "${finalValue}" (normalized: "${normalizedValue}")`);

                        if (normalizedValue) {
                            // Try to find exact match first
                            let benefitMapping = platform.benefitMappings.find(m => {
                                const normalizedPlatformName = m.platformBenefitName.toLowerCase().trim();
                                return normalizedPlatformName === normalizedValue;
                            });

                            // If no exact match, try contains match
                            if (!benefitMapping) {
                                benefitMapping = platform.benefitMappings.find(m => {
                                    const normalizedPlatformName = m.platformBenefitName.toLowerCase().trim();
                                    return normalizedValue.includes(normalizedPlatformName) ||
                                        normalizedPlatformName.includes(normalizedValue);
                                });

                                if (benefitMapping) {
                                    console.log(`Found partial match for "${normalizedValue}" -> "${benefitMapping.platformBenefitName}"`);
                                }
                            }

                            if (benefitMapping) {
                                console.log(`Mapped benefit: "${finalValue}" -> ID: "${benefitMapping.benefitId}"`);

                                // Check if this benefit ID exists in availableBenefits
                                const benefitExists = allBenefits.some(b => b.id === benefitMapping!.benefitId);
                                console.log(`Benefit ID ${benefitMapping.benefitId} exists in available benefits: ${benefitExists}`);

                                if (benefitExists) {
                                    // Set the benefit ID directly
                                    application.benefitId = benefitMapping.benefitId;
                                    // Still store the name for display purposes
                                    application.benefit[field] = finalValue;
                                    console.log(`Successfully set benefitId to ${benefitMapping.benefitId}`);
                                } else {
                                    console.warn(`Benefit ID ${benefitMapping.benefitId} not available for this company - skipping mapping`);
                                    application.benefit[field] = finalValue;
                                }
                            } else {
                                console.log(`No benefit mapping found for "${finalValue}"`);
                                application.benefit[field] = finalValue;

                                // Log all available mappings for debugging
                                console.log('Available mappings:');
                                platform.benefitMappings.forEach(m => {
                                    console.log(`- "${m.platformBenefitName}" -> "${m.benefitId}"`);
                                });
                            }
                        } else {
                            console.log(`Empty benefit name value, can't map`);
                            application.benefit[field] = finalValue;
                        }
                    }
                    else {
                        application[section as keyof Pick<MappedApplication, 'employee' | 'benefit' | 'metadata'>][field] = finalValue;
                    }
                }
            }

            return application;
        });
    };

    const handleBenefitSelection = (index: number, benefitId: string) => {
        if (uploadState) {
            const updatedMappedData = [...uploadState.mappedData];
            updatedMappedData[index] = {
                ...updatedMappedData[index],
                benefitId
            };

            setUploadState({
                ...uploadState,
                mappedData: updatedMappedData
            });
        }
    };

    const handleProcessApplications = async () => {
        if (!uploadState || !selectedCompanyId || !id) return;

        // Check if all rows have benefits selected
        const missingBenefits = uploadState.mappedData.filter(app => !app.benefitId).length;
        if (missingBenefits > 0) {
            toast.error(`Please select benefits for all ${missingBenefits} remaining applications`);
            return;
        }

        setIsProcessing(true);

        try {
            // Prepare applications for submission
            const applications = uploadState.mappedData.map(app => ({
                employee: app.employee,
                benefit: {
                    id: app.benefitId,
                    ...app.benefit
                },
                metadata: app.metadata,
                company: {
                    companyID: selectedCompanyId
                }
            }));

            // Submit to the backend
            const result = await processApplications(id, selectedCompanyId, applications);

            toast.success(`Successfully processed ${result.success.length} applications`);

            if (result.errors && result.errors.length > 0) {
                console.warn('Some applications had errors:', result.errors);
                toast.warning(`${result.errors.length} applications had errors. Check the console for details.`);
            }

            // Refresh companies data
            getCompaniesByPlatformId(id)
                .then(data => {
                    setCompanies(data);
                })
                .catch((error: Error) => {
                    console.error('Error refreshing companies:', error);
                });

            // Close the modal
            closeUploadModal();
        } catch (error) {
            console.error('Error processing applications:', error);
            toast.error(`Failed to process applications: ${error instanceof Error ? error.message : 'Unknown error'}`);
        } finally {
            setIsProcessing(false);
        }
    };

    const toggleHeaderRow = () => {
        if (uploadState) {
            setUploadState({
                ...uploadState,
                hasHeaderRow: !uploadState.hasHeaderRow,
                headers: uploadState.hasHeaderRow ? [] : (uploadState.rawData[0] as string[])
            });
        }
    };

    // Define columns for companies table
    const columns = ['Company ID', 'Name', 'Status', 'Applications', 'Actions'];

    // Transform companies data to rows for DynamicTable
    const rows = companies.map(company => [
        company.companyID,
        company.name,
        company.active ? (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                Active
            </span>
        ) : (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                Inactive
            </span>
        ),
        company.applicationCount || 0,
        <button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleUploadClick(company.companyID);
            }}
            className="text-blue-600 hover:text-blue-900 bg-transparent shadow-none flex items-center"
        >
            <FiUploadCloud className="mr-1" /> Upload Applications
        </button>
    ]);

    const renderModalContent = () => {
        if (!uploadState) {
            return (
                <div
                    className={`border-2 border-dashed rounded-lg p-8 text-center ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {processingFile ? (
                        <div className="flex flex-col items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
                            <p className="text-lg">Parsing file...</p>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center">
                            <FiUploadCloud className="h-12 w-12 text-gray-400 mb-2" />
                            <p className="text-lg">Drag and drop a file here, or</p>
                            <label className="mt-2 cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">
                                Browse Files
                                <input
                                    type="file"
                                    accept=".csv,.xlsx,.xls"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                            <p className="mt-2 text-sm text-gray-500">
                                Supported formats: CSV, Excel (.xlsx, .xls)
                            </p>
                        </div>
                    )}
                </div>
            );
        }

        switch (currentStep) {
            case UploadStep.FieldMapping:
                return (
                    <div>
                        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-4">
                            <h3 className="text-lg font-semibold text-blue-700 mb-2">File Preview</h3>
                            <div className="flex justify-between mb-4">
                                <div>
                                    <p><span className="font-semibold">File:</span> {uploadState.fileName}</p>
                                    <p><span className="font-semibold">Size:</span> {(uploadState.fileSize / 1024).toFixed(2)} KB</p>
                                </div>
                                <div>
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={uploadState.hasHeaderRow}
                                            onChange={toggleHeaderRow}
                                            className="mr-2"
                                        />
                                        First row contains headers
                                    </label>
                                </div>
                            </div>

                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border border-gray-200">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            {uploadState.hasHeaderRow && uploadState.headers.map((header, index) => (
                                                <th key={index} className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    {header}
                                                </th>
                                            ))}
                                            {!uploadState.hasHeaderRow && uploadState.rawData[0].map((_: any, index: number) => (
                                                <th key={index} className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Column {index + 1}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadState.rawData.slice(uploadState.hasHeaderRow ? 1 : 0, 5).map((row, rowIndex) => (
                                            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                {row.map((cell: any, cellIndex: number) => (
                                                    <td key={cellIndex} className="py-2 px-4 border-b text-sm">
                                                        {cell?.toString() || ''}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <p className="text-sm text-gray-500 mt-2">
                                Showing first {Math.min(5, uploadState.rawData.length - (uploadState.hasHeaderRow ? 1 : 0))} rows of {uploadState.rawData.length - (uploadState.hasHeaderRow ? 1 : 0)}
                            </p>
                        </div>

                        {/* Field mapping component */}
                        <PlatformFieldMapping
                            fileHeaders={uploadState.headers}
                            platformId={id || ''}
                            onMappingComplete={handleMappingComplete}
                            initialMappings={platform?.fieldMappings?.mappings || []}
                        />
                    </div>
                );

            case UploadStep.Preview:
                return (
                    <div>
                        <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
                            <h3 className="text-lg font-semibold text-green-700 mb-2">Applications Preview</h3>
                            <p className="mb-4">The following applications will be created. Please select the appropriate benefit for each application.</p>

                            {platform?.benefitMappings && platform.benefitMappings.length > 0 && (
                                <div className="bg-blue-50 border border-blue-200 rounded-lg p-3 mb-4">
                                    <p className="text-blue-700 flex items-center">
                                        <FiCheckCircle className="mr-2" />
                                        Using platform's benefit mappings to automatically match benefits.
                                        {uploadState?.mappedData && uploadState.mappedData.filter(app => app.benefitId).length > 0 && (
                                            <span className="ml-1 font-medium">
                                                ({uploadState.mappedData.filter(app => app.benefitId).length} of {uploadState.mappedData.length} benefits auto-matched)
                                            </span>
                                        )}
                                    </p>
                                </div>
                            )}

                            {platform?.actionMappings && platform.actionMappings.length > 0 && (
                                <div className="bg-blue-50 border border-blue-200 rounded-lg p-3 mb-4">
                                    <p className="text-blue-700 flex items-center">
                                        <FiCheckCircle className="mr-2" />
                                        Using platform's action mappings to standardize actions.
                                    </p>
                                </div>
                            )}

                            {loadingBenefits ? (
                                <div className="flex justify-center my-4">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                    <span className="ml-2">Loading benefits...</span>
                                </div>
                            ) : availableBenefits.length === 0 ? (
                                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
                                    <p className="text-yellow-700">No benefits are linked to this company. Please add benefits to the company before uploading applications.</p>
                                </div>
                            ) : (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full bg-white border border-gray-200">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    #
                                                </th>
                                                <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Name
                                                </th>
                                                <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Email
                                                </th>
                                                <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Mapped Benefit Name
                                                </th>
                                                <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Select Benefit
                                                </th>
                                                {platform?.actionMappings && platform.actionMappings.length > 0 && (
                                                    <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Action
                                                    </th>
                                                )}
                                                <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadState.mappedData.map((app, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                    <td className="py-2 px-4 border-b text-sm">
                                                        {index + 1}
                                                    </td>
                                                    <td className="py-2 px-4 border-b text-sm">
                                                        {app.employee.firstName} {app.employee.surname}
                                                    </td>
                                                    <td className="py-2 px-4 border-b text-sm">
                                                        {app.employee.email}
                                                    </td>
                                                    <td className="py-2 px-4 border-b text-sm">
                                                        {app.benefit.name || <span className="text-gray-400">Not mapped</span>}
                                                    </td>
                                                    <td className="py-2 px-4 border-b text-sm">
                                                        <select
                                                            className="w-full p-2 border border-gray-300 rounded-md"
                                                            value={app.benefitId || ''}
                                                            onChange={(e) => handleBenefitSelection(index, e.target.value)}
                                                        >
                                                            <option value="">Select a benefit</option>
                                                            {availableBenefits.map(benefit => {
                                                                // Check if this is the benefit that was auto-mapped
                                                                const isAutoMapped = app.benefitId === benefit.id &&
                                                                    platform?.benefitMappings?.some(m =>
                                                                        m.benefitId === benefit.id &&
                                                                        app.benefit.name &&
                                                                        (m.platformBenefitName.toLowerCase().includes(app.benefit.name.toString().toLowerCase()) ||
                                                                            app.benefit.name.toString().toLowerCase().includes(m.platformBenefitName.toLowerCase()))
                                                                    );

                                                                // Is this the currently selected benefit
                                                                const isSelected = app.benefitId === benefit.id;

                                                                // Check if this benefit name matches the mapped benefit name
                                                                const isNameMatch = app.benefit.name &&
                                                                    typeof app.benefit.name === 'string' &&
                                                                    typeof benefit.name === 'string' &&
                                                                    benefit.name.toLowerCase().includes(app.benefit.name.toLowerCase());

                                                                return (
                                                                    <option
                                                                        key={benefit.id}
                                                                        value={benefit.id}
                                                                        className={isAutoMapped
                                                                            ? 'font-bold text-blue-700 bg-blue-50'
                                                                            : isSelected
                                                                                ? 'font-bold bg-green-50'
                                                                                : isNameMatch ? 'font-bold' : ''}
                                                                    >
                                                                        {isAutoMapped ? '✓ (Auto) ' : isSelected ? '✓ ' : ''}{benefit.name} (£{benefit.originalCost})
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        {app.benefitId && (
                                                            <div className="mt-1 text-xs text-gray-500">
                                                                Selected: {availableBenefits.find(b => b.id === app.benefitId)?.name || 'Unknown'}
                                                            </div>
                                                        )}
                                                    </td>
                                                    {platform?.actionMappings && platform.actionMappings.length > 0 && (
                                                        <td className="py-2 px-4 border-b text-sm">
                                                            {app.metadata.action ? (
                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                                    {app.metadata.action}
                                                                </span>
                                                            ) : (
                                                                <span className="text-gray-400">None</span>
                                                            )}
                                                        </td>
                                                    )}
                                                    <td className="py-2 px-4 border-b text-sm">
                                                        {!app.isValid ? (
                                                            <div className="text-red-500">
                                                                <FiX className="inline mr-1" /> Invalid
                                                                <div className="text-xs mt-1">
                                                                    {app.errors.map((err, i) => (
                                                                        <div key={i}>{err}</div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ) : app.benefitId ? (
                                                            <div className="text-green-500">
                                                                <FiCheckCircle className="inline mr-1" /> Ready
                                                            </div>
                                                        ) : (
                                                            <div className="text-yellow-500">
                                                                Needs benefit selection
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>

                        <div className="mt-4 flex justify-end space-x-3">
                            <button
                                onClick={() => setCurrentStep(UploadStep.FieldMapping)}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md"
                                disabled={isProcessing}
                            >
                                Back to Mapping
                            </button>
                            <button
                                onClick={handleProcessApplications}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center"
                                disabled={isProcessing || availableBenefits.length === 0}
                            >
                                {isProcessing ? (
                                    <>
                                        <FiRefreshCw className="mr-2 animate-spin" /> Processing...
                                    </>
                                ) : (
                                    'Process Applications'
                                )}
                            </button>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    if (platformLoading || isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
            </div>
        );
    }

    if (!platform) {
        return (
            <div className="p-6">
                <div className="text-red-500">Platform not found</div>
                <button
                    onClick={() => navigate('/platforms')}
                    className="mt-4 text-blue-500 hover:text-blue-700"
                >
                    Back to Platforms
                </button>
            </div>
        );
    }

    return (
        <div className="p-6">
            <div className="flex items-center mb-6">
                <button
                    onClick={() => navigate('/platforms')}
                    className="text-blue-500 hover:text-blue-700 flex items-center mr-4"
                >
                    <FiArrowLeft className="mr-1" /> Back to Platforms
                </button>
                <h1 className="text-2xl font-semibold">{platform.name} - Companies</h1>
            </div>

            <div className="mb-4 flex">
                <div className="relative flex-1">
                    <input
                        type="text"
                        placeholder="Search companies..."
                        className="w-full p-2 pl-10 border border-gray-300 rounded-md"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <FiSearch className="absolute left-3 top-3 text-gray-400" />
                </div>
            </div>

            {companies.length === 0 ? (
                <div className="bg-white p-6 rounded-lg shadow text-center">
                    <p className="text-gray-500">No companies found for this platform</p>
                </div>
            ) : (
                <DynamicTable
                    tableId="platform-companies-table"
                    heading=""
                    cols={columns}
                    rows={rows}
                    onRowClicked={(id: string) => navigate(`/companies/${id}`)}
                    loading={false}
                    idIndexInColumns={0}
                    showID={true}
                    searchKeys={["Company ID", "Name"]}
                    searchQueryInput={searchTerm}
                    setSearchQueryInput={setSearchTerm}
                    showExport={true}
                    showSearchBox={false}
                />
            )}

            {/* Upload Modal */}
            {showUploadModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">
                                Upload Applications for {selectedCompany?.name || 'Company'}
                            </h2>
                            <button onClick={closeUploadModal} className="text-gray-500 hover:text-gray-700">
                                <FiX className="w-5 h-5" />
                            </button>
                        </div>

                        {renderModalContent()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlatformCompanies;
