import { createContext, useContext, Dispatch, SetStateAction, useState, useRef, useEffect } from "react";
import { useAPI } from "../hooks/useAPI";
import { useFulfillment } from "../hooks/useFulfillment";
import { useAnalytics } from '../hooks/useAnalytics';
import { AnalyticsResponse, AnalyticsFilters } from '../hooks/useAnalytics';

import { DateTime } from "luxon";
import { useAuth } from "../hooks/useAuth";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { ApiClient } from "../services/apiClient";
import { useBatchJobs } from '../hooks/useBatchJobs';
import { useBenefits } from '../hooks/useBenefits';
import { Benefit, Partner } from '../types/benefit';




// Define the shape of the context including the user and the setter function
interface AppContextType {
  currentUser: any; // Use a more specific type for your user object
  setCurrentUser: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any

  runningAllApplicationsQuery: any; // Use a more specific type for your user object
  flattenedApplications: any; // Use a more specific type for your user object
  flattenedCompanies: any; // Use a more specific type for your user object
  setRunningAllApplicationsQuery: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  gettingCompanies: any;
  getApplication: any;
  fetchAndSetApplication: any;
  currentJobId: any;

  approvingApplication: any; // Use a more specific type for your user object
  setApprovingApplication: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  runningApplicationExportQuery: any; // Use a more specific type for your user object
  runningInvoicingQuery: any; // Use a more specific type for your user object
  companyXeroConfiguration: any; // Use a more specific type for your user object
  setRunningApplicationExportQuery: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  setRunningInvoicingQuery: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any

  signingIn: any; // Use a more specific type for your user object
  setSigningIn: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  selectedApplication: any; // Use a more specific type for your user object
  setSelectedApplication: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  selectedFulfillment: any; // Use a more specific type for yourFulfillmentt
  setSelectedFulfillment: Dispatch<SetStateAction<any>>; // Again, use a more specific type inFulfillmenty
  selectedCompany: any; // Use a more specific type for your user object
  setSelectedCompany: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  // getAllApplicationsData: any;
  setCompanyToManualPayment: any;
  getApplicationsExport: any;
  getMarketingContactsExport: any;
  getAllContactsExport: any;
  getActivationsLogHistory: any;
  requestInProgress: any;
  getSFTPFileForCompany: any;
  getCurrentSalarySacrificeReport: any;
  sendSpecificSalarySacrificeReport: any;
  getPastSalarySacrificeReport: any;

  addCompanyActivityHistory: any;

  resendNewApplicationEmails: any;
  sendRequestToAddPaymentDetails: any;
  resendHRApprovalEmail: any;
  disburseApplication: any;
  cancelApplication: any;
  uploadCSVApplicationsForCompany: any;
  getCompanyDetails: any;
  createContactInXero: any;
  issueInvoiceForCompany: any;
  disburseProductsForApplicationsByCompanyID: any;
  companyDetails: any;
  companyApplications: any;
  companyPendingInvoiceItems: any;
  signOut: any;
  reassignApplication: any;
  updateCompanyDetails: any;
  viewApplication: any;
  getCompanyNamesAndIDs: any;
  companyNamesAndIDs: any;

  signInWithEmailAndPassword: any;
  resendInformationRequiredEmployeeEmail: any;
  signInWithGoogle: any;
  dashboardFilterStartDate: DateTime | null;
  dashboardFilterEndDate: DateTime | null;
  setAllApplicationsFilterStartDate: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  setAllApplicationsFilterEndDate: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  companies: any;
  companiesError: any;
  setCompanies: Dispatch<SetStateAction<any>>;
  setCompaniesError: Dispatch<SetStateAction<any>>;
  companySalarySacrificeRecurringItems: any;

  sendUnregisteredCompanyOnboardingInstructions: Dispatch<SetStateAction<any>>;
  getFulfillments: any;
  fulfillments: any;
  gettingFulfillments: any;
  setApplicationBatchProcessing: (applicationID: string, processInBatch: boolean) => Promise<void>;
  setCompanyBatchProcessing: (companyID: string, processInBatch: boolean) => Promise<void>;
  archiveCompany: (companyID: string) => Promise<void>;
  usePaginatedApplications: (filters?: any) => any;
  usePaginatedCompanies: (filters?: any) => any;

  batchJobs: any[];
  invoicingJobs: any[];
  salarySacrificeJobs: any[];
  loadingJobs: boolean;
  loadingInvoicingJobs: boolean;
  loadingSalSacJobs: boolean;
  runningBatch: boolean;
  runningInvoicing: boolean;
  runningSalSac: boolean;
  resendVoucher: (applicationID: string, disbursementID: string, communicationType: 'email' | 'sms') => Promise<any>;
  resendingVoucher: boolean;
  fetchBatchJobs: () => Promise<void>;
  resendCommunication: (communicationId: string) => Promise<void>;
  fetchInvoicingJobs: () => Promise<void>;
  fetchSalarySacrificeJobs: () => Promise<void>;
  runBatchJob: () => Promise<void>;
  runInvoicingJob: () => Promise<void>;
  runSalarySacrificeJob: () => Promise<void>;

  // Benefits related properties and methods
  benefits: Benefit[] | undefined;
  loadingBenefits: boolean;
  benefitsError: unknown;
  refetchBenefits: () => void;
  partners: Partner[] | undefined;
  loadingPartners: boolean;
  partnersError: unknown;
  refetchPartners: () => void;
  selectedBenefit: Benefit | null;
  setSelectedBenefit: Dispatch<SetStateAction<Benefit | null>>;
  getBenefit: (benefitId: string) => Promise<Benefit>;
  createBenefit: (benefit: Partial<Benefit>) => void;
  updateBenefit: (benefit: Benefit) => void;
  deleteBenefit: (benefitId: string) => void;
  calculatePricing: (benefit: Partial<Benefit>) => Promise<any>;

  analyticsData: AnalyticsResponse | null;
  isLoadingAnalytics: boolean;
  analyticsError: string | null;
  fetchAnalyticsData: (filters?: AnalyticsFilters) => Promise<void>;
  clearAnalyticsData: () => void;
}

const AppContext = createContext<AppContextType>({
  currentUser: null,
  setCurrentUser: () => { },

  gettingCompanies: null,
  getApplication: null,
  currentJobId: null,
  fetchAndSetApplication: null,
  runningAllApplicationsQuery: null,
  flattenedApplications: null,
  flattenedCompanies: null,
  setRunningAllApplicationsQuery: () => { },

  runningApplicationExportQuery: null,
  runningInvoicingQuery: null,
  companyXeroConfiguration: null,
  setRunningApplicationExportQuery: () => { },
  setRunningInvoicingQuery: () => { },

  signingIn: null,
  setSigningIn: () => { },
  selectedApplication: null,
  setSelectedApplication: () => { },
  selectedFulfillment: null,
  setSelectedFulfillment: () => { },
  selectedCompany: null,
  setSelectedCompany: () => { },
  setCompanyToManualPayment: () => { },
  getApplicationsExport: () => { },
  getMarketingContactsExport: () => { },
  getAllContactsExport: () => { },
  getActivationsLogHistory: () => { },
  requestInProgress: () => { },
  getSFTPFileForCompany: () => { },
  getCurrentSalarySacrificeReport: () => { },
  sendSpecificSalarySacrificeReport: () => { },
  getPastSalarySacrificeReport: () => { },

  addCompanyActivityHistory: () => { },
  getCompanyDetails: () => { },
  createContactInXero: () => { },
  issueInvoiceForCompany: () => { },
  disburseProductsForApplicationsByCompanyID: () => { },
  companyDetails: null,
  companyApplications: null,
  companyPendingInvoiceItems: null,
  companies: null,
  companiesError: null,
  setCompanies: () => { },
  setCompaniesError: () => { },
  approvingApplication: null,
  setApprovingApplication: () => { },

  resendNewApplicationEmails: () => { },
  sendRequestToAddPaymentDetails: () => { },
  resendHRApprovalEmail: () => { },
  disburseApplication: () => { },
  cancelApplication: () => { },
  uploadCSVApplicationsForCompany: () => { },
  reassignApplication: () => { },
  updateCompanyDetails: () => { },
  companySalarySacrificeRecurringItems: null,

  sendUnregisteredCompanyOnboardingInstructions: () => { },

  signOut: () => { },
  viewApplication: () => { },
  getCompanyNamesAndIDs: () => { },
  companyNamesAndIDs: () => { },

  signInWithEmailAndPassword: () => { },
  resendInformationRequiredEmployeeEmail: () => { },
  signInWithGoogle: () => { },
  dashboardFilterStartDate: null,
  setAllApplicationsFilterStartDate: () => { }, // Again, use a more specific type instead of any
  dashboardFilterEndDate: null,
  setAllApplicationsFilterEndDate: () => { }, // Again, use a more specific type instead of any
  getFulfillments: () => { }, // Again, use a more specific type instead of any
  fulfillments: null,
  gettingFulfillments: null,
  setApplicationBatchProcessing: async () => { },
  setCompanyBatchProcessing: async () => { },
  archiveCompany: () => Promise.resolve(),
  usePaginatedApplications: () => ({}),
  usePaginatedCompanies: () => ({}),

  batchJobs: [],
  invoicingJobs: [],
  salarySacrificeJobs: [],
  loadingJobs: true,
  loadingInvoicingJobs: true,
  loadingSalSacJobs: true,
  runningBatch: false,
  runningInvoicing: false,
  runningSalSac: false,
  resendVoucher: async () => { throw new Error('Not implemented'); },
  resendingVoucher: false,
  fetchBatchJobs: async () => { },
  resendCommunication: async () => { },
  fetchInvoicingJobs: async () => { },
  fetchSalarySacrificeJobs: async () => { },
  runBatchJob: async () => { },
  runInvoicingJob: async () => { },
  runSalarySacrificeJob: async () => { },

  // Benefits related properties and methods
  benefits: [],
  loadingBenefits: false,
  benefitsError: null,
  refetchBenefits: () => { },
  partners: [],
  loadingPartners: false,
  partnersError: null,
  refetchPartners: () => { },
  selectedBenefit: null,
  setSelectedBenefit: () => { },
  getBenefit: async () => { throw new Error('Not implemented'); },
  createBenefit: async () => { throw new Error('Not implemented'); },
  updateBenefit: async () => { throw new Error('Not implemented'); },
  deleteBenefit: async () => { throw new Error('Not implemented'); },
  calculatePricing: async () => { throw new Error('Not implemented'); },

  analyticsData: null,
  isLoadingAnalytics: false,
  analyticsError: null,
  fetchAnalyticsData: async () => { },
  clearAnalyticsData: () => { },
});

export const useUser = () => useContext(AppContext);

export const UserProvider = ({ children }: any) => {
  const [requestInProgress, setRequestInProgress] = useState(false);

  const auth = useAuth();

  const api = useAPI();

  const fulfillment = useFulfillment()

  const batchJobs = useBatchJobs();
  const benefits = useBenefits();
  const analytics = useAnalytics();
  // Set up the request progress callback once
  useEffect(() => {
    ApiClient.setRequestInProgressCallback((inProgress: boolean) => {
      setRequestInProgress(inProgress);
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...auth,
        ...api,
        ...fulfillment,
        ...batchJobs,
        ...benefits,
        analyticsData: analytics.analyticsData,
        isLoadingAnalytics: analytics.isLoading,
        analyticsError: analytics.error,
        fetchAnalyticsData: analytics.fetchAnalyticsData,
        clearAnalyticsData: analytics.clearAnalyticsData,
        requestInProgress
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
