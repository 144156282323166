import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/AppContext';
import { Benefit, Partner } from '../../types/benefit';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Chip,
    Tooltip,
    Divider,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    CalendarMonth as CalendarIcon,
    AttachMoney as MoneyIcon,
    Info as InfoIcon,
    ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Common styles for Montserrat font
const fontStyles = {
    fontFamily: 'Montserrat, sans-serif',
};

const Benefits: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const context = useUser();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [benefitToDelete, setBenefitToDelete] = useState<Benefit | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Refetch benefits and partners when the component mounts
        if (context) {
            context.refetchBenefits();
        }
    }, []);

    if (!context) {
        return <div style={fontStyles}>Error: AppContext not available</div>;
    }

    const {
        benefits,
        loadingBenefits,
        benefitsError,
        refetchBenefits,
        partners,
        loadingPartners,
        partnersError,
        deleteBenefit,
    } = context;

    const handleCreateBenefit = () => {
        navigate('/benefits/create');
    };

    const handleEditBenefit = (benefit: Benefit) => {
        navigate(`/benefits/edit/${benefit.id}`);
    };

    const handleDeleteClick = (benefit: Benefit) => {
        setBenefitToDelete(benefit);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (benefitToDelete) {
            try {
                await deleteBenefit(benefitToDelete.id);
                toast.success('Benefit deleted successfully');
            } catch (error) {
                console.error('Error deleting benefit:', error);
                toast.error('Failed to delete benefit');
            } finally {
                setDeleteDialogOpen(false);
                setBenefitToDelete(null);
            }
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setBenefitToDelete(null);
    };

    const getPartnerName = (partnerId: string): string => {
        const partner = partners?.find((p: Partner) => p.id === partnerId);
        return partner ? partner.display.name : partnerId;
    };

    const handleDuplicateBenefit = (benefit: Benefit, event: React.MouseEvent) => {
        // Stop propagation to prevent navigating to edit page when clicking duplicate
        event.stopPropagation();

        // Create a clone of the benefit without the ID
        const { id, ...benefitWithoutId } = benefit;

        // Store the cloned benefit in localStorage to pass to the create page
        localStorage.setItem('duplicatedBenefit', JSON.stringify({
            ...benefitWithoutId,
            name: `${benefit.name} (Copy)` // Append "(Copy)" to the name
        }));

        // Navigate to create page
        navigate('/benefits/create');
    };

    if (loadingBenefits || loadingPartners) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (benefitsError || partnersError) {
        return (
            <Container sx={fontStyles}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Error Loading Benefits
                </Typography>
                <Typography color="error">
                    {benefitsError ? 'Failed to load benefits.' : 'Failed to load partners.'}
                </Typography>
                <Button variant="contained" color="primary" onClick={() => refetchBenefits()}>
                    Retry
                </Button>
            </Container>
        );
    }


    // Format currency values
    const formatCurrency = (value: string | undefined) => {
        if (!value) return '£0';
        // If value already has a currency symbol, return as is
        if (value.includes('£')) return value;
        // Otherwise add the pound symbol
        return `£${value}`;
    };

    return (
        <Container maxWidth="xl" sx={{ py: 4, ...fontStyles }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Typography variant="h4" component="h1" fontWeight="bold" sx={fontStyles}
                >
                    Benefits Management
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleCreateBenefit}
                    size={isMobile ? "small" : "medium"}
                    sx={fontStyles}
                >
                    Create Benefit
                </Button>
            </Box>

            {benefits && benefits.length > 0 ? (
                <Grid container spacing={3}>
                    {benefits.map((benefit: Benefit) => (
                        <Grid item xs={12} sm={6} md={4} key={benefit.id}>
                            <Card
                                onClick={() => handleEditBenefit(benefit)}
                                elevation={1}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    position: 'relative',
                                    overflow: 'visible',
                                    ...fontStyles,
                                    '&:hover': {
                                        transform: 'translateY(-1px)',
                                        boxShadow: 2,
                                    }
                                }}
                            >
                                {/* Status chip - keep this absolutely positioned */}


                                <CardContent sx={{ flexGrow: 1, px: 2, pt: 2, pb: 2 }}>
                                    {/* Header row with icon, name and partner */}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 2
                                    }}>
                                        {/* Icon */}
                                        <Box
                                            sx={{
                                                width: 50,
                                                height: 50,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                overflow: 'hidden',
                                                flexShrink: 0,
                                                mr: 1
                                            }}
                                        >
                                            {benefit.imageURL ? (
                                                <img
                                                    src={benefit.imageURL}
                                                    alt={benefit.name}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: "100%"
                                                    }}
                                                />
                                            ) : (
                                                <MoneyIcon color="primary" sx={{ fontSize: 24 }} />
                                            )}
                                        </Box>

                                        {/* Name and partner - middle box that stretches */}
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            overflow: 'hidden',
                                            flexGrow: 1,
                                            mx: 1
                                        }}>
                                            <Typography variant="h6" component="h2" noWrap fontWeight="bold" sx={{
                                                ...fontStyles,
                                                mb: 0.5
                                            }}>
                                                {benefit.name}
                                            </Typography>

                                            <Typography variant="body2" color="text.secondary" noWrap sx={{
                                                ...fontStyles,
                                                mt: 0
                                            }}>
                                                <strong>Partner:</strong> {getPartnerName(benefit.partnerID)}
                                            </Typography>
                                        </Box>

                                        {/* Status chip - right aligned */}
                                        <Box sx={{ flexShrink: 0 }}>
                                            <Chip
                                                label={benefit.active ? 'Active' : 'Inactive'}
                                                color={benefit.active ? 'success' : 'default'}
                                                size="medium"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                    ...fontStyles
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Grid container spacing={1} sx={{ mt: 1 }}>
                                        <Grid item xs={6}>
                                            <Box display="flex" alignItems="center">
                                                <MoneyIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
                                                <Typography variant="body2" noWrap sx={fontStyles}>
                                                    <strong>Original:</strong> {formatCurrency(benefit.originalCost)}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box display="flex" alignItems="center">
                                                <MoneyIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
                                                <Typography variant="body2" noWrap sx={fontStyles}>
                                                    <strong>Annual:</strong> {formatCurrency(benefit.originalAnnualCost)}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <CalendarIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
                                                <Typography variant="body2" sx={fontStyles}>
                                                    <strong>Recurrence:</strong> {benefit.renewalConfiguration?.recurrence || 'N/A'}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {/* {benefit.description && (
                                        <Tooltip title={benefit.description}>
                                            <IconButton size="small" sx={{ mt: 1, p: 0.5 }}>
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )} */}
                                </CardContent>

                                {/* Add CardActions with duplicate and edit buttons */}
                                <CardActions sx={{ px: 2, pt: 0, pb: 2, justifyContent: 'flex-end' }}>
                                    <Tooltip title="Duplicate Benefit">
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleDuplicateBenefit(benefit, e)}
                                            sx={{ mr: 1 }}
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        py: 8,
                        textAlign: 'center',
                        backgroundColor: 'rgba(0,0,0,0.02)',
                        borderRadius: 2,
                        ...fontStyles
                    }}
                >
                    <Typography variant="h6" color="text.secondary" gutterBottom sx={fontStyles}>
                        No benefits found
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3, ...fontStyles }}>
                        Get started by creating your first benefit
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleCreateBenefit}
                        sx={fontStyles}
                    >
                        Create Benefit
                    </Button>
                </Box>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                PaperProps={{
                    sx: fontStyles
                }}
            >
                <DialogTitle sx={fontStyles}>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={fontStyles}>
                        Are you sure you want to delete the benefit "{benefitToDelete?.name}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary" sx={fontStyles}>
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error" sx={fontStyles}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Benefits; 