// Login.tsx
import React, { useState, useEffect } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";

import { toast } from "react-toastify";
import { useUser } from "../../context/AppContext";

const Login = () => {
  const { signInWithGoogle, signInWithEmailAndPassword, signingIn } = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + "Admin | Login";
  }, []);
  // Clear error when inputs change
  useEffect(() => {
    if (error) setError("");
  }, [email, password]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    try {
      await signInWithEmailAndPassword(email, password);
    } catch (err: any) {
      // Error is already handled in useAuth, this is just a fallback
      setError("Authentication failed. Please try again.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (err: any) {
      // Error is already handled in useAuth, this is just a fallback
      setError("Google authentication failed. Please try again.");
    }
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex justify-around w-full items-center bg-secondary flex-col md:flex-row h-full p-4">
        <form
          className="bg-white shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4 w-full max-w-[640px] flex flex-col"
          onSubmit={handleSubmit}
        >
          <div>
            <img
              src={require("../../assets/logoLarge.png")}
              className="max-w-[300px] mx-auto rounded-full bg-white p-4"
              alt="Login Visual"
            />
          </div>

          <div className="w-full flex items-center justify-center mb-6">
            <Button
              onClick={handleGoogleSignIn}
              loading={signingIn}
              type="button"
              className="flex items-center gap-2"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                className="w-5 h-5"
              />
              Sign in with Google
            </Button>
          </div>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with email</span>
            </div>
          </div>

          {error && (
            <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          <TextInput
            id="email"
            type="email"
            placeholder="Email"
            label="Email"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <TextInput
            id="password"
            type="password"
            placeholder="Password"
            label="Password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <div className="w-full flex items-center justify-center ml-auto">
            <Button
              loading={signingIn}
              type="submit"
            >
              Sign In with Email
            </Button>
          </div>
          <Spacer />
        </form>
      </div>
    </div>
  );
};

export default Login;
