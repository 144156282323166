import { useState, useCallback } from 'react';
import { analyticsAPI } from '../services/apiClient';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { isAuthenticated } from '../utils/auth';

export interface AnalyticsFilters {
    startDate?: string | null;
    endDate?: string | null;
    timeFrame?: 'day' | 'week' | 'month';
}

export interface AnalyticsResponse {
    companiesRegistered: Record<string, number>;
    companiesActivated: Record<string, number>;
    applicationsReceived: Record<string, number>;
    applicationsActivated: Record<string, number>;
    activeCompanies: number;
    totalCompanies: number;
    totalApplications: number;
    activeApplications: number;
    popularBenefits: Record<string, number>;
    firstFulfillments: Record<string, number>;
    totalFulfillments: Record<string, number>;
    currentProgress: {
        awaitingEmployerSignature: number;
        awaitingActivation: number;
        companiesActivatedThisWeek: number;
        newCompaniesThisWeek: number;
    };
    conversionRates: {
        registrationToActive: string;
        applicationToActive: string;
    };
    timeToActivation: {
        companies: string;
        applications: string;
    };
    applicationStatusCounts: Record<string, Record<string, number>>;
}

export const useAnalytics = () => {
    const [analyticsData, setAnalyticsData] = useState<AnalyticsResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchAnalyticsData = useCallback(async (filters: AnalyticsFilters = {}) => {
        if (!isAuthenticated()) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            // Default to last 3 months if no dates provided
            const defaultEndDate = DateTime.now();
            const defaultStartDate = defaultEndDate.minus({ months: 3 });

            const requestData = {
                startDate: filters.startDate || defaultStartDate.toISO(),
                endDate: filters.endDate || defaultEndDate.toISO(),
                timeFrame: filters.timeFrame || 'week'
            };

            const response = await analyticsAPI.post('/analytics-data', requestData);

            if (response.success) {
                setAnalyticsData(response.data);
            } else {
                throw new Error(response.message || 'Failed to fetch analytics data');
            }
        } catch (err: any) {
            const errorMessage = err.message || 'An error occurred while fetching analytics data';
            setError(errorMessage);
            toast.error(errorMessage);
            console.error('Analytics fetch error:', err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const clearAnalyticsData = useCallback(() => {
        setAnalyticsData(null);
    }, []);

    return {
        analyticsData,
        isLoading,
        error,
        fetchAnalyticsData,
        clearAnalyticsData,
    };
};