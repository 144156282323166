import { Table, TableHead, TableCell, TableBody, TableRow, Box, Paper, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useUser } from "../../context/AppContext";

const ApplicationHistory = ({ historyList, heading }: any) => {
  const [isTableExpanded, setIsTableExpanded] = useState(true);
  const { addCompanyActivityHistory } = useUser()
  const [data, setData] = useState<any[]>([]);
  const [noteInput, setNoteInput] = useState("")
  const toggleTableVisibility = () => {
    setIsTableExpanded(!isTableExpanded);
  };
  useEffect(() => {
    console.log("History List", historyList)
    if (historyList && historyList.length > 0) {
      const reversedLog = [...historyList].reverse(); // Create a copy and reverse it
      setData(reversedLog);
    }
  }, [historyList]); // Add application as a dependency to re-run effect when it changes

  function getIconForHistoryItemType(type: any) {
    console.log("Type", type)
    switch (type) {
      case "Application Created":
        return require("../../assets/created.png");
      case "Application Approved":
        return require("../../assets/mark.png");
      case "Created":
        return require("../../assets/created.png");
      case "Note":
        return require("../../assets/note.png");
      case "Request Sent":
        return require("../../assets/send.png");
      case "Invoice Created":
        return require("../../assets/invoice.png");
      case "Invoice Sent":
        return require("../../assets/sent.png");
      case "Enrichment Completed":
        return require("../../assets/formCompleted.png");
      case "Payment Mandate - FULFILLED":
        return require("../../assets/debitOrderFulfilled.png");
      case "Payment Mandate Fulfilled":
        return require("../../assets/debitOrderFulfilled.png");
      case "Payment Mandate Pending":
        return require("../../assets/pending.png");
      case "Payment Mandate - Debit Mandate PENDING":
        return require("../../assets/pending.png");
      case "Activated":
        return require("../../assets/mark.png");
      case "Product Change Requested":
        return require("../../assets/product-change.png");
      case "Product Change Completed":
        return require("../../assets/product-change-complete.png");
      case "Application Cancelled":
        return require("../../assets/cancel.png");
      case "Document Signed":
        return require("../../assets/sign.png");
      case "Voucher Assigned":
        return require("../../assets/voucher.png");
      case "Activation Requested":
        return require("../../assets/sent.png");
      case "Salary Sacrifice Report Generated":
        return require("../../assets/csv.png");
      case "Salary Sacrifice Report Sent":
        return require("../../assets/csvsent.png");
      case "Payment Details - Manual Payment Requested":
        return require("../../assets/cash.png");
      case "Xero Update":
        return require("../../assets/xero-logo.png");
      case "Xero Contact Created":
        return require("../../assets/xero-logo.png");
      case "Contacts Updated":
        return require("../../assets/info.png");
      case "Company Updated":
        return require("../../assets/info.png");
      case "Application Paused":
        return require("../../assets/pause.png");
      case "Application Resumed":
        return require("../../assets/resume.png");
      default:
        return require("../../assets/puzzle-pieces.png");
    }
  }

  return (
    <div className="w-full relative flex justify-center flex-col py-5">
      <div className="flex flex-col flex-wrap py-5 w-full border border-lightgrey px-5 rounded-xl ">
        <div className="flex justify-between items-center">
          <h3 className="underline">{heading ? heading : "History Log"}</h3>

          {/* <button onClick={toggleTableVisibility} className="px-4 py-2 rounded-lg bg-blue-100 text-darkgrey">
            {isTableExpanded ? (
              <div className="flex flex-row gap-2">
                <p>Hide</p>
                <img src={require("../../assets/eye-password.png")} className="w-8" />
              </div>
            ) : (
              <div className="flex flex-row gap-2">
                <p>Show</p>
                <img src={require("../../assets/visual.png")} className="w-6 m-1" />
              </div>
            )}
          </button> */}
        </div>
        <div className="pb-3 flex flex-row gap-2">
          <input type="text" className="border-b w-full" placeholder="Type here to add a note" value={noteInput} onChange={(e) => { setNoteInput(e.target.value) }} />
          <button

            onClick={() => {
              addCompanyActivityHistory({ action: noteInput, type: 'Note', typeCode: 'NOTECREATED' })
              setNoteInput("")
            }}
            disabled={false}
          >
            <img src={require("../../assets/add.png")} className="w-6 h-6" />


          </button>
        </div>
        <AnimatePresence>
          {isTableExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: "auto",
                transition: {
                  opacity: {
                    duration: 0.5,
                    delay: 0.2,
                  },
                },
              }}
              exit={{
                opacity: 0,
                height: 0,
                transition: {
                  height: {
                    duration: 0.3,
                    delay: 0.2,
                  },
                },
              }}
              transition={{ duration: 0.5 }}
            >
              {/* Account History table goes here */}
              <Paper
                sx={{
                  borderRadius: "16px",
                  overflow: "hidden",
                  padding: "8px",
                  bgcolor: "background.paper",
                  boxShadow: 3, // Adding some shadow to enhance the appearance
                }}
              >
                <Table sx={{ border: "none" }}>

                  <TableBody sx={{ border: "none" }}>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <div
                          className="w-full flex flex-row items-center justify-start gap-4 p-2 border-b"
                          key={index}
                        >
                          <img src={getIconForHistoryItemType(item.type)} className="w-8 h-8 " />

                          <div>
                            <p className="font-montserrat text-left text-base">{item.type} | {item.action}</p>

                            <p className="font-montserrat text-left text-sm"></p>
                            <p className="font-montserrat text-left text-xs text-darkgrey/70">
                              {DateTime.fromISO(item.timestamp).toFormat("dd/MM/yy")} | {DateTime.fromISO(item.timestamp).toFormat("HH:mm")}{" "}{item.userDetails && "by " + item.userDetails.firstName + " " + item.userDetails.surname}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <TableRow sx={{ border: "none" }}>
                        <TableCell colSpan={3} sx={{ border: "none" }}>
                          <p className="text-left mt-3 font-montserrat">No logs exist.</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ApplicationHistory;
