import { useState, useCallback } from 'react';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Benefit, Partner } from '../types/benefit';
import { ApiClient } from '../services/apiClient';

// Use your ApiClient instead of creating a new axios instance
const benefitsApi = new ApiClient({
    baseURL: process.env.REACT_APP_FUNCTIONS_URL + "/api/benefits",
});

// Define the query keys
const BENEFITS_QUERY_KEY = 'benefits';
const PARTNERS_QUERY_KEY = 'partners';

export const useBenefits = () => {
    const [selectedBenefit, setSelectedBenefit] = useState<Benefit | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();

    // Get all benefits
    const { data: benefits, isLoading: loadingBenefits, error: benefitsError, refetch: refetchBenefits } = useQuery({
        queryKey: [BENEFITS_QUERY_KEY],
        queryFn: async () => {
            // No need to manually check for token or add headers - ApiClient does this
            const response = await benefitsApi.get('/list');
            return response.data as Benefit[];
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        enabled: !!localStorage.getItem("sessionToken"), // Only run when session token exists
    });

    // Get all partners
    const { data: partners, isLoading: loadingPartners, error: partnersError, refetch: refetchPartners } = useQuery({
        queryKey: [PARTNERS_QUERY_KEY],
        queryFn: async () => {
            // No need to manually check for token or add headers - ApiClient does this
            const response = await benefitsApi.get('/partners');
            return response.data as Partner[];
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        enabled: !!localStorage.getItem("sessionToken"), // Only run when session token exists
    });

    // Get a single benefit
    const getBenefit = useCallback(async (benefitId: string) => {
        setIsLoading(true);
        try {
            const response = await benefitsApi.get(`/${benefitId}`);
            setSelectedBenefit(response.data);
            return response.data as Benefit;
        } catch (error) {
            console.error('Error fetching benefit:', error);
            toast.error('Failed to fetch benefit details');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Create a new benefit
    const createBenefitMutation = useMutation({
        mutationFn: async (benefit: Partial<Benefit>) => {
            console.log('Creating new benefit');
            try {
                const response = await benefitsApi.post('/create', benefit);
                return response.data;
            } catch (error) {
                console.error('Error in createBenefitMutation:', error);
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [BENEFITS_QUERY_KEY] });
        },
        onError: (error) => {
            console.error('Error creating benefit:', error);
        },
    });

    // Update an existing benefit
    const updateBenefitMutation = useMutation({
        mutationFn: async (benefit: Benefit) => {
            console.log('Updating benefit:', benefit.id);
            try {
                const response = await benefitsApi.put(`/update/${benefit.id}`, benefit);
                return response.data;
            } catch (error) {
                console.error('Error in updateBenefitMutation:', error);
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [BENEFITS_QUERY_KEY] });
        },
        onError: (error) => {
            console.error('Error updating benefit:', error);
        },
    });

    // Delete a benefit
    const deleteBenefitMutation = useMutation({
        mutationFn: async (benefitId: string) => {
            console.log('Deleting benefit:', benefitId);
            try {
                const response = await benefitsApi.delete(`/delete/${benefitId}`);
                return response;
            } catch (error) {
                console.error('Error in deleteBenefitMutation:', error);
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [BENEFITS_QUERY_KEY] });
        },
        onError: (error) => {
            console.error('Error deleting benefit:', error);
        },
    });

    // Calculate pricing for a benefit
    const calculatePricing = useCallback(async (benefit: Partial<Benefit>) => {
        try {
            // Check if required fields are present
            if (!benefit.originalCost || !benefit.originalAnnualCost || !benefit.renewalConfiguration?.recurrence) {
                toast.error('Original cost, annual cost, and recurrence are required to calculate pricing');
                return null;
            }

            const response = await benefitsApi.post('/get-pricing', benefit);

            console.log('API Response:', response);

            // Make sure we're returning the data property from the response
            if (response && response.success && response.data) {
                return response.data;
            } else {
                console.error('Invalid response format:', response);
                toast.error('Invalid response format from server');
                return null;
            }
        } catch (error) {
            console.error('Error calculating pricing:', error);
            toast.error('Failed to calculate pricing');
            return null;
        }
    }, []);

    return {
        benefits,
        loadingBenefits,
        benefitsError,
        refetchBenefits,
        partners,
        loadingPartners,
        partnersError,
        refetchPartners,
        selectedBenefit,
        setSelectedBenefit,
        isLoading,
        getBenefit,
        createBenefit: createBenefitMutation.mutate,
        updateBenefit: updateBenefitMutation.mutate,
        deleteBenefit: deleteBenefitMutation.mutate,
        calculatePricing,
    };
}; 