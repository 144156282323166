import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/AppContext";
import { useEffect } from "react";

const pages = [
  { name: "Applications", link: "/applications" },
  { name: "Companies", link: "/companies" },
  { name: "Fulfillment", link: "/fulfillments" },
  { name: "Jobs", link: "/batch-jobs" },
  { name: "Benefits", link: "/benefits" },
  { name: "Platforms", link: "/platforms" },

  { name: "Analytics", link: "/analytics" },
];

const settings = ["Clear Cache", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { currentUser, signOut } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("Navbar - Current user updated:", currentUser);
  }, [currentUser]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (setting: string) => {
    if (setting === "Logout") {
      signOut();
    } else if (setting === "Clear Cache") {
      localStorage.removeItem("cachedApplications");
      window.location.reload();
    }
    handleCloseUserMenu();
  };

  const handleNavigation = (link: string) => {
    navigate(link);
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        color: "#fff",
        backgroundColor: process.env.REACT_APP_ENV == "DEVELOPMENT" ? '#AAFFFF' : "#86eb85",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <img
            src={require("../assets/logoSmaller.png")}
            className="h-16 cursor-pointer"
            onClick={() => navigate('/')}
            alt="Logo"
          />

          {/* Mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => handleNavigation(page.link)}
                  selected={location.pathname === page.link}
                  sx={{
                    color: "#333",
                    fontWeight: 600,
                    fontFamily: "montserrat",
                    display: "block",
                    backgroundColor: location.pathname === page.link ? 'rgba(0,0,0,0.1)' : 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Desktop menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex", marginLeft: "16px" }, gap: "16px" }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleNavigation(page.link)}
                sx={{
                  my: 2,
                  color: "#333",
                  fontWeight: 600,
                  fontFamily: "montserrat",
                  display: "block",
                  backgroundColor: location.pathname === page.link ? 'rgba(0,0,0,0.1)' : 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.1)'
                  }
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {/* User section */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {currentUser?.firstName && (
              <Typography
                variant="h6"
                sx={{
                  display: { xs: "none", md: "flex" },
                  fontFamily: "montserrat",
                  fontWeight: 700,
                  color: "inherit",
                }}
              >
                {`${currentUser.firstName} ${currentUser.surname}`}
              </Typography>
            )}

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={currentUser?.firstName || "User"}
                    src={currentUser?.profilePicture ?? require("../assets/user.png")}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleMenuItemClick(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
