import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { dashboardApi } from '../services/apiClient';
import React, { useEffect, useState } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { auth, firestore } from '../firebaseConfig';
import { isAuthenticated } from '../utils/auth';

export interface CompanyFilters {
    archived?: boolean;
}

export interface PaginatedResponse {
    data: any[];
    nextCursor: string | null;
    hasNextPage: boolean;
}

export const useCompanies = () => {
    const queryClient = useQueryClient();
    const [selectedCompany, setSelectedCompany] = useState<any>();
    const [companyDetails, setCompanyDetails] = useState<any>();
    const [companyNamesAndIDs, setCompanyNamesAndIDs] = useState<any>();

    const [companyApplications, setCompanyApplications] = useState<any>();
    const [companyPendingInvoiceItems, setCompanyPendingInvoiceItems] = useState<any>();
    const [companySalarySacrificeRecurringItems, setCompanySalarySacrificeRecurringItems] = useState<any>();
    const [companyXeroConfiguration, setCompanyXeroConfiguration] = useState<any>();
    const [gettingCompanyDetails, setGettingCompanyDetails] = useState<boolean>(false);
    const [gettingCompanies, setGettingCompanies] = useState<boolean>(false);
    const [allApplicationsError, setAllApplicationsError] = useState<boolean>(false);

    /**
     * 
     * THIS USEEFFECT IS THE SNAPSHOT LISTENER FOR A SELECTED COMPANY
     */

    useEffect(() => {
        let unsubscribe: (() => void) | undefined;

        const setupListener = async () => {
            if (selectedCompany?.companyID && isAuthenticated()) {
                try {
                    const user = auth.currentUser;
                    if (user) {
                        const token = await user.getIdToken(true);
                    }

                    unsubscribe = onSnapshot(
                        doc(firestore, "companies", selectedCompany.companyID),
                        {
                            next: (doc) => {
                                if (doc.exists()) {
                                    const updatedCompany = { ...doc.data(), companyID: selectedCompany.companyID };
                                    setSelectedCompany(updatedCompany);

                                    // Update all paginated company queries
                                    queryClient.setQueriesData(
                                        { queryKey: ['companies', 'paginated'] },
                                        (oldData: any) => {
                                            if (!oldData) return oldData;

                                            return {
                                                ...oldData,
                                                pages: oldData.pages.map((page: any) => ({
                                                    ...page,
                                                    data: page.data.map((company: any) =>
                                                        company.companyID === selectedCompany.companyID ? updatedCompany : company
                                                    )
                                                }))
                                            };
                                        }
                                    );
                                }
                            },
                            error: (error) => {
                                console.error("Error listening to selected company:", {
                                    error,
                                    companyId: selectedCompany.companyID,
                                    currentUser: auth.currentUser?.uid,
                                    isAuthenticated: !!auth.currentUser,
                                    errorCode: error.code,
                                    errorDetails: error.message
                                });
                            }
                        }
                    );
                } catch (error) {
                    console.error("Error setting up company listener:", error);
                }
            }
        };

        setupListener();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [selectedCompany?.companyID, queryClient]);

    /**
     * 
     * THIS IS THE FETCH PAGINATED COMPANIES FUNCTION
     * 
     */
    const fetchPaginatedCompanies = async ({
        pageParam = null,
        filters = {} as CompanyFilters
    }: {
        pageParam?: string | null;
        filters?: CompanyFilters
    }): Promise<PaginatedResponse> => {
        try {
            const response = await dashboardApi.post('/paginated-data', {
                cursor: pageParam,
                pageSize: 500,
                dataType: 'companies',
                filters: null,
                tableId: 'CompaniesTable'
            });

            return {
                data: response.companies.data,
                nextCursor: response.companies.nextCursor,
                hasNextPage: response.companies.hasNextPage
            };
        } catch (error) {
            console.error('Error fetching paginated companies:', error);
            throw error;
        }
    };

    const defaultFilters = React.useMemo(() => ({ archived: false }), []);

    const companiesQuery = useInfiniteQuery({
        queryKey: ['companies', 'paginated', defaultFilters],
        queryFn: ({ pageParam }) => fetchPaginatedCompanies({
            pageParam: pageParam as string | null,
            filters: defaultFilters
        }),
        getNextPageParam: (lastPage: PaginatedResponse) => lastPage.nextCursor,
        initialPageParam: null as string | null,
        enabled: isAuthenticated(),
        staleTime: 15 * 60 * 1000,
        gcTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const flattenedCompanies = React.useMemo(() => {
        if (!companiesQuery.data?.pages) return [];
        return companiesQuery.data.pages.flatMap(page => page.data || []);
    }, [companiesQuery.data]);

    const usePaginatedCompanies = (filters: CompanyFilters = {}) => {
        return useInfiniteQuery({
            queryKey: ['companies', 'paginated', filters],
            queryFn: ({ pageParam }) => fetchPaginatedCompanies({
                pageParam: pageParam as string | null,
                filters
            }),
            getNextPageParam: (lastPage: PaginatedResponse) => lastPage.nextCursor,
            initialPageParam: null as string | null,
            enabled: isAuthenticated(),
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        });
    };

    /**
     * The below are helper functions for the useCompanies hook
     */

    const getCompanyDetails = async (companyID: any, filters?: any) => {
        try {
            if (!isAuthenticated()) {
                throw new Error('Not authenticated');
            }

            // Call your API endpoint after successful sign-in
            setCompanyDetails(null);
            setCompanyApplications(null);

            setGettingCompanyDetails(true);
            console.log("THESE ARE THE FILTERS:", filters);
            const response = await dashboardApi.post('/company-details', {
                companyID: companyID,
                filters: { dates: filters },
            });
            setCompanyDetailsFromResponse(response);
            setGettingCompanies(false);
        } catch (error) {
            setAllApplicationsError(true);
            console.error('Error getting company details:', error);
        } finally {
            setGettingCompanyDetails(false);
        }
    };


    const getCompanyNamesAndIDs = async () => {
        try {
            if (!isAuthenticated()) {
                throw new Error('Not authenticated');
            }

            setGettingCompanyDetails(true);
            const response = await dashboardApi.post('/get-company-names-and-ids');
            setCompanyNamesAndIDs(response.list);
        } catch (error) {
            setAllApplicationsError(true);
            console.error('Error getting company details:', error);
        } finally {
            setGettingCompanyDetails(false);
        }
    };

    const setCompanyDetailsFromResponse = (response: any) => {
        console.log(response)
        setCompanyDetails(response.data);
        !selectedCompany && setSelectedCompany(response.data.company);
        response.data.applications && setCompanyApplications(response.data.applications);
        response.data.pendingInvoiceItems && setCompanyPendingInvoiceItems(response.data.pendingInvoiceItems);
        response.data.salarySacrificeRecurringItems && setCompanySalarySacrificeRecurringItems(response.data.salarySacrificeRecurringItems);
        response.data.xeroConfiguration && setCompanyXeroConfiguration(response.data.xeroConfiguration);
    };
    return {
        companiesQuery,
        flattenedCompanies,
        usePaginatedCompanies,
        selectedCompany,
        setSelectedCompany,
        getCompanyDetails,
        setCompanyDetailsFromResponse,
        companyDetails,
        companyApplications,
        companyPendingInvoiceItems,
        companySalarySacrificeRecurringItems,
        companyXeroConfiguration,
        gettingCompanyDetails,
        gettingCompanies,
        allApplicationsError,
        getCompanyNamesAndIDs,
        companyNamesAndIDs
    };
}; 