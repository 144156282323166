import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { toast } from 'react-toastify';
import DynamicTable from '../DynamicTable/DynamicTable';
import { useUser } from '../../context/AppContext';

interface CommunicationsTableProps {
    companyId?: string;
    applicationId?: string;
}

const CommunicationsTable: React.FC<CommunicationsTableProps> = ({ companyId, applicationId }) => {
    const [communications, setCommunications] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const { resendCommunication } = useUser();

    const fetchCommunications = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_FUNCTIONS_URL}/api/communications/list`,
                {
                    params: {
                        companyId,
                        applicationId
                    },
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("sessionToken")
                    }
                }
            );

            if (response.data.success) {
                setCommunications(response.data.communications);
            }
        } catch (error) {
            console.error("Error fetching communications:", error);
            toast.error("Failed to fetch communications");
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCommunications();
    }, [companyId, applicationId]);

    const generateStatusHistoryContent = (communication: any) => {
        return (
            <div className=" pl-4">
                <table className="min-w-full">
                    <thead>
                        <tr className="border-b bg-white">
                            <th className="text-left p-2 font-medium text-sm text-gray-600">Status</th>
                            <th className="text-left p-2 font-medium text-sm text-gray-600">Timestamp</th>
                            <th className="text-left p-2 font-medium text-sm text-gray-600">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {communication.statusHistory.map((history: any, index: number) => (
                            <tr key={index} className="border-b hover:bg-gray-50">
                                <td className="p-2 text-sm">
                                    <span className={`px-2 py-1 rounded-full ${history.status === 'delivered' ? 'bg-green-100 text-green-800' :
                                        history.status === 'failed' ? 'bg-red/50 text-red-800' :
                                            history.status === 'sent' ? 'bg-blue-100 text-blue-800' :
                                                'bg-gray-100 text-gray-800'
                                        }`}>
                                        {history.status}
                                    </span>
                                </td>
                                <td className="p-2 text-sm">
                                    {DateTime.fromISO(history.timestamp).toFormat("yyyy-MM-dd HH:mm:ss")}
                                </td>
                                <td className="p-2 text-sm font-mono text-gray-600">
                                    {Object.keys(history.metadata).length > 0
                                        ? Object.entries(history.metadata).map(([key, value]) => (
                                            <div key={key}>
                                                <span className="font-semibold">{key}:</span> {String(value)}
                                            </div>
                                        ))
                                        : '-'
                                    }
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="w-full">
            <DynamicTable
                loading={loading}
                showSearchBox={false}
                tableId="CommunicationsTable"
                cols={[
                    "AppID",
                    "Type",
                    "Recipient",
                    "Subject",
                    "Status",
                    "Time sent",
                    "Actions"
                ]}
                filterableColumns={["Type", "Recipient", "Status"]}
                showExport={true}
                rows={communications.map((comm) => [
                    comm.applicationId ?? "None",
                    comm.type.toUpperCase(),
                    comm.recipient,
                    comm.subject || comm.content.substring(0, 50) + "...",
                    comm.status,
                    DateTime.fromISO(comm.createdAt).toFormat("dd/MM/yy HH:mm"),
                    <button onClick={() => resendCommunication(comm.id)}>Resend</button>
                ])}
                rowExpansionComponents={communications.map((comm) => generateStatusHistoryContent(comm)
                )}
                rowClasses={communications.map((comm) => {
                    switch (comm.status) {
                        case 'delivered':
                            return 'bg-green-100';
                        case 'failed':
                            return 'bg-red-100';
                        case 'sent':
                            return 'bg-blue-100';
                        default:
                            return '';
                    }
                })}
                idIndexInColumns={1} // Using recipient as ID column
                showID={true} onRowClicked={() => { }}
            />
        </div>
    );
};

export default CommunicationsTable; 