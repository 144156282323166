// src/hooks/useFulfillment.js

import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { fulfillmentApi } from "../services/apiClient";
import { isAuthenticated } from "../utils/auth";

export const useFulfillment = () => {
    const [fulfillments, setFulfillments] = useState<any[]>();
    const [gettingFulfillments, setGettingFulfillments] = useState<boolean>(false);
    const [selectedFulfillment, setSelectedFulfillment] = useState<any>();
    const [resendingVoucher, setResendingVoucher] = useState<boolean>(false);

    useEffect(() => {
        // Check if user is logged in before fetching fulfillments
        if (!fulfillments && isAuthenticated()) {
            getFulfillments(null, null);
        }
    }, [fulfillments]);

    const getFulfillments = async (companyID: string | null, date: string | null) => {
        // Check if user is logged in before making the API call
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping fulfillment fetch");
            return;
        }

        try {
            setGettingFulfillments(true);

            const response = await fulfillmentApi.post('/all', {
                date: date ?? DateTime.now().toISO(),
                companyID: companyID ?? null,
            });

            setFulfillments(response.data);
        } catch (error) {
            console.error("Error getting fulfillments:", error);
            toast.error("Failed to load fulfillments");
        } finally {
            setGettingFulfillments(false);
        }
    };

    const setApplicationBatchProcessing = async (applicationID: string, processInBatch: boolean) => {
        // Check if user is logged in before making the API call
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping batch processing update");
            throw new Error('Not authenticated');
        }

        try {
            const response = await fulfillmentApi.post('/set-application-batch-processing', {
                applicationID,
                processInBatch
            });

            if (response.success) {
                return response;
            }
            throw new Error('Failed to update batch processing');
        } catch (error) {
            console.error('Error setting application batch processing:', error);
            toast.error('Failed to update batch processing status');
            throw error;
        }
    };

    const setCompanyBatchProcessing = async (companyID: string, processInBatch: boolean) => {
        // Check if user is logged in before making the API call
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping batch processing update");
            throw new Error('Not authenticated');
        }

        try {
            const response = await fulfillmentApi.post('/set-company-batch-processing', {
                companyID,
                processInBatch
            });

            if (response.success) {
                return response;
            }
            throw new Error('Failed to update batch processing');
        } catch (error) {
            console.error('Error setting company batch processing:', error);
            toast.error('Failed to update batch processing status');
            throw error;
        }
    };

    const resendVoucher = async (applicationID: string, disbursementID: string, communicationType: 'email' | 'sms') => {
        // Check if user is logged in before making the API call
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping voucher resend");
            throw new Error('Not authenticated');
        }

        try {
            setResendingVoucher(true);

            const response = await fulfillmentApi.post('/resend-voucher', {
                applicationID,
                disbursementID,
                communicationType
            });

            if (response.success) {
                toast.success(`${communicationType.toUpperCase()} resent successfully`);
                return response;
            }
            throw new Error(response.message || 'Failed to resend voucher');
        } catch (error: any) {
            console.error('Error resending voucher:', error);
            toast.error(`Failed to resend ${communicationType}: ${error.message || 'Unknown error'}`);
            throw error;
        } finally {
            setResendingVoucher(false);
        }
    };

    return {
        fulfillments,
        gettingFulfillments,
        getFulfillments,
        setSelectedFulfillment,
        selectedFulfillment,
        setApplicationBatchProcessing,
        setCompanyBatchProcessing,
        resendVoucher,
        resendingVoucher
    };
};