import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/AppContext';
import { DateTime } from 'luxon';
import DateRangePicker from '../../components/DateRangePicker';
import { toast } from 'react-toastify';

// visx imports
import { Group } from '@visx/group';
import { BarGroup, BarStack } from '@visx/shape';
import { scaleLinear, scaleBand } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Grid } from '@visx/grid';
import { Text } from '@visx/text';
import { Pie } from '@visx/shape';
import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { Tooltip, useTooltip } from '@visx/tooltip';
import { LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';

import StatCard from '../../components/StatCard';

interface TimeSeriesData {
    date: string;
    value: number;
}

interface TooltipData {
    date?: string;
    value?: number;
    name?: string;
    percentage?: string;
    type?: string;
}

interface ProgressMetric {
    current: number;
    previous: number;
    percentChange: number;
}

interface CurrentProgress {
    awaitingEmployerSignature: ProgressMetric;
    awaitingActivation: ProgressMetric;
    companiesActivatedThisWeek: ProgressMetric;
    newCompaniesThisWeek: ProgressMetric;
}

interface StatusData {
    date: string;
    [key: string]: string | number;
}


const Analytics = () => {
    const {
        analyticsData,
        isLoadingAnalytics,
        analyticsError,
        fetchAnalyticsData,
    } = useUser();

    const [startDate, setStartDate] = useState<DateTime | null>(DateTime.now().minus({ weeks: 4 }));
    const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());
    const [timeFrame, setTimeFrame] = useState<'day' | 'week' | 'month'>('week');

    useEffect(() => {
        document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + `Admin | Analytics`;

        // Fetch analytics data on component mount
        fetchAnalyticsData({
            startDate: startDate?.toISO() || undefined,
            endDate: endDate?.toISO() || undefined,
            timeFrame,
        });
    }, []);

    const handleApplyFilters = () => {
        if (!startDate || !endDate) {
            toast.error('Please select both start and end dates');
            return;
        }

        if (startDate > endDate) {
            toast.error('Start date cannot be after end date');
            return;
        }

        fetchAnalyticsData({
            startDate: startDate.toISO(),
            endDate: endDate.toISO(),
            timeFrame,
        });
    };

    // Transform data for charts
    const companiesRegisteredData = useMemo(() => {
        if (!analyticsData?.companiesRegistered) return [];
        return Object.entries(analyticsData.companiesRegistered).map(([date, value]) => ({
            date,
            value,
        }));
    }, [analyticsData]);

    const companiesActivatedData = useMemo(() => {
        if (!analyticsData?.companiesActivated) return [];
        return Object.entries(analyticsData.companiesActivated).map(([date, value]) => ({
            date,
            value,
        }));
    }, [analyticsData]);

    const applicationsReceivedData = useMemo(() => {
        if (!analyticsData?.applicationsReceived) return [];
        return Object.entries(analyticsData.applicationsReceived).map(([date, value]) => ({
            date,
            value,
        }));
    }, [analyticsData]);

    const applicationsActivatedData = useMemo(() => {
        if (!analyticsData?.applicationsActivated) return [];
        return Object.entries(analyticsData.applicationsActivated).map(([date, value]) => ({
            date,
            value,
        }));
    }, [analyticsData]);

    const popularBenefitsData = useMemo(() => {
        if (!analyticsData?.popularBenefits) return [];
        return Object.entries(analyticsData.popularBenefits).map(([name, value]) => ({
            name,
            value,
        }));
    }, [analyticsData]);

    const firstFulfillments = useMemo(() => {
        if (!analyticsData?.firstFulfillments) return [];
        return Object.entries(analyticsData.firstFulfillments).map(([date, value]) => ({
            date,
            value,
        }));
    }, [analyticsData]);

    const totalFulfillments = useMemo(() => {
        if (!analyticsData?.totalFulfillments) return [];
        return Object.entries(analyticsData.totalFulfillments).map(([date, value]) => ({
            date,
            value,
        }));
    }, [analyticsData]);

    // Replace the fixed chart dimensions with responsive ones
    // const chartWidth = 400;
    // const chartHeight = 300;
    const margin = { top: 40, right: 30, bottom: 50, left: 50 };

    // Modify the TimeSeriesChart component to be responsive
    const TimeSeriesChart = ({ data, color, title, description }: { data: TimeSeriesData[], color: string, title: string, description: string }) => {
        // Chart dimensions - use refs for responsive sizing
        const containerRef = React.useRef<HTMLDivElement>(null);
        const [dimensions, setDimensions] = useState({ width: 0, height: 300 });

        // Update dimensions when container size changes
        useEffect(() => {
            if (!containerRef.current) return;

            const updateDimensions = () => {
                if (containerRef.current) {
                    const width = containerRef.current.clientWidth;
                    setDimensions({ width, height: 300 });
                }
            };

            // Set initial dimensions
            updateDimensions();

            // Add resize listener
            const resizeObserver = new ResizeObserver(updateDimensions);
            resizeObserver.observe(containerRef.current);

            // Clean up
            return () => {
                if (containerRef.current) {
                    resizeObserver.unobserve(containerRef.current);
                }
            };
        }, []);

        const chartWidth = dimensions.width;
        const chartHeight = dimensions.height;
        const innerWidth = chartWidth - margin.left - margin.right;
        const innerHeight = chartHeight - margin.top - margin.bottom;

        const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            showTooltip,
            hideTooltip,
        } = useTooltip<TooltipData>();

        // Move this useMemo before the conditional return
        const monthMarkers = useMemo(() => {
            if (!data || !data.length) return [];

            // Extract all dates and sort them
            const allDates = data.map(d => d.date).sort();

            // Group data points by month
            const monthGroups: Record<string, { date: string, label: string }> = {};

            // Process each date to determine its month
            for (const dateStr of allDates) {
                let monthDate;

                if (timeFrame === 'week') {
                    // Parse the week string (e.g., "2025-W09")
                    const [year, weekPart] = dateStr.split('-W');
                    const weekNum = parseInt(weekPart);

                    // Convert to actual date (first day of that week)
                    const date = DateTime.fromObject({
                        weekYear: parseInt(year),
                        weekNumber: weekNum
                    });

                    // Get the month this week falls in
                    monthDate = date.startOf('month');
                } else if (timeFrame === 'month') {
                    monthDate = DateTime.fromISO(dateStr).startOf('month');
                } else {
                    monthDate = DateTime.fromISO(dateStr).startOf('month');
                }

                // Key for de-duplication
                const monthKey = monthDate.toFormat('yyyy-MM');

                // Only store the first occurrence of each month
                if (!monthGroups[monthKey]) {
                    monthGroups[monthKey] = {
                        date: dateStr,
                        label: monthDate.toFormat('MMM')
                    };
                }
            }

            // Convert the month groups object to an array
            // Sort by the actual date strings to maintain chronological order
            const markers = Object.values(monthGroups).sort((a, b) => {
                // For week-based dates, extract the year and week number for comparison
                if (timeFrame === 'week') {
                    const [yearA, weekPartA] = a.date.split('-W');
                    const [yearB, weekPartB] = b.date.split('-W');
                    const weekNumA = parseInt(weekPartA);
                    const weekNumB = parseInt(weekPartB);

                    if (yearA !== yearB) {
                        return parseInt(yearA) - parseInt(yearB);
                    }
                    return weekNumA - weekNumB;
                }

                // For other date formats, simple string comparison works
                return a.date.localeCompare(b.date);
            });

            return markers;
        }, [data, timeFrame]);

        if (data.length === 0) return <div className="flex justify-center items-center h-full">No data available</div>;

        const xScale = scaleBand({
            domain: data.map(d => d.date),
            padding: 0.2,
            range: [0, innerWidth],
        });

        const yScale = scaleLinear({
            domain: [0, Math.max(...data.map(d => d.value)) + 1],
            range: [innerHeight, 0],
        });

        return (
            <div ref={containerRef} className="p-4 bg-white rounded-lg shadow w-full">
                <h3 className="font-semibold text-[#333] mb-2">{title}</h3>
                <p className="text-sm text-gray-600 mb-4">{description}</p>
                {dimensions.width > 0 && (
                    <svg width={chartWidth} height={chartHeight}>
                        <Group left={margin.left} top={margin.top}>
                            <Grid
                                xScale={xScale}
                                yScale={yScale}
                                width={innerWidth}
                                height={innerHeight}
                                stroke="#e0e0e0"
                                strokeOpacity={0.5}
                                numTicksRows={5}
                            />

                            {/* Month markers - vertical lines */}
                            {monthMarkers.map((marker, i) => (
                                <line
                                    key={`month-marker-${i}`}
                                    x1={xScale(marker.date) ?? 0}
                                    y1={0}
                                    x2={xScale(marker.date) ?? 0}
                                    y2={innerHeight}
                                    stroke="#999"
                                    strokeWidth={1}
                                    strokeDasharray="4,4"
                                    opacity={0.7}
                                />
                            ))}

                            {/* Month labels at the top */}
                            {monthMarkers.map((marker, i) => (
                                <text
                                    key={`month-label-${i}`}
                                    x={xScale(marker.date) ?? 0}
                                    y={-8}
                                    fontSize={9}
                                    textAnchor="middle"
                                    fill="#666"
                                >
                                    {marker.label}
                                </text>
                            ))}

                            <AxisBottom
                                scale={xScale}
                                top={innerHeight}
                                tickFormat={(date) => {
                                    if (timeFrame === 'week') {
                                        return DateTime.fromISO(date.toString().split("-W")[0])
                                            .plus({ weeks: parseInt(date.toString().split("-W")[1]) - 1 }).startOf("week")
                                            .toFormat('MMM dd');
                                    }
                                    if (timeFrame === 'month') {
                                        return DateTime.fromISO(date.toString()).toFormat('MMM yyyy');
                                    }
                                    return date.toString();
                                }}
                                tickLabelProps={() => ({
                                    fill: '#333333',
                                    fontSize: 10,
                                    textAnchor: 'middle',
                                    dy: '0.5em',
                                })}
                                hideAxisLine
                            />
                            <AxisLeft
                                scale={yScale}
                                numTicks={5}
                                tickLabelProps={() => ({
                                    fill: '#333333',
                                    fontSize: 10,
                                    textAnchor: 'end',
                                    dx: '-0.5em',
                                    dy: '0.25em',
                                })}
                                hideAxisLine
                            />
                            {data.map((d, i) => (
                                <rect
                                    key={`bar-${i}`}
                                    x={xScale(d.date)}
                                    y={yScale(d.value)}
                                    width={xScale.bandwidth()}
                                    height={innerHeight - yScale(d.value)}
                                    fill={color}
                                    rx={4}
                                    onMouseMove={(event) => {
                                        const coords = {
                                            x: event.clientX,
                                            y: event.clientY,
                                        };
                                        showTooltip({
                                            tooltipData: { date: d.date, value: d.value },
                                            tooltipLeft: coords.x,
                                            tooltipTop: coords.y,
                                        });
                                    }}
                                    onMouseLeave={() => hideTooltip()}
                                />
                            ))}
                        </Group>
                    </svg>
                )}
                {tooltipOpen && tooltipData && (
                    <Tooltip
                        top={tooltipTop}
                        left={tooltipLeft}
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '5px 10px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                            fontSize: '12px',
                            pointerEvents: 'none',
                        }}
                    >
                        <div>
                            <strong>Date: </strong>
                            {typeof tooltipData.date === 'string' ? (
                                timeFrame === 'week' ? (
                                    DateTime.fromISO(tooltipData.date.split("-W")[0])
                                        .plus({ weeks: parseInt(tooltipData.date.split("-W")[1]) - 1 })
                                        .toFormat('MMM dd, yyyy')
                                ) : timeFrame === 'month' ? (
                                    DateTime.fromISO(tooltipData.date).toFormat('MMMM yyyy')
                                ) : (
                                    tooltipData.date
                                )
                            ) : (
                                'Unknown date'
                            )}
                        </div>
                        <div>
                            <strong>Value: </strong>
                            {typeof tooltipData.value === 'number' ? tooltipData.value : 'Unknown value'}
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    // Similarly update PieChart component
    const PieChart = () => {
        const containerRef = React.useRef<HTMLDivElement>(null);
        const [dimensions, setDimensions] = useState({ width: 0, height: 300 });

        useEffect(() => {
            // Same resize observer logic as TimeSeriesChart
            // ...
        }, []);

        const pieWidth = dimensions.width;
        const pieHeight = dimensions.height;

        const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            showTooltip,
            hideTooltip,
        } = useTooltip<TooltipData>();
        if (!popularBenefitsData.length) return <div className="flex justify-center items-center h-full">No data available</div>;

        const total = popularBenefitsData.reduce((sum, entry) => sum + entry.value, 0);
        const radius = Math.min(pieWidth, pieHeight) / 2 - 20;
        const centerX = pieWidth / 2;
        const centerY = pieHeight / 2;

        const colorScale = (i: number) => {
            const colors = [
                'rgb(59, 130, 246)', 'rgb(34, 197, 94)', 'rgb(249, 115, 22)',
                'rgb(168, 85, 247)', 'rgb(234, 88, 12)', 'rgb(22, 163, 74)',
                'rgb(37, 99, 235)', 'rgb(192, 132, 252)', 'rgb(251, 191, 36)',
                'rgb(96, 165, 250)'
            ];
            return colors[i % colors.length];
        };

        return (
            <div ref={containerRef} className="p-4 bg-white rounded-lg shadow w-full">
                <h3 className="font-semibold text-[#333] mb-2">Most Popular Benefits</h3>
                <div className="flex flex-col md:flex-row items-center">
                    <svg width={pieWidth} height={pieHeight}>
                        <Group top={centerY} left={centerX}>
                            <Pie
                                data={popularBenefitsData}
                                pieValue={d => d.value}
                                outerRadius={radius}
                                innerRadius={radius / 2}
                                padAngle={0.01}
                            >
                                {pie => {
                                    return pie.arcs.map((arc, i) => {
                                        const [centroidX, centroidY] = pie.path.centroid(arc);
                                        const percentage = ((arc.data.value / total) * 100).toFixed(1);

                                        return (
                                            <g key={`arc-${i}`}
                                                onMouseMove={(event) => {
                                                    const coords = {
                                                        x: event.clientX,
                                                        y: event.clientY,
                                                    };
                                                    showTooltip({
                                                        tooltipData: {
                                                            name: arc.data.name,
                                                            value: arc.data.value,
                                                            percentage: percentage
                                                        },
                                                        tooltipLeft: coords.x,
                                                        tooltipTop: coords.y,
                                                    });
                                                }}
                                                onMouseLeave={hideTooltip}
                                            >
                                                <path
                                                    d={pie.path(arc) || ''}
                                                    fill={colorScale(i)}
                                                />
                                            </g>
                                        );
                                    });
                                }}
                            </Pie>
                        </Group>
                    </svg>
                    <div className="ml-4 text-xs">
                        {popularBenefitsData.map((item, i) => (
                            <div key={i} className="flex items-center mb-1">
                                <div
                                    className="w-4 h-4 mr-2"
                                    style={{ backgroundColor: colorScale(i) }}
                                ></div>
                                <span>{item.name} ({((item.value / total) * 100).toFixed(1)}%)</span>
                            </div>
                        ))}
                    </div>
                </div>
                {tooltipOpen && tooltipData && (
                    <Tooltip
                        top={tooltipTop}
                        left={tooltipLeft}
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '5px 10px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                            fontSize: '12px',
                            pointerEvents: 'none',
                        }}
                    >
                        <div>
                            <strong>{tooltipData.name}</strong>
                        </div>
                        <div>
                            Count: {tooltipData.value}
                        </div>
                        <div>
                            Percentage: {tooltipData.percentage}%
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    // Add a new section for Current Progress component
    const CurrentProgressComponent = () => {
        // Use type assertion to fix TypeScript errors
        const currentProgress = (analyticsData as any)?.currentProgress;
        if (!currentProgress) return null;

        const {
            awaitingEmployerSignature,
            awaitingActivation,
            companiesActivatedThisWeek,
            newCompaniesThisWeek
        } = currentProgress;

        return (
            <div className="mb-8 bg-white p-6 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-4">This week's progress</h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <StatCard
                        description="Applications Awaiting Employer Signature This Week"
                        value={awaitingEmployerSignature.current.toString()}
                        prevValue={awaitingEmployerSignature.previous.toString()}
                        percentChange={Number(awaitingEmployerSignature.percentChange)}
                        showComparison={true}
                    />
                    <StatCard
                        description="Applications Awaiting Activation This Week"
                        value={awaitingActivation.current.toString()}
                        prevValue={awaitingActivation.previous.toString()}
                        percentChange={Number(awaitingActivation.percentChange)}
                        showComparison={true}
                    />
                    <StatCard
                        description="New Companies This Week"
                        value={newCompaniesThisWeek.current.toString()}
                        prevValue={newCompaniesThisWeek.previous.toString()}
                        percentChange={Number(newCompaniesThisWeek.percentChange)}
                        showComparison={true}
                    />
                    <StatCard
                        description="Companies Activated This Week"
                        value={companiesActivatedThisWeek.current.toString()}
                        prevValue={companiesActivatedThisWeek.previous.toString()}
                        percentChange={Number(companiesActivatedThisWeek.percentChange)}
                        showComparison={true}
                    />
                </div>
            </div>
        );
    };

    // Fix the applicationStatusData useMemo function to handle typing correctly
    const applicationStatusData = useMemo(() => {
        if (!analyticsData || !analyticsData.applicationStatusCounts) return [];

        // Get all the keys and time periods
        const statusTypes = Object.keys((analyticsData as any).applicationStatusCounts);
        const allDates = new Set<string>();

        // Collect all unique dates from all statuses
        statusTypes.forEach(status => {
            Object.keys((analyticsData as any).applicationStatusCounts[status] || {}).forEach(date => {
                allDates.add(date);
            });
        });

        // Sort dates chronologically
        const sortedDates = Array.from(allDates).sort();

        // Create data structure for stacked bar chart
        return sortedDates.map(date => {
            const dataPoint: StatusData = { date };

            // Add counts for each status
            statusTypes.forEach(status => {
                dataPoint[status] = (analyticsData as any).applicationStatusCounts[status][date] || 0;
            });

            return dataPoint;
        });
    }, [analyticsData]);

    // Modify the ApplicationStatusChart component to move hooks to the top level

    const ApplicationStatusChart = () => {
        // Chart dimensions - use refs for responsive sizing
        const containerRef = React.useRef<HTMLDivElement>(null);
        const [dimensions, setDimensions] = useState({ width: 0, height: 400 }); // Make this chart taller

        const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            showTooltip,
            hideTooltip,
        } = useTooltip<TooltipData>();

        // Move this check after all hooks are called
        const hasData = analyticsData && (analyticsData as any).applicationStatusCounts && applicationStatusData.length > 0;

        // All hooks must be called before any conditional returns
        useEffect(() => {
            if (!containerRef.current) return;

            const updateDimensions = () => {
                if (containerRef.current) {
                    const width = containerRef.current.clientWidth;
                    setDimensions({ width, height: 400 });
                }
            };

            // Set initial dimensions
            updateDimensions();

            // Add resize listener
            const resizeObserver = new ResizeObserver(updateDimensions);
            resizeObserver.observe(containerRef.current);

            // Clean up
            return () => {
                if (containerRef.current) {
                    resizeObserver.unobserve(containerRef.current);
                }
            };
        }, []);

        // Early return after all hooks are called
        if (!hasData) {
            return <div className="flex justify-center items-center h-full">No application status data available</div>;
        }

        const chartWidth = dimensions.width;
        const chartHeight = dimensions.height;
        const margin = { top: 40, right: 30, bottom: 80, left: 60 };
        const innerWidth = chartWidth - margin.left - margin.right;
        const innerHeight = chartHeight - margin.top - margin.bottom;

        // Instead of just getting keys, use a predefined order
        const statusOrder = [
            "ACTIVE",
            "COMPLETED",
            "AWAITING_ACTIVATION",
            "AWAITING_EMPLOYER_SIGNATURE",
            "PAUSED",
            "PROCESSING",
            "CANCELLED",
            "PENDING_PRODUCT_CHANGE" // Including this to avoid losing any data
        ];

        // Filter the keys to only include those that have data, but maintain the defined order
        const keys = statusOrder.filter(status =>
            (analyticsData as any).applicationStatusCounts[status] &&
            Object.values((analyticsData as any).applicationStatusCounts[status]).some((val: any) => val > 0)
        );

        // Update the colorMap to use the existing color scheme from getStatusStyles
        const colorMap: Record<string, string> = {
            "AWAITING_EMPLOYER_SIGNATURE": "rgba(59, 130, 246, 0.7)",  // blue-400
            "AWAITING_ACTIVATION": "rgba(168, 85, 247, 0.7)",         // purple-400
            "ACTIVE": "rgba(34, 197, 94, 0.7)",                       // green-400
            "COMPLETED": "rgba(79, 70, 229, 0.7)",                   // indigo-500
            "PENDING_PRODUCT_CHANGE": "rgba(192, 38, 211, 0.7)",      // fuchsia-400
            "CANCELLED": "rgba(239, 68, 68, 0.7)",                    // red
            "PAUSED": "rgba(6, 182, 212, 0.7)",                       // cyan-400
            "PROCESSING": "rgba(251, 191, 36, 0.7)",                  // amber-400
            "INFORMATION_REQUIRED": "rgba(234, 179, 8, 0.7)"          // yellow-400
        };

        // Update the statusLabels to include the PROCESSING status
        const statusLabels: Record<string, string> = {
            "AWAITING_EMPLOYER_SIGNATURE": "Awaiting Employer Signature",
            "AWAITING_ACTIVATION": "Awaiting Activation",
            "ACTIVE": "Active",
            "PENDING_PRODUCT_CHANGE": "Pending Product Change",
            "CANCELLED": "Cancelled",
            "PAUSED": "Paused",
            "COMPLETED": "Completed",
            "PROCESSING": "Processing"
        };

        // Create scales
        const xScale = scaleBand<string>({
            domain: applicationStatusData.map(d => d.date),
            padding: 0.2,
            range: [0, innerWidth],
        });

        const yScale = scaleLinear<number>({
            domain: [
                0,
                Math.max(
                    ...applicationStatusData.map(d => {
                        return keys.reduce((sum, key) => sum + (Number(d[key]) || 0), 0);
                    }),
                ) + 1,
            ],
            range: [innerHeight, 0],
            nice: true,
        });

        // Color scale for the legend
        const colorScale = scaleOrdinal<string, string>({
            domain: keys,
            range: keys.map(key => colorMap[key] || `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`),
        });

        // Fix the onClick handler to remove reference to undefined events variable
        const handleBarClick = () => {
            // No action needed
        };

        // Calculate month markers
        const monthMarkers = (() => {
            const allDates = applicationStatusData.map(d => d.date).sort();

            // Group data points by month
            const monthGroups: Record<string, { date: string, label: string }> = {};

            // Process each date to determine its month
            for (const dateStr of allDates) {
                let monthDate;

                if (timeFrame === 'week') {
                    // Parse the week string (e.g., "2025-W09")
                    const [year, weekPart] = dateStr.split('-W');
                    const weekNum = parseInt(weekPart);

                    // Convert to actual date (first day of that week)
                    const date = DateTime.fromObject({
                        weekYear: parseInt(year),
                        weekNumber: weekNum
                    });

                    // Get the month this week falls in
                    monthDate = date.startOf('month');
                } else if (timeFrame === 'month') {
                    monthDate = DateTime.fromISO(dateStr).startOf('month');
                } else {
                    monthDate = DateTime.fromISO(dateStr).startOf('month');
                }

                // Key for de-duplication
                const monthKey = monthDate.toFormat('yyyy-MM');

                // Only store the first occurrence of each month
                if (!monthGroups[monthKey]) {
                    monthGroups[monthKey] = {
                        date: dateStr,
                        label: monthDate.toFormat('MMM')
                    };
                }
            }

            // Convert the month groups object to an array
            // Sort by the actual date strings to maintain chronological order
            return Object.values(monthGroups).sort((a, b) => {
                // For week-based dates, extract the year and week number for comparison
                if (timeFrame === 'week') {
                    const [yearA, weekPartA] = a.date.split('-W');
                    const [yearB, weekPartB] = b.date.split('-W');
                    const weekNumA = parseInt(weekPartA);
                    const weekNumB = parseInt(weekPartB);

                    if (yearA !== yearB) {
                        return parseInt(yearA) - parseInt(yearB);
                    }
                    return weekNumA - weekNumB;
                }

                // For other date formats, simple string comparison works
                return a.date.localeCompare(b.date);
            });
        })();

        return (
            <div ref={containerRef} className="p-4 bg-white rounded-lg shadow w-full">
                <h3 className="font-semibold text-[#333] mb-2">Application Status Over Time</h3>
                <p className="text-sm text-gray-600 mb-4">
                    Number of applications in each status category per {timeFrame}
                </p>

                {/* Legend */}
                <div className="mb-4 flex flex-wrap gap-2 justify-center">
                    {keys.map(key => (
                        <div key={key} className="flex items-center mx-2">
                            <div className="w-3 h-3 mr-1" style={{ backgroundColor: colorMap[key] }}></div>
                            <span className="text-xs">{statusLabels[key] || key}</span>
                        </div>
                    ))}
                </div>

                {dimensions.width > 0 && (
                    <svg width={chartWidth} height={chartHeight}>
                        <Group left={margin.left} top={margin.top}>
                            <Grid
                                xScale={xScale}
                                yScale={yScale}
                                width={innerWidth}
                                height={innerHeight}
                                stroke="#e0e0e0"
                                strokeOpacity={0.5}
                                numTicksRows={5}
                            />

                            {/* Month markers - vertical lines */}
                            {monthMarkers.map((marker, i) => (
                                <line
                                    key={`month-marker-${i}`}
                                    x1={xScale(marker.date) ?? 0}
                                    y1={0}
                                    x2={xScale(marker.date) ?? 0}
                                    y2={innerHeight}
                                    stroke="#999"
                                    strokeWidth={1}
                                    strokeDasharray="4,4"
                                    opacity={0.7}
                                />
                            ))}

                            {/* Month labels at the top */}
                            {monthMarkers.map((marker, i) => (
                                <text
                                    key={`month-label-${i}`}
                                    x={xScale(marker.date) ?? 0}
                                    y={-8}
                                    fontSize={9}
                                    textAnchor="middle"
                                    fill="#666"
                                >
                                    {marker.label}
                                </text>
                            ))}

                            <BarStack
                                data={applicationStatusData}
                                keys={keys}
                                x={(d) => d.date}
                                xScale={xScale}
                                yScale={yScale}
                                color={(key) => colorMap[key] || 'rgba(23, 233, 217, .5)'}
                            >
                                {(barStacks) =>
                                    barStacks.map((barStack) =>
                                        barStack.bars.map((bar) => (
                                            <rect
                                                key={`bar-stack-${barStack.index}-${bar.index}`}
                                                x={bar.x}
                                                y={bar.y}
                                                height={bar.height}
                                                width={bar.width}
                                                fill={bar.color}
                                                rx={4}
                                                onClick={handleBarClick}
                                                onMouseLeave={() => {
                                                    hideTooltip();
                                                }}
                                                onMouseMove={(event) => {
                                                    const coords = {
                                                        x: event.clientX,
                                                        y: event.clientY,
                                                    };
                                                    showTooltip({
                                                        tooltipData: {
                                                            date: bar.bar.data.date,
                                                            value: Number(bar.bar.data[bar.key]), // Convert to number
                                                            type: statusLabels[bar.key] || bar.key
                                                        },
                                                        tooltipLeft: coords.x,
                                                        tooltipTop: coords.y,
                                                    });
                                                }}
                                            />
                                        )),
                                    )
                                }
                            </BarStack>

                            <AxisBottom
                                scale={xScale}
                                top={innerHeight}
                                tickFormat={(date) => {
                                    if (timeFrame === 'week') {
                                        return DateTime.fromISO(date.toString().split("-W")[0])
                                            .plus({ weeks: parseInt(date.toString().split("-W")[1]) - 1 }).startOf("week")
                                            .toFormat('MMM dd');
                                    }
                                    if (timeFrame === 'month') {
                                        return DateTime.fromISO(date.toString()).toFormat('MMM yyyy');
                                    }
                                    return date.toString();
                                }}
                                tickLabelProps={() => ({
                                    fill: '#333333',
                                    fontSize: 10,
                                    textAnchor: 'middle',
                                    dy: '0.5em',
                                })}
                                hideAxisLine
                            />

                            <AxisLeft
                                scale={yScale}
                                numTicks={5}
                                tickLabelProps={() => ({
                                    fill: '#333333',
                                    fontSize: 10,
                                    textAnchor: 'end',
                                    dx: '-0.5em',
                                    dy: '0.25em',
                                })}
                                hideAxisLine
                            />
                        </Group>
                    </svg>
                )}

                {tooltipOpen && tooltipData && (
                    <Tooltip
                        top={tooltipTop}
                        left={tooltipLeft}
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '5px 10px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                            fontSize: '12px',
                            pointerEvents: 'none',
                        }}
                    >
                        <div>
                            <strong>Date: </strong>
                            {typeof tooltipData.date === 'string' ? (
                                timeFrame === 'week' ? (
                                    DateTime.fromISO(tooltipData.date.split("-W")[0])
                                        .plus({ weeks: parseInt(tooltipData.date.split("-W")[1]) - 1 })
                                        .toFormat('MMM dd, yyyy')
                                ) : timeFrame === 'month' ? (
                                    DateTime.fromISO(tooltipData.date).toFormat('MMMM yyyy')
                                ) : (
                                    tooltipData.date
                                )
                            ) : (
                                'Unknown date'
                            )}
                        </div>
                        <div>
                            <strong>{tooltipData.type}: </strong>
                            {tooltipData.value}
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    return (
        <div className="min-h-screen flex-col flex-grow bg-white p-4 w-full">
            <h2 className="ml-2">ANALYTICS</h2>
            {/* Content */}
            {analyticsData && !isLoadingAnalytics && (
                <>
                    {/* Current Progress Section - Add this before the existing content */}
                    <CurrentProgressComponent />
                    {/* Filters */}
                    <div className="flex flex-col md:flex-row items-end gap-4 mb-6 bg-white p-4 rounded-lg shadow">
                        <div>
                            <label className="block text-sm text-darkgrey mb-1">Date Range</label>
                            <DateRangePicker
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}

                                mini={true}

                            />
                        </div>
                        <div>
                            <label className="block text-sm text-darkgrey mb-1">Time Frame</label>
                            <select
                                value={timeFrame}
                                onChange={(e) => setTimeFrame(e.target.value as 'day' | 'week' | 'month')}
                                className="p-2 border border-lightgrey rounded-md"
                            >
                                <option value="day">Daily</option>
                                <option value="week">Weekly</option>
                                <option value="month">Monthly</option>
                            </select>
                        </div>
                        <button
                            onClick={handleApplyFilters}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition-colors"
                            disabled={isLoadingAnalytics}
                        >
                            Apply Filters
                        </button>
                    </div>

                    {/* Loading state */}
                    {isLoadingAnalytics && (
                        <div className="flex justify-center items-center h-40">
                            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
                        </div>
                    )}

                    {/* Error state */}
                    {analyticsError && (
                        <div className="bg-red/10 border border-red rounded-lg p-4 mb-6">
                            <p className="text-red font-medium">{analyticsError}</p>
                        </div>
                    )}




                    {/* Summary Cards
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
                        <StatCard
                            description="Total Companies"
                            value={analyticsData.totalCompanies.toString()}
                        // subvalue={`${analyticsData.activeCompanies} active (${Math.round((analyticsData.activeCompanies / analyticsData.totalCompanies) * 100)}%)`}
                        // background="bg-blue-50"
                        // border="border-blue-400"
                        />
                        <StatCard
                            description="Total Applications"
                            value={analyticsData.totalApplications.toString()}
                        // subvalue={`${analyticsData.activeApplications} active (${Math.round((analyticsData.activeApplications / analyticsData.totalApplications) * 100)}%)`}
                        // background="bg-green-100"
                        // border="border-green-400"
                        />
                        <StatCard
                            description="Registration to Active"
                            value={`${analyticsData.conversionRates.registrationToActive}%`}
                        // subvalue="Conversion rate"
                        // background="bg-purple-100"
                        // border="border-purple-400"
                        />
                        <StatCard
                            description="Application to Active"
                            value={`${analyticsData.conversionRates.applicationToActive}%`}
                        // subvalue="Conversion rate"
                        // background="bg-amber-100"
                        // border="border-amber-400"
                        />
                    </div> */}

                    {/* Time to Activation Metrics */}
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                        <StatCard
                            description="Avg Time to Activation (Companies)"
                            value={`${analyticsData.timeToActivation.companies} days`}
                        // subvalue="From registration to active state"
                        // background="bg-cyan-100"
                        // border="border-cyan-400"
                        />
                        <StatCard
                            description="Avg Time to Activation (Applications)"
                            value={`${analyticsData.timeToActivation.applications} days`}
                        // subvalue="From submission to first activation"
                        // background="bg-fuchsia-100"
                        // border="border-fuchsia-400"
                        />
                    </div> */}

                    {/* Charts Grid - use flex instead of grid for better responsiveness */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 w-full">
                        {/* <StackedCompaniesChart /> */}
                        <TimeSeriesChart
                            data={companiesRegisteredData}
                            color="rgba(59, 130, 246, 0.7)"
                            title={`New Companies per ${timeFrame}`}
                            description={`Shows the total companies registered as new leads per ${timeFrame}`}
                        />
                        <TimeSeriesChart
                            data={companiesActivatedData}
                            color="rgba(34, 197, 94, 0.7)"
                            title={`Companies Activated per ${timeFrame}`}
                            description={`Companies that have completed onboarding (enrichment data and DD or manual payment) per ${timeFrame}`}
                        />
                        <TimeSeriesChart
                            data={applicationsReceivedData}
                            color="rgba(249, 115, 22, 0.7)"
                            title={`Applications Received per ${timeFrame}`}
                            description={`Shows the total applications received per ${timeFrame}`}
                        />
                        <TimeSeriesChart
                            data={firstFulfillments}
                            color="rgba(168, 85, 247, 0.7)"
                            title={`New Applications First Activated per ${timeFrame}`}
                            description={`Applications activated for the first time per ${timeFrame}`}
                        />
                        <TimeSeriesChart
                            data={totalFulfillments}
                            color="rgba(168, 85, 247, 0.7)"
                            title={`Total Applications fulfilled per ${timeFrame}`}
                            description={`Total applications fulfilled (activated/completed) per ${timeFrame}`}
                        />
                        <div className="col-span-1 md:col-span-2">
                            <ApplicationStatusChart />
                        </div>
                    </div>

                    {/* Popular Benefits Chart */}
                    <div className="mb-6">
                        <PieChart />
                    </div>
                </>
            )}
        </div>
    );
};

export default Analytics;