import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { applicationApi, dashboardApi } from '../services/apiClient';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore, auth } from '../firebaseConfig';
import { isAuthenticated } from '../utils/auth';

export interface ApplicationFilters {
    dateRange?: {
        start?: DateTime;
        end?: DateTime;
    };
    status?: string[];
}

export interface PaginatedResponse {
    data: any[];
    nextCursor: string | null;
    hasNextPage: boolean;
}

export const useApplications = () => {
    const queryClient = useQueryClient();
    const [selectedApplication, setSelectedApplication] = useState<any>(null);

    const fetchPaginatedApplications = async ({
        pageParam = null,
        filters = {} as ApplicationFilters
    }: {
        pageParam?: string | null;
        filters?: ApplicationFilters
    }): Promise<PaginatedResponse> => {
        try {
            const response = await dashboardApi.post('/paginated-data', {
                cursor: pageParam,
                pageSize: 100,
                dataType: 'applications',
                filters: {
                    dateRange: filters.dateRange ? {
                        start: filters.dateRange.start?.toISO?.(),
                        end: filters.dateRange.end?.toISO?.()
                    } : undefined,
                    status: filters.status
                },
                tableId: 'AllApplications'
            });

            return {
                data: response.applications.data,
                nextCursor: response.applications.nextCursor,
                hasNextPage: response.applications.hasNextPage
            };
        } catch (error) {
            console.error('Error fetching paginated applications:', error);
            throw error;
        }
    };

    const defaultFilters = React.useMemo(() => ({}), []);

    const applicationsQuery = useInfiniteQuery({
        queryKey: ['applications', 'paginated', defaultFilters],
        queryFn: ({ pageParam }) => fetchPaginatedApplications({
            pageParam: pageParam as string | null,
            filters: defaultFilters
        }),
        getNextPageParam: (lastPage: PaginatedResponse) => lastPage.nextCursor,
        initialPageParam: null as string | null,
        enabled: isAuthenticated(),
        staleTime: 15 * 60 * 1000,
        gcTime: 60 * 60 * 1000,

        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const flattenedApplications = React.useMemo(() => {
        if (!applicationsQuery.data?.pages) return [];
        return applicationsQuery.data.pages.flatMap(page => page.data || []);
    }, [applicationsQuery.data]);

    const usePaginatedApplications = (filters: ApplicationFilters = {}) => {
        return useInfiniteQuery({
            queryKey: ['applications', 'paginated', filters],
            queryFn: ({ pageParam }) => fetchPaginatedApplications({
                pageParam: pageParam as string | null,
                filters
            }),
            getNextPageParam: (lastPage: PaginatedResponse) => lastPage.nextCursor,
            initialPageParam: null as string | null,
            enabled: isAuthenticated(),
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        });
    };

    // Add the listener setup
    useEffect(() => {
        let unsubscribe: (() => void) | undefined;

        const setupListener = async () => {
            if (selectedApplication?.id && isAuthenticated()) {
                try {
                    const user = auth.currentUser;
                    if (user) {
                        const token = await user.getIdToken(true);
                    }

                    unsubscribe = onSnapshot(
                        doc(firestore, "applications", selectedApplication.id),
                        {
                            next: (doc) => {
                                if (doc.exists()) {
                                    const updatedApplication = { ...doc.data(), id: selectedApplication.id };
                                    setSelectedApplication(updatedApplication);

                                    // Update all paginated application queries
                                    queryClient.setQueriesData(
                                        { queryKey: ['applications', 'paginated'] },
                                        (oldData: any) => {
                                            if (!oldData) return oldData;

                                            return {
                                                ...oldData,
                                                pages: oldData.pages.map((page: any) => ({
                                                    ...page,
                                                    data: page.data.map((app: any) =>
                                                        app.id === selectedApplication.id ? updatedApplication : app
                                                    )
                                                }))
                                            };
                                        }
                                    );
                                }
                            },
                            error: (error) => {
                                console.error("Error listening to selected application:", {
                                    error,
                                    applicationId: selectedApplication.id,
                                    currentUser: auth.currentUser?.uid,
                                    isAuthenticated: !!auth.currentUser,
                                    errorCode: error.code,
                                    errorDetails: error.message
                                });
                            }
                        }
                    );
                } catch (error) {
                    console.error("Error setting up listener:", error);
                }
            }
        };

        setupListener();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [selectedApplication?.id, queryClient]);

    const getApplication = async (applicationID: string) => {
        if (!isAuthenticated()) {
            throw new Error('Not authenticated');
        }

        const response = await dashboardApi.post(`/fetch-application`, { appID: applicationID });
        return response.application;
    }

    const resendInformationRequiredEmployeeEmail = async (applicationID: string) => {
        if (!isAuthenticated()) {
            throw new Error('Not authenticated');
        }

        const response = await dashboardApi.post(`/resend-information-required-employee-email`, { applicationID: applicationID });
        return response;
    }

    const fetchAndSetApplication = async (applicationID: string) => {
        const application = await getApplication(applicationID);
        setSelectedApplication(application);
    }

    return {
        applicationsQuery,
        flattenedApplications,
        usePaginatedApplications,
        selectedApplication,
        setSelectedApplication,
        getApplication,
        fetchAndSetApplication,
        resendInformationRequiredEmployeeEmail
    };
};