import { platformsApi } from './apiClient';
import { Platform } from '../types/platform';

export const getPlatforms = async (): Promise<Platform[]> => {
    const response = await platformsApi.get('/list');
    return response.data;
};

export const getPlatform = async (id: string): Promise<Platform> => {
    const response = await platformsApi.get(`/${id}`);
    return response.data;
};

export const createPlatform = async (platformData: Omit<Platform, 'id'>): Promise<Platform> => {
    const response = await platformsApi.post('/create', platformData);
    return response.data;
};

export const updatePlatform = async (platformData: Platform): Promise<Platform> => {
    const response = await platformsApi.put(`/update/${platformData.id}`, platformData);
    return response.data;
};

export const deletePlatform = async (id: string): Promise<void> => {
    await platformsApi.delete(`/${id}`);
};

export const updatePlatformContacts = async (id: string, contacts: Platform['contacts']): Promise<Platform> => {
    const response = await platformsApi.put(`/contacts/${id}`, { contacts });
    return response.data;
};

export const updatePlatformSftp = async (id: string, sftp: Platform['sftp']): Promise<Platform> => {
    const response = await platformsApi.put(`/sftp/${id}`, { sftp });
    return response.data;
};

// We're now using a direct fetch approach for downloads with proper authentication
// export const downloadPlatformFiles = async (id: string): Promise<Blob> => {
//     const response = await platformsApi.get(`/get-files/${id}`, {
//         responseType: 'blob' // Important: This tells axios to handle the response as a blob
//     });
//     return response.data;
// };

// Function to get companies by platform ID
export const getCompaniesByPlatformId = async (platformId: string): Promise<any[]> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/api/company/by-provider/${platformId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch companies');
        }

        const data = await response.json();
        return data.data || [];
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};

// Process applications from CSV data
export const processApplications = async (
    platformId: string,
    companyId: string,
    applications: any[]
): Promise<any> => {
    try {
        const response = await platformsApi.post('/process-mapped-applications', {
            platformID: platformId,
            companyID: companyId,
            applications: applications
        });

        return response.data;
    } catch (error) {
        console.error('Error processing applications:', error);
        throw error;
    }
}; 