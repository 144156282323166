import React, { useState, useEffect } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import { FieldMapping, TARGET_FIELDS } from '../../types/platform';

interface FieldMappingProps {
    fileHeaders: string[];
    platformId: string;
    onMappingComplete: (mappings: FieldMapping[]) => void;
    initialMappings?: FieldMapping[];
}

const PlatformFieldMapping: React.FC<FieldMappingProps> = ({
    fileHeaders,
    platformId,
    onMappingComplete,
    initialMappings = []
}) => {
    const [mappings, setMappings] = useState<FieldMapping[]>([]);
    const [loadedMappings, setLoadedMappings] = useState(false);

    // Load existing mappings if available
    useEffect(() => {
        if (!loadedMappings && platformId) {
            setLoadedMappings(true);

            if (initialMappings && initialMappings.length > 0) {
                // If we have initial mappings, use those (from platform configuration)
                // But ensure that all source columns match available fileHeaders
                const validInitialMappings = initialMappings.filter(mapping =>
                    fileHeaders.includes(mapping.sourceColumn)
                );

                // Add any file headers that don't have mappings
                const mappedSourceColumns = validInitialMappings.map(m => m.sourceColumn);
                const unmappedHeaders = fileHeaders.filter(header => !mappedSourceColumns.includes(header));

                const newMappings = [
                    ...validInitialMappings,
                    ...unmappedHeaders.map(header => ({
                        sourceColumn: header,
                        targetField: '',
                        isRequired: false
                    }))
                ];

                setMappings(newMappings);

                // If all fields are already mapped, show a helpful message
                if (validInitialMappings.length === fileHeaders.length) {
                    // All headers are already mapped
                    console.log('All file headers are already mapped from platform configuration');
                }
            } else if (fileHeaders.length > 0 && mappings.length === 0) {
                // Default: Create an empty mapping for each header
                setMappings(fileHeaders.map(header => ({
                    sourceColumn: header,
                    targetField: '',
                    isRequired: false
                })));
            }
        }
    }, [fileHeaders, platformId, loadedMappings, mappings.length, initialMappings]);

    const handleAddMapping = () => {
        setMappings([
            ...mappings,
            {
                sourceColumn: '',
                targetField: '',
                isRequired: false
            }
        ]);
    };

    const handleRemoveMapping = (index: number) => {
        const newMappings = [...mappings];
        newMappings.splice(index, 1);
        setMappings(newMappings);
    };

    const handleMappingChange = (index: number, field: keyof FieldMapping, value: string | boolean) => {
        const newMappings = [...mappings];
        newMappings[index] = {
            ...newMappings[index],
            [field]: value
        };
        setMappings(newMappings);
    };

    const handleSaveMappings = () => {
        // Filter out any mappings without a target field
        const validMappings = mappings.filter(mapping => mapping.targetField);
        onMappingComplete(validMappings);
    };

    const getTargetFieldOptions = () => {
        const options = [];

        for (const category in TARGET_FIELDS) {
            const fields = TARGET_FIELDS[category as keyof typeof TARGET_FIELDS];

            options.push(
                <optgroup key={category} label={category.charAt(0).toUpperCase() + category.slice(1)}>
                    {fields.map(field => (
                        <option key={`${category}.${field}`} value={`${category}.${field}`}>
                            {field}
                        </option>
                    ))}
                </optgroup>
            );
        }

        return options;
    };

    return (
        <div>
            <div className="mb-4 flex justify-between items-center">
                <h3 className="text-lg font-semibold">Field Mappings</h3>
                <button
                    onClick={handleAddMapping}
                    className="bg-blue-500 text-white p-2 rounded-md flex items-center text-sm"
                >
                    <FiPlus className="mr-1" /> Add Mapping
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 rounded-md">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Source Column
                            </th>
                            <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Target Field
                            </th>
                            <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Default Value
                            </th>
                            <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Required
                            </th>
                            <th className="py-2 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {mappings.map((mapping, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                <td className="py-2 px-4 border-b">
                                    <select
                                        value={mapping.sourceColumn}
                                        onChange={(e) => handleMappingChange(index, 'sourceColumn', e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    >
                                        <option value="">Select a column</option>
                                        {fileHeaders.map((header) => (
                                            <option key={header} value={header}>
                                                {header}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <select
                                        value={mapping.targetField}
                                        onChange={(e) => handleMappingChange(index, 'targetField', e.target.value)}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    >
                                        <option value="">Select a field</option>
                                        {getTargetFieldOptions()}
                                    </select>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <input
                                        type="text"
                                        value={mapping.defaultValue || ''}
                                        onChange={(e) => handleMappingChange(index, 'defaultValue', e.target.value)}
                                        placeholder="Default value (optional)"
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </td>
                                <td className="py-2 px-4 border-b text-center">
                                    <input
                                        type="checkbox"
                                        checked={mapping.isRequired}
                                        onChange={(e) => handleMappingChange(index, 'isRequired', e.target.checked)}
                                        className="h-4 w-4"
                                    />
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <button
                                        onClick={() => handleRemoveMapping(index)}
                                        className="text-red-500 hover:text-red-700 p-1"
                                    >
                                        <FiX className="h-5 w-5" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mt-4 flex justify-end">
                <button
                    onClick={handleSaveMappings}
                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                >
                    Save Mappings
                </button>
            </div>
        </div>
    );
};


export default PlatformFieldMapping; 