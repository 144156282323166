import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { fulfillmentBatchJobsApi, invoicingBatchJobsApi, salarySacrificeBatchJobsApi } from '../services/apiClient';
import { firestore as db } from '../firebaseConfig';
import { onSnapshot, doc } from 'firebase/firestore';
import { isAuthenticated } from '../utils/auth';

interface JobStatus {
    jobId: string;
    status: 'INITIATED' | 'IN_PROGRESS' | 'COMPLETED' | 'ERROR';
    progress?: {
        processedCount: number;
        skippedCount: number;
        errorCount: number;
        totalCompanies: number;
    };
}

export const useBatchJobs = () => {
    const [batchJobs, setBatchJobs] = useState<any[]>([]);
    const [invoicingJobs, setInvoicingJobs] = useState<any[]>([]);
    const [salarySacrificeJobs, setSalarySacrificeJobs] = useState<any[]>([]);
    const [loadingJobs, setLoadingJobs] = useState(true);
    const [loadingInvoicingJobs, setLoadingInvoicingJobs] = useState(true);
    const [loadingSalSacJobs, setLoadingSalSacJobs] = useState(true);
    const [runningBatch, setRunningBatch] = useState(false);
    const [runningInvoicing, setRunningInvoicing] = useState(false);
    const [runningSalSac, setRunningSalSac] = useState(false);
    const [currentJobId, setCurrentJobId] = useState<string | null>(null);
    const [jobListener, setJobListener] = useState<(() => void) | null>(null);

    const fetchBatchJobs = async () => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping batch jobs fetch");
            return;
        }

        try {
            const response = await fulfillmentBatchJobsApi.get('/jobs');
            setBatchJobs(response.jobs);

            // Check for any IN_PROGRESS jobs that are less than an hour old
            const inProgressJob = response.jobs.find((job: any) => {
                if (job.status === 'IN_PROGRESS') {
                    // Check if job is less than an hour old
                    const jobTime = DateTime.fromISO(job.createdAt);
                    const nowTime = DateTime.now();
                    const diffInHours = nowTime.diff(jobTime, 'hours').hours;

                    return true;
                }
                return false;
            });

            if (inProgressJob) {
                console.log(`Found in-progress job ${inProgressJob.id}, setting up listener`);
                setRunningBatch(true);
                setCurrentJobId(inProgressJob.id);
                startJobListener(inProgressJob.id);
            }
        } catch (error) {
            console.error("Error fetching batch jobs:", error);
            toast.error("Failed to fetch batch jobs");
        } finally {
            setLoadingJobs(false);
        }
    };

    const fetchInvoicingJobs = async () => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping invoicing jobs fetch");
            return;
        }

        try {
            const response = await invoicingBatchJobsApi.get('/jobs');
            setInvoicingJobs(response.jobs);
        } catch (error) {
            console.error("Error fetching invoicing jobs:", error);
            toast.error("Failed to fetch invoicing jobs");
        } finally {
            setLoadingInvoicingJobs(false);
        }
    };

    const fetchSalarySacrificeJobs = async () => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping salary sacrifice jobs fetch");
            return;
        }

        try {
            const response = await salarySacrificeBatchJobsApi.get('/jobs');
            setSalarySacrificeJobs(response.jobs);
        } catch (error) {
            console.error("Error fetching salary sacrifice jobs:", error);
            toast.error("Failed to fetch salary sacrifice jobs");
        } finally {
            setLoadingSalSacJobs(false);
        }
    };

    const stopJobListener = () => {
        if (jobListener) {
            jobListener();
            setJobListener(null);
        }
    };

    // Clean up listener on unmount
    useEffect(() => {
        return () => {
            stopJobListener();
        };
    }, []);

    const startJobListener = (jobId: string) => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, skipping job listener setup");
            return;
        }

        stopJobListener(); // Clean up any existing listener

        const unsubscribe = onSnapshot(
            doc(db, 'fulfillmentJobs', jobId),
            (snapshot) => {
                const jobData = snapshot.data() as JobStatus;

                if (!jobData) {
                    console.error('Job not found');
                    stopJobListener();
                    setRunningBatch(false);
                    setCurrentJobId(null);
                    toast.error('Job not found');
                    return;
                }

                // Update the jobs list with the latest status
                setBatchJobs(prevJobs => {
                    const updatedJobs = [...prevJobs];
                    const jobIndex = updatedJobs.findIndex(j => j.id === jobId);

                    if (jobIndex !== -1) {
                        updatedJobs[jobIndex] = { ...updatedJobs[jobIndex], ...jobData };
                    } else {
                        updatedJobs.unshift({ id: jobId, ...jobData });
                    }

                    return updatedJobs;
                });

                // Handle job completion or error
                if (jobData.status === 'COMPLETED' || jobData.status === 'ERROR') {
                    stopJobListener();
                    setRunningBatch(false);
                    setCurrentJobId(null);

                    if (jobData.status === 'COMPLETED') {
                        toast.success('Batch job completed successfully');
                    } else {
                        toast.error('Batch job failed');
                    }

                    // Fetch full job details
                    fetchBatchJobs();
                }
            },
            (error) => {
                console.error('Error listening to job updates:', error);
                stopJobListener();
                setRunningBatch(false);
                setCurrentJobId(null);
                toast.error('Failed to listen to job updates');
            }
        );

        setJobListener(() => unsubscribe);
    };

    const runBatchJob = async () => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, cannot run batch job");
            toast.error("You must be logged in to run batch jobs");
            return;
        }

        if (!window.confirm("Are you sure you want to run the batch job?")) {
            return;
        }

        const today = DateTime.now();
        if (today.day !== 1) {
            if (!window.confirm(
                "WARNING: It's not the 1st of the month. Are you definitely sure you want to run the batch job again?"
            )) {
                return;
            }
        }

        try {
            setRunningBatch(true);
            const response = await fulfillmentBatchJobsApi.post('/disburse-all-companies');
            const { jobId } = response;

            setCurrentJobId(jobId);
            startJobListener(jobId);
            toast.info('Batch job started');
        } catch (error: any) {
            console.error("Error running batch job:", error);
            setRunningBatch(false);
            toast.error(error.response?.data?.message || "Failed to run batch job");
        }
    };

    const runInvoicingJob = async () => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, cannot run invoicing job");
            toast.error("You must be logged in to run invoicing jobs");
            return;
        }

        if (!window.confirm("Are you sure you want to run the invoicing batch job?")) {
            return;
        }

        try {
            setRunningInvoicing(true);
            await invoicingBatchJobsApi.post('/process-all-companies');
            toast.success("Invoicing batch job completed successfully");
            fetchInvoicingJobs();
        } catch (error: any) {
            console.error("Error running invoicing batch job:", error);
            toast.error(error.response?.data?.message || "Failed to run invoicing batch job");
        } finally {
            setRunningInvoicing(false);
        }
    };

    const runSalarySacrificeJob = async () => {
        if (!isAuthenticated()) {
            console.log("User not authenticated, cannot run salary sacrifice job");
            toast.error("You must be logged in to run salary sacrifice jobs");
            return;
        }

        if (!window.confirm("Are you sure you want to run the salary sacrifice batch job?")) {
            return;
        }

        const currentDate = DateTime.now();
        const month = currentDate.toFormat('MMM');
        const year = currentDate.toFormat('yyyy');

        try {
            setRunningSalSac(true);
            await salarySacrificeBatchJobsApi.post('/process-all-companies', { month, year });
            toast.success("Salary sacrifice batch job completed successfully");
            fetchSalarySacrificeJobs();
        } catch (error: any) {
            console.error("Error running salary sacrifice batch job:", error);
            toast.error(error.response?.data?.message || "Failed to run salary sacrifice batch job");
        } finally {
            setRunningSalSac(false);
        }
    };

    return {
        // State
        batchJobs,
        invoicingJobs,
        salarySacrificeJobs,
        loadingJobs,
        loadingInvoicingJobs,
        loadingSalSacJobs,
        runningBatch,
        runningInvoicing,
        runningSalSac,
        currentJobId,
        stopJobListener,

        // Methods
        fetchBatchJobs,
        fetchInvoicingJobs,
        fetchSalarySacrificeJobs,
        runBatchJob,
        runInvoicingJob,
        runSalarySacrificeJob,
    };
}; 