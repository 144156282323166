import React, { useState, useEffect, ReactElement } from "react";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
const CustomInput = React.forwardRef(function CustomInput(props, ref: any) {
    return (
        <input
            {...props}
            ref={ref}
            placeholder="Phone"
            name="phone"
            className={`p-2 border mb-1 rounded-md w-full border-[#ccc]/80 text-primary font-montserrat`}
        />
    );
});
interface ContactSectionProps {
    title: string;
    description: ReactElement;
    contacts: Array<{
        name: string;
        email: string;
        phone: string;
    }>;
    errors: Array<{
        name?: string;
        email?: string;
        phone?: string;
    }>;
    onContactChange: (index: number, field: string, value: string) => void;
    onAddContact: () => void;
    onRemoveContact: (index: number) => void;
    sectionKey: string;
    submitting: boolean;
    availableContacts: {
        hr?: Array<{ name: string; email: string; phone: string; }>;
        finance?: Array<{ name: string; email: string; phone: string; }>;
        payroll?: Array<{ name: string; email: string; phone: string; }>;
    };
    onCopyFrom: (contactData: any) => void;
}

const ContactSection: React.FC<ContactSectionProps> = ({
    title,
    description,
    contacts,
    errors,
    onContactChange,
    onAddContact,
    onRemoveContact,
    sectionKey,
    submitting,
    availableContacts,
    onCopyFrom,
}) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const copyMenus = document.querySelectorAll('[id^="copyMenu-"]');
            copyMenus.forEach((menu) => {
                if (!menu.contains(event.target as Node)) {
                    menu.classList.add('hidden');
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const hasContactData = (contact: { name: string; email: string; phone: string }) => {
        return contact.name.trim() !== '' ||
            contact.email.trim() !== '' ||
            (contact.phone && contact.phone.trim() !== '');
    };

    const getAvailableSections = () => {
        const sections = [
            { key: 'hr', name: 'HR' },
            { key: 'finance', name: 'Finance' },
            { key: 'payroll', name: 'Payroll' }
        ];
        return sections.filter(section => section.key !== sectionKey);
    };


    const getall = () => {
        const contactMap = new Map<string, {
            name: string;
            email: string;
            phone: string;
            sections: string[]
        }>();

        // Helper to add contacts to the map
        const addContactsToMap = (contacts: Array<{ name: string; email: string; phone: string; }> | undefined, sectionName: string) => {
            if (!contacts) return;

            contacts.forEach(contact => {
                if (contact.name.trim() && contact.email.trim()) {
                    if (contactMap.has(contact.email)) {
                        // Add section to existing contact
                        const existing = contactMap.get(contact.email)!;
                        if (!existing.sections.includes(sectionName)) {
                            existing.sections.push(sectionName);
                        }
                    } else {
                        // Add new contact
                        contactMap.set(contact.email, {
                            ...contact,
                            sections: [sectionName]
                        });
                    }
                }
            });
        };

        // Add contacts from each section
        if (sectionKey !== 'hr') {
            addContactsToMap(availableContacts.hr, 'HR');
        }
        if (sectionKey !== 'finance') {
            addContactsToMap(availableContacts.finance, 'Finance');
        }
        if (sectionKey !== 'payroll') {
            addContactsToMap(availableContacts.payroll, 'Payroll');
        }

        // Convert map to array and sort by name
        return Array.from(contactMap.values())
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(contact => ({
                ...contact,
                section: contact.sections.join(' & ') // Combine section names
            }));
    };

    return (
        <>
            <div>
                <h3 className="text-left">{title}</h3>
                {description}
            </div>
            <div className="relative w-full my-2">
                <button
                    className="shadow-lg flex w-full bg-blue-200/30 justify-center flex-row items-center gap-2 border-[#ccc] font-medium text-primary py-2 px-4 rounded-lg transform transition-transform duration-100 font-montserrat hover:scale-[1.02]"
                    onClick={(e) => {
                        e.stopPropagation();
                        document.querySelectorAll('[id^="copyMenu-"]').forEach(menu => {
                            menu.classList.add('hidden');
                        });
                        const menu = document.getElementById(`copyMenu-${sectionKey}`);
                        menu?.classList.toggle('hidden');
                    }}
                >
                    <img
                        src={require("../assets/plus.png")}
                        alt="Copy"
                        className="w-[18px] h-[18px]"
                    />
                    <span className="text-sm">Add existing contact</span>
                </button>
                <div
                    id={`copyMenu-${sectionKey}`}
                    className="hidden absolute left-0 right-0 mt-1 bg-white border border-gray-200 rounded-md shadow-lg z-10"
                >
                    {getall().length > 0 ? (
                        getall().map((contact, idx) => (
                            <button
                                key={idx}
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex justify-between items-center"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCopyFrom(contact);
                                    document.getElementById(`copyMenu-${sectionKey}`)?.classList.add('hidden');
                                }}
                            >
                                <span>{contact.name}</span>
                                <span className="text-xs text-gray-500">{contact.section}</span>
                            </button>
                        ))
                    ) : (
                        <div className="px-4 py-2 text-sm text-gray-500">
                            No contacts available to copy
                        </div>
                    )}
                </div>
            </div>

            {contacts.map((contact, index) => (
                <div className="border border-[#ccc] w-full p-3 pt-1 rounded-lg mt-2 relative" key={`${sectionKey}${index}`}>
                    <div className="flex flex-row justify-between items-center pr-2">
                        <h4 className="text-left">{title.split(" ")[0]} Person {index + 1}</h4>

                        <div className="flex items-center gap-2">
                            {(index > 0 || contacts.length > 1) && (
                                <img
                                    className="ml-2 w-5 h-5 cursor-pointer"
                                    src={require("../assets/trash.png")}
                                    onClick={() => onRemoveContact(index)}
                                />
                            )}
                        </div>
                    </div>


                    <input
                        type="text"
                        name="contactPerson"
                        placeholder="Full Name"
                        value={contact.name}
                        onChange={(e) => onContactChange(index, "name", e.target.value)}
                        required
                        className={`p-2 border mb-1 rounded-md w-full ${errors[index]?.name ? "border-red bg-red/10" : "border-[#ccc]"}`}
                    />
                    {errors[index]?.name && (
                        <span className="text-red text-left w-full">{errors[index]?.name}</span>
                    )}


                    <input
                        type="text"
                        name="email"
                        placeholder="Email address"
                        value={contact.email}
                        onChange={(e) => onContactChange(index, "email", e.target.value)}
                        required
                        className={`p-2 border mb-1 rounded-md w-full ${errors[index]?.email ? "border-red bg-red/10" : "border-[#ccc]"}`}
                    />
                    {errors[index]?.email && (
                        <span className="text-red text-left w-full">{errors[index]?.email}</span>
                    )}

                    <div className="w-full">

                        <PhoneInput
                            name="contactNumber"
                            defaultCountry={"GB"}
                            className="w-full flex"
                            value={contact.phone}
                            required
                            onChange={(value) => onContactChange(index, "phone", value || "")}
                            inputComponent={CustomInput}
                        />
                        {errors[index]?.phone && (
                            <span className="text-red text-left w-full">{errors[index]?.phone}</span>
                        )}
                    </div>
                </div>
            ))}

            <div className="flex flex-col w-full gap-2 mt-2">

                <button
                    onClick={onAddContact}
                    className="shadow-lg text-sm flex w-full bg-secondary/30 justify-center flex-row items-center gap-2 border-[#ccc] font-medium text-primary py-2 px-4 rounded-lg transform transition-transform duration-100 font-montserrat hover:scale-[1.02]"
                    disabled={submitting}
                >
                    <img
                        src={require("../assets/plus.png")}
                        alt={"CycleSaver.co.uk partners"}
                        className={"w-[18px] h-[18px] object-contain rounded-xl"}
                    />
                    Add a new {title.split(" ")[0]} person
                </button>


            </div>
        </>
    );
};

export default ContactSection; 