import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlatforms, useDeletePlatform } from '../../hooks/usePlatforms';
import { Platform } from '../../types/platform';
import { FiEdit, FiTrash2, FiPlus, FiSearch, FiDownload } from 'react-icons/fi';
import DynamicTable from '../../components/DynamicTable/DynamicTable';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

const Platforms: React.FC = () => {
    const navigate = useNavigate();
    const { data: platforms, isLoading, error } = usePlatforms();
    const deletePlatformMutation = useDeletePlatform();
    const [searchTerm, setSearchTerm] = useState('');
    const [downloadingId, setDownloadingId] = useState<string | null>(null);

    const handleEdit = (id: string) => {
        navigate(`/platforms/edit/${id}`);
    };

    const handleCreate = () => {
        navigate('/platforms/create');
    };

    const handleDelete = (id: string, name: string) => {
        if (window.confirm("Are you sure you want to delete this provider?")) {
            deletePlatformMutation.mutate(id);
        }
    };

    const handleDownload = async (e: React.MouseEvent, id: string) => {
        e.stopPropagation();
        try {
            setDownloadingId(id);

            // Get API URL
            const apiUrl = process.env.REACT_APP_FUNCTIONS_URL;
            const downloadUrl = `${apiUrl}/api/platforms/get-files/${id}`;

            // Get the auth token from localStorage
            const token = localStorage.getItem('sessionToken');

            // Create a fetch request with proper headers
            const response = await fetch(downloadUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }

            // Get the filename from the Content-Disposition header if available
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = `${id}-${DateTime.now().toISODate()}.zip`;
            if (contentDisposition && contentDisposition.includes('filename=')) {
                const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1];
                }
            }

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a link and trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            toast.success('Files downloaded successfully');
        } catch (error) {
            toast.error(`Failed to download files: ${error instanceof Error ? error.message : 'Unknown error'}`);
            console.error('Download error:', error);
        } finally {
            setDownloadingId(null);
        }
    };

    // Columns for the DynamicTable
    const columns = ["ID", "Name", "SFTP Enabled", "Contacts", "Actions"];

    // Transform platform data to rows for DynamicTable
    const rows = platforms?.map((provider: Platform) => [
        provider.id,
        provider.name,
        provider.sftp?.enabled ? (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                Enabled
            </span>
        ) : (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                Disabled
            </span>
        ),
        provider.contacts?.length || 0,
        <div className="flex space-x-2">
            {/* Edit button removed - clicking on row serves this purpose */}
            {/* Delete button removed - will be added to the form page instead */}
            {provider.sftp?.enabled && (
                <button
                    onClick={(e) => handleDownload(e, provider.id)}
                    disabled={downloadingId === provider.id}
                    className="text-blue-600 hover:text-blue-900 bg-transparent shadow-none flex items-center"
                >
                    <FiDownload className="mr-1" />
                    {downloadingId === provider.id ? 'Downloading...' : 'Download Files'}
                </button>
            )}
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/platforms/${provider.id}/companies`);
                }}
                className="text-green-600 hover:text-green-900 bg-transparent shadow-none flex items-center"
            >
                <FiSearch className="mr-1" /> View Companies
            </button>
        </div>
    ]) || [];

    // Handle row click to navigate to edit page
    const handleRowClick = (id: string) => {
        navigate(`/platforms/edit/${id}`);
    };

    if (error) {
        return <div className="text-red-500">Error loading platforms: {(error as Error).message}</div>;
    }

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1>Benefit Platforms</h1>
                <button
                    onClick={handleCreate}
                    className="bg-primary text-white px-4 py-2 rounded-md flex items-center"
                >
                    <FiPlus className="mr-2" /> Add New Platform
                </button>
            </div>

            <div className="mb-4 flex">
                <div className="relative flex-1">
                    <input
                        type="text"
                        placeholder="Search platforms..."
                        className="w-full p-2 pl-10 border border-gray-300 rounded-md"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <FiSearch className="absolute left-3 top-3 text-gray-400" />
                </div>
            </div>

            {/* DynamicTable for platforms */}
            <DynamicTable
                tableId="platforms-table"
                heading=""  // Remove heading as we already have one
                cols={columns}
                rows={rows}
                onRowClicked={handleRowClick}
                loading={isLoading}
                idIndexInColumns={0}
                showID={true}
                searchKeys={["ID", "Name"]}
                searchQueryInput={searchTerm}
                setSearchQueryInput={setSearchTerm}
                filterableColumns={["SFTP Enabled", "Contacts"]}
                showExport={true}
                showSearchBox={false}  // We use our custom search box above
            />
        </div>
    );
};

export default Platforms; 