const mapApplicationStatus = (status: string): string => {
  switch (status) {
    case "PROCESSING":
      return "Processing";
    case "AWAITING_EMPLOYEE_SIGNATURE":
      return "Awaiting Employee Signature";
    case "AWAITING_EMPLOYER_SIGNATURE":
      return "Awaiting Employer Signature";
    case "AWAITING_ACTIVATION":
      return "Awaiting Activation";
    case "ACTIVE":
      return "Active";
    case "COMPLETED":
      return "Completed";
    case "CANCELLED":
      return "Cancelled";
    case "PENDING_PRODUCT_CHANGE":
      return "Pending Product Change";
    default:
      return status;
  }
};

const mapDebitOrderStatus = (status: string): string => {
  switch (status) {
    case "fulfilled":
      return "Fulfilled";
    case "pending":
      return "Pending";

    default:
      return status;
  }
};

const mapCompanyNextStatus = (status: string): string => {
  switch (status) {
    case "CREATED":
      return "Add Payment Details";
    case "PAYMENT_REQUEST_SENT":
      return "Add Payment Details";
    case "MANDATE_COMPLETED":
      return "Awaiting Employer Signature";

    default:
      return status;
  }
};

const mapCompanyStatus = (status: string): string => {
  switch (status) {
    case "CREATED":
      return "Created";
    case "PAYMENT_REQUEST_SENT":
      return "Awaiting Employee Signature";
    case "MANDATE_COMPLETED":
      return "Awaiting Employer Signature";

    default:
      return status;
  }
};

const getStatusStyles = (status: string): string => {
  const styleMap: Record<string, string> = {
    PROCESSING: "bg-amber-100 border-amber-400",
    ACTIVE: "bg-green-100 border-green-400",
    COMPLETED: "bg-indigo-100 border-indigo-500",
    INFORMATION_REQUIRED: "bg-yellow-100 border-yellow-400",
    AWAITING_EMPLOYER_SIGNATURE: "bg-blue-100 border-blue-400",
    DECLINED: "bg-red/30 border-red",
    AWAITING_ACTIVATION: "bg-purple-100 border-purple-400",
    CANCELLED: "bg-red/30 border-red",
    PAUSED: "bg-cyan-100 border-cyan-400",

    PENDING_PRODUCT_CHANGE: "bg-fuchsia-100 border-fuchsia-400"
  };

  return styleMap[status] || "";
};

const APPLICATIONS_CACHE_KEY = 'cachedApplications';
const APPLICATIONS_CACHE_TIMESTAMP_KEY = 'applicationsTimestamp';

export { mapApplicationStatus, mapDebitOrderStatus, mapCompanyNextStatus, mapCompanyStatus, getStatusStyles, APPLICATIONS_CACHE_KEY, APPLICATIONS_CACHE_TIMESTAMP_KEY };

