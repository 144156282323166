// src/hooks/useAPI.js

import { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Make sure you've installed react-router-dom

import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import Papa from "papaparse";
import { saveAs } from "file-saver";

import {
  dashboardApi,
  invoicingApi,
  userApi,
  applicationApi,
  fulfillmentApi,
  sftpApi,
  paymentApi,
  voucherApi,
  salarySacrificeApi
} from "../services/apiClient";
import React from "react";
import { useApplications } from './useApplications';
import { useCompanies } from './useCompanies';
import { useQueryClient } from '@tanstack/react-query';
import { ApiClient } from "../services/apiClient";


// Define interfaces for the filter types and API responses
interface DateRange {
  start: DateTime | null;
  end: DateTime | null;
}

interface ApplicationFilters {
  dateRange?: DateRange;
  status?: string[];
  [key: string]: any;
}

interface CompanyFilters {
  archived?: boolean;
  [key: string]: any;
}

interface PaginatedResponse {
  data: any[];
  nextCursor: string | null;
  hasNextPage: boolean;
}

export const useAPI = () => {

  const {
    applicationsQuery,
    flattenedApplications,
    usePaginatedApplications,
    selectedApplication,
    setSelectedApplication,
    getApplication,
    fetchAndSetApplication,
    resendInformationRequiredEmployeeEmail
  } = useApplications();

  const {
    companiesQuery,
    flattenedCompanies,
    usePaginatedCompanies,
    selectedCompany,
    setSelectedCompany,
    getCompanyDetails,
    setCompanyDetailsFromResponse,
    companyDetails,
    companyApplications,
    companyPendingInvoiceItems,
    companySalarySacrificeRecurringItems,
    companyXeroConfiguration,

    gettingCompanyDetails,
    gettingCompanies,
    allApplicationsError,
    getCompanyNamesAndIDs,
    companyNamesAndIDs
  } = useCompanies();

  const navigate = useNavigate();
  const [dashboardError, setAllApplicationsError] = useState(false);
  const [companiesError, setCompaniesError] = useState(false);
  const [companies, setCompanies] = useState<any>();
  const [runningAllApplicationsQuery, setRunningAllApplicationsQuery] = useState(true);
  const [runningApplicationExportQuery, setRunningApplicationExportQuery] = useState(false);
  const [runningInvoicingQuery, setRunningInvoicingQuery] = useState(false);
  const [approvingApplication, setApprovingApplication] = useState(false);
  const [dashboardFilterStartDate, setAllApplicationsFilterStartDate] = useState<DateTime | null>(null);
  const [dashboardFilterEndDate, setAllApplicationsFilterEndDate] = useState<DateTime | null>(null);

  useEffect(() => {
    if (selectedApplication) {
      setSelectedCompany(null)
      const company = flattenedCompanies?.find((c: any) => c.companyID === selectedApplication.company.companyID);

      if (company) {
        setSelectedCompany(company);
      } else {
        console.log("Company not found in flattened list, fetching details");
        // If company not found in flattened list, fetch details
        getCompanyDetails(selectedApplication.company.companyID);
      }
    }
  }, [selectedApplication]);

  // This is the function that is used to view an application
  const viewApplication = (app: any) => {
    setSelectedApplication(app);
    // const comp = companies?.find((c: any) => c.companyID == app.company.companyID);
    // setSelectedCompany(comp);
    // localStorage.setItem("selectedApplication", JSON.stringify(app));
    navigate(`/application/${app.id}`);
  };

  const getApplicationsExport = async (type: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await dashboardApi.post('/applications-export',
        { type },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Applications_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting applications:', error);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };


  const getMarketingContactsExport = async (type: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await userApi.post('/get-marketing-contacts',
        { type },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Applicants_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error exporting marketing contacts:', error);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const resendCommunication = async (communicationId: string) => {
    try {
      if (!window.confirm("Are you sure you want to resend this communication?")) {
        return;
      }
      setRunningApplicationExportQuery(true);
      const response = await dashboardApi.post('/resend-communication', {
        communicationId
      });

      if (response.success === false) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
      }

    } catch (error: any) {
      console.error('Error resending communication:', error);
      toast.error("Something went wrong while resending the communication: " + error.message);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const getAllContactsExport = async (type: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await userApi.post('/get-all-contacts',
        { type },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `AllContacts_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error exporting all contacts:', error);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const getSFTPFileForCompany = async () => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await sftpApi.post('/download',
        { companyID: selectedCompany.companyID },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedCompany.companyID}_sftpexport_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error downloading SFTP file:', error);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const getActivationsLogHistory = async (type: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await dashboardApi.post('/activations-log-export',
        {
          startDate: dashboardFilterStartDate?.toFormat("yyyy-MM-dd"),
          endDate: dashboardFilterEndDate?.toFormat("yyyy-MM-dd"),
        },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Activations_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error exporting activations log:', error);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const getCurrentSalarySacrificeReport = async (companyID: string, month?: string, year?: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await salarySacrificeApi.post('/current',
        {
          companyID,
          month: month ?? null,
          year: year ?? null,
        },
        { responseType: 'blob' }
      );

      if (response.success === false) {
        toast(response.message);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        month && year
          ? `${companyID}_SALSAC_${month.toUpperCase()}_${year}.csv`
          : `${companyID}_SALSAC_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      getCompanyDetails(companyID);
    } catch (error) {
      console.error('Error getting salary sacrifice report:', error);
      toast("A report has already been issued for this month.");
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const sendSpecificSalarySacrificeReport = async (companyID: string, month?: string, year?: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await salarySacrificeApi.post('/send', {
        companyID,
        month: month ?? null,
        year: year ?? null,
      });

      if (response.success === false) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
      }

      getCompanyDetails(companyID);
    } catch (error) {
      console.error('Error sending salary sacrifice report:', error);
      toast.error("Something went wrong while sending the report.");
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };


  const getPastSalarySacrificeReport = async (companyID: string, reportID: string) => {
    try {
      setRunningApplicationExportQuery(true);
      const response = await salarySacrificeApi.post('/past',
        {
          companyID,
          reportID,
        },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedCompany.name} Salary Sacrifice Report ${reportID}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error getting past salary sacrifice report:', error);
    } finally {
      setRunningApplicationExportQuery(false);
    }
  };

  const cancelApplication = async (applicationID: string) => {
    if (!selectedApplication) {
      console.log("Can only perform this function when an application is being viewed.");
      return;
    }

    try {
      setRunningAllApplicationsQuery(true);
      const response = await applicationApi.post('/cancel', { applicationID });
      return response;
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error canceling application:', error);
      return Promise.reject(error);
    } finally {
      setRunningAllApplicationsQuery(false);
    }
  };

  const resendNewApplicationEmails = async () => {
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    setRunningAllApplicationsQuery(true);
    try {
      const response = await dashboardApi.post('/resend-new-application-emails', {
        applicationID: selectedApplication?.id,
      });

      toast.success("Application Emails resent successfully!");
      return Promise.resolve();
    } catch (error) {
      setAllApplicationsError(true);
      console.log(error);
      return Promise.reject();
    } finally {
      setRunningAllApplicationsQuery(false);
    }
  };

  const resendHRApprovalEmail = async () => {
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    setRunningAllApplicationsQuery(true);
    try {
      const response = await dashboardApi.post('/resend-hr-approval-email', {
        applicationID: selectedApplication?.id,
      });

      toast.success("New application email sent to the company successfully.");
    } catch (error) {
      setAllApplicationsError(true);
      console.error('Error resending HR approval email:', error);
    } finally {
      setRunningAllApplicationsQuery(false);
    }
  };

  const disburseApplication = async () => {
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    const confirmed = window.confirm(
      selectedApplication?.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ?
        `This will send out a voucher to ${selectedApplication?.employee.firstName + ` ` + selectedApplication.employee.surname}. Are you sure you want to continue?`
        : `This will mark the application as COMPLETED. Are you sure you want to continue?`
    );

    if (confirmed) {
      setRunningAllApplicationsQuery(true);
      try {
        const response = await fulfillmentApi.post('/disburse', {
          applicationID: selectedApplication?.id,
        });

        console.log("RESPONSE from disburseApplication", response)

        toast.success(selectedApplication?.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ?
          `The voucher was sent!`
          : `The application has been marked as completed!`);
      } catch (error) {
        console.log(error)
        setAllApplicationsError(true);
        toast.error(selectedApplication?.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ?
          `The voucher could not be sent`
          : `The application could not be marked as completed`);
      } finally {
        setRunningAllApplicationsQuery(false);
      }
    }
  };

  const addCompanyActivityHistory = async (activityItem: any) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setApprovingApplication(true);
    try {
      await dashboardApi.post('/add-company-activity-log', {
        companyID: selectedCompany.companyID,
        activityLog: activityItem
      });
      // setSelectedCompany(response.data.company)
      // setCompanies(companies.map((c: any) => {
      //   if (c.companyID == selectedCompany.companyID) {
      //     return response.data.company
      //   } else return c
      // }))
      toast("Note created.");
    } catch (error) {
      setAllApplicationsError(true);
      toast.error("Something went wrong while creating the note.");
    } finally {
      setApprovingApplication(false);
    }

  };

  const sendRequestToAddPaymentDetails = async (companyID: any) => {
    try {
      const response = await dashboardApi.post('/send-payment-details-request', {
        companyID: companyID,
      });

      if (response.success) {
        toast.success("Request sent successfully!");
        // updateSelectedCompany(response.updatedCompany);
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
      console.error(error);
      return Promise.reject();
    }
  };

  const sendUnregisteredCompanyOnboardingInstructions = async (companyID: any) => {
    try {
      const response = await dashboardApi.post('/send-onboarding-instructions', {
        companyID: companyID,
      });

      if (response.success) {
        toast.success("Onboarding email sent to the company successfully!");
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
      console.error(error);
      return Promise.reject();
    }
  };

  const createContactInXero = async (companyID: any) => {
    try {
      const response = await dashboardApi.post('/create-xero-contact', {
        companyID: companyID,
      });

      if (response.success) {
        setCompanyDetailsFromResponse(response);
        toast.success("Company created in Xero!");
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Something went wrong with this request but you should try again, it usually works the second time around lol.",
        { autoClose: 15000 });
      console.error(error);
    }
  };

  const issueInvoiceForCompany = async (companyID: any) => {
    setRunningInvoicingQuery(true);
    try {
      const response = await invoicingApi.post('/issue', {
        companyID: companyID,
      });

      if (response.success) {
        toast.success("Invoice Issued! NB: Make sure to match the Xero contact with the GoCardless mandate before sending the invoice through Xero.",
          { autoClose: 15000 });
        getCompanyDetails(companyID);
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
      console.error(error);
      return Promise.reject();
    } finally {
      setRunningInvoicingQuery(false);
    }
  };

  const setCompanyToManualPayment = async (companyID: any) => {
    setRunningInvoicingQuery(true);
    try {
      const response = await paymentApi.post('/manual', {
        companyID: companyID,
      });

      if (response.success) {
        toast.success("Company set to Manual Payment and activated - activated email sent to company.");
        // setTimeout(() => { getCompanyDetails(companyID) }, 3000);
        return Promise.resolve();
      } else {
        toast.error("Failed to set company to manual payment: " + response.message);
        return Promise.reject();
      }
    } catch (error: any) {
      toast.error("Failed to set company to manual payment: " + error.message);
      console.error(error);
      throw error
    } finally {
      setRunningInvoicingQuery(false);
    }
  };

  const disburseProductsForApplicationsByCompanyID = async (companyID: any) => {
    setRunningInvoicingQuery(true);
    try {
      const response = await voucherApi.post('/disburse-by-company', {
        companyID: companyID,
      });

      if (response.success) {
        toast.success("Applications disbursed!");
        response.data.csvsToGenerate.forEach(({ benefitID, emails }: any) => {
          const csvContent = Papa.unparse(emails.map((email: any) => [email]));
          const filename = `${benefitID}_${DateTime.now().toISO()}.csv`;
          const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
          saveAs(blob, filename);
        });
        getCompanyDetails(companyID);
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
      console.error(error);
      return Promise.reject();
    } finally {
      setRunningInvoicingQuery(false);
    }
  };

  const uploadCSVApplicationsForCompany = async (companyID: any, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("companyID", companyID);

    try {
      const response = await dashboardApi.post('/upload-csv-applications', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.success) {
        getCompanyDetails(companyID);
        return response;
      } else {
        return Promise.reject(new Error("Processing failed"));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };


  const archiveCompany = async (companyID: string) => {
    try {
      const response = await dashboardApi.post('/archive-company', {
        companyID,
        archiveReason: "Archived via dashboard"
      });

      if (response.success) {
        toast.success("Company archived successfully");
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Error archiving company");
      console.error(error);
      return Promise.reject(error);
    }
  };

  const reassignApplication = async (applicationId: string, newCompanyId: string) => {
    try {
      const response = await dashboardApi.post('/reassign-application', {
        applicationId,
        newCompanyId,
      });

      if (response.success) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Failed to reassign application"));
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateCompanyDetails = async (company: any) => {
    try {
      const response = await dashboardApi.post('/update-company-details', company);

      if (response.success) {
        setSelectedCompany(response.company);
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Failed to update company details"));
      }
    } catch (error) {
      toast.error('Failed to update company details');
      console.error(error);
      return Promise.reject(error);
    }
  };

  // Add these new functions for paginated data fetching with proper typing
  const fetchPaginatedApplications = async ({
    pageParam = null,
    filters = {} as ApplicationFilters
  }: {
    pageParam?: string | null;
    filters?: ApplicationFilters
  }): Promise<PaginatedResponse> => {
    try {
      const response = await dashboardApi.post('/paginated-data', {
        cursor: pageParam,
        pageSize: 100,
        dataType: 'applications',
        filters: {
          dateRange: filters.dateRange ? {
            start: filters.dateRange.start?.toISO?.(),
            end: filters.dateRange.end?.toISO?.()
          } : undefined,
          status: filters.status
        },
        tableId: 'AllApplications'
      });

      // Transform the response to match the expected PaginatedResponse structure
      return {
        data: response.applications.data,
        nextCursor: response.applications.nextCursor,
        hasNextPage: response.applications.hasNextPage
      };
    } catch (error) {
      console.error('Error fetching paginated applications:', error);
      throw error;
    }
  };

  const fetchPaginatedCompanies = async ({
    pageParam = null,
    filters = {} as CompanyFilters
  }: {
    pageParam?: string | null;
    filters?: CompanyFilters
  }): Promise<PaginatedResponse> => {
    try {
      const response = await dashboardApi.post('/paginated-data', {
        cursor: pageParam,
        pageSize: 50,
        dataType: 'companies',
        filters: {
        },
        tableId: 'CompaniesTable'
      });
      console.log("Companies RESPONSE", response)
      // Transform the response to match the expected PaginatedResponse structure
      return {
        data: response.companies.data,
        nextCursor: response.companies.nextCursor,
        hasNextPage: response.companies.hasNextPage
      };
    } catch (error) {
      console.error('Error fetching paginated companies:', error);
      throw error;
    }
  };


  return {
    runningAllApplicationsQuery,
    setRunningAllApplicationsQuery,
    runningApplicationExportQuery,
    setRunningApplicationExportQuery,
    dashboardError,
    setAllApplicationsError,
    viewApplication,
    dashboardFilterEndDate,
    dashboardFilterStartDate,
    setAllApplicationsFilterEndDate,
    setAllApplicationsFilterStartDate,
    approvingApplication,
    setApprovingApplication,
    resendNewApplicationEmails,
    resendHRApprovalEmail,
    companies,
    companiesError,
    setCompanies,
    setCompaniesError,
    setSelectedCompany,
    selectedCompany,
    gettingCompanies,
    getCompanyDetails,
    companyDetails,
    companyApplications,
    companyPendingInvoiceItems,
    sendRequestToAddPaymentDetails,
    companySalarySacrificeRecurringItems,
    companyXeroConfiguration,
    getApplicationsExport,
    getMarketingContactsExport,
    getAllContactsExport,
    getCurrentSalarySacrificeReport,
    getPastSalarySacrificeReport,
    createContactInXero,
    issueInvoiceForCompany,
    runningInvoicingQuery,
    setRunningInvoicingQuery,
    uploadCSVApplicationsForCompany,
    disburseProductsForApplicationsByCompanyID,
    cancelApplication,
    disburseApplication,
    addCompanyActivityHistory,
    getActivationsLogHistory,
    getSFTPFileForCompany,
    sendSpecificSalarySacrificeReport,
    setCompanyToManualPayment,
    archiveCompany,
    reassignApplication,
    updateCompanyDetails,
    setSelectedApplication,
    selectedApplication,
    sendUnregisteredCompanyOnboardingInstructions,
    fetchPaginatedApplications,
    fetchPaginatedCompanies,
    usePaginatedApplications,
    usePaginatedCompanies,

    applicationsQuery,
    flattenedApplications,

    companiesQuery,
    flattenedCompanies,
    getApplication,
    fetchAndSetApplication,
    resendInformationRequiredEmployeeEmail,
    getCompanyNamesAndIDs,
    companyNamesAndIDs,

    resendCommunication
  };
};
