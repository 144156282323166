import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
import { useUser } from "../../context/AppContext";
import ResponsiveAppBar from "../../components/Navbar";
import { toast } from "react-toastify";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { getStatusStyles } from "../../utils/util";
import ApplicationStatusComponent from "../../components/ApplicationStatusComponent";
const AllApplications = () => {

  const [sortedData, setSortedData] = useState<any[]>([]);

  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" | undefined }>({
    key: "",
    direction: undefined,
  });

  const {
    runningAllApplicationsQuery,
    viewApplication,
    getApplicationsExport,
    getActivationsLogHistory,
    runningApplicationExportQuery,
    getMarketingContactsExport,
    dashboardFilterEndDate,
    dashboardFilterStartDate,
    getAllContactsExport,
    usePaginatedApplications
  } = useUser();

  const [initialising, setInitialising] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [filters, setFilters] = useState({
    dateRange: {
      start: dashboardFilterStartDate,
      end: dashboardFilterEndDate
    },
    status: []
  });

  const {
    data: paginatedApplicationsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: loadingPaginatedApplications,
  } = usePaginatedApplications(filters);

  const flattenedApplications = React.useMemo(() => {
    if (!paginatedApplicationsData?.pages) return [];
    return paginatedApplicationsData.pages.flatMap((page: any) => {
      // Check if the data is nested under a 'data' property
      const pageData = page.data || page;
      return Array.isArray(pageData) ? pageData : [];
    });
  }, [paginatedApplicationsData]);

  const handleFilterChange = (newFilters: any) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters
    }));
  };

  useEffect(() => {
    setSortConfig({ key: "creationDateTime", direction: "desc" });
    requestSort("creationDateTime");
  }, [flattenedApplications]);

  const requestSort = (key: string | null) => {
    if (key && flattenedApplications) {
      let direction: "asc" | "desc" = "asc";
      if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
      const sorted = [...flattenedApplications].sort((a, b) => {
        const valueA = getValue(a, key)?.toString()?.toLowerCase();
        const valueB = getValue(b, key)?.toString()?.toLowerCase();
        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sorted);
    }
  };

  const getValue = (object: any, path: string) => {
    const keys = path.split(".");
    let value = object;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return undefined;
      }
    }
    return value;
  };



  useEffect(() => {
    document.title = (process.env.REACT_APP_ENV == "DEVELOPMENT" ? "DEV " : "") + "Admin | All Applications";
  }, []);

  return (
    <div className="min-h-screen flex-col  bg-white">
      <div className=" flex w-full min-h-screen bg-white ">
        {/* <Sidebar /> */}
        <div className="flex flex-col px-3 py-3 md:p-6 w-full ">
          <h2 className="ml-2">APPLICATIONS</h2>

          <div className="flex flex-col border border-lightgrey rounded-lg p-2 gap-2 my-2">

            <div className="flex flex-col w-full md:flex-row  items-start rounded-md gap-2 ">
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getApplicationsExport("VOUCHER");
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export all applications</p>
              </button>
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getActivationsLogHistory();
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export Activation Log</p>

              </button>
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getMarketingContactsExport();
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export Applicant Details</p>

              </button>
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getAllContactsExport();
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export All Contacts</p>

              </button>
            </div>

          </div>
          <DynamicTable
            cols={["Date", "Status", "ID", "Benefit", "Employee", "Company"]}
            filterableColumns={["Date", "Status", "Benefit", "Company"]}
            showExport={true}
            tableId="AllApplications"
            rows={flattenedApplications?.map((application: any) => [
              <p>{application.creationDateTime}</p>,
              <ApplicationStatusComponent application={application} />,

              <p>{application.id}</p>,
              <div className="flex flex-row items-center justify-start gap-3 mx-1">
                <img src={application.benefit.imageURL} className="w-8 h-8 rounded-md" />
                <p>{application.benefit.name}</p>
              </div>,

              <div>
                <p>{application.employee.firstName} {application.employee.surname}</p>
                {application.referrerDetails?.referrerFirstName && <p className="text-xs text-gray-500">Referred By: {application.referrerDetails?.referrerFirstName} - {application.referrerDetails?.userCode}</p>}
              </div>,
              , <div className="flex flex-col">
                <p>{application.company.name}</p>
                {application.companyAutomaticallyReassigned &&
                  <div className="flex flex-row">
                    <img src="/exchange.png" className="w-4 h-4" />
                    <p className="pt-1">{application.archivedCompanyID}</p>
                  </div>
                }
              </div>,
              // <div className="flex flex-col">
              //   <p>{application.referrerDetails?.referrerFirstName} - {application.referrerDetails?.userCode}</p>

              // </div>,
            ])}
            onRowClicked={(applicationID: string) => {
              const application = flattenedApplications?.find((a: any) => a.id === applicationID);
              if (application) {
                viewApplication(application);
              }
            }}
            loading={loadingPaginatedApplications}
            idIndexInColumns={2}
            showID={true}
            rowExpansionComponents={flattenedApplications?.map((application: any) => {
              return <div className="flex flex-row flex-wrap gap-2 p-2">
                <div className=" p-2">
                  <p className="font-semibold text-sm">Employee</p>
                  <p className="text-sm">{`${application.employee.firstName} ${application.employee.surname}`}</p>
                  <div
                    className="flex flex-row gap-3"
                    onClick={() => {
                      navigator.clipboard.writeText(application?.employee.email);
                      toast("Copied!", { autoClose: 1000, type: "info" });
                    }}
                  >
                    <p className="text-sm">{application?.employee.email}</p>
                    <img
                      src={require("../../assets/copy.png")}
                      className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                    />
                  </div>
                  <div
                    className="flex flex-row gap-3"
                    onClick={() => {
                      navigator.clipboard.writeText(application?.employee.phone);
                      toast("Copied!", { autoClose: 1000, type: "info" });
                    }}
                  >
                    <p className="text-sm">
                      {" "}
                      <a href={`tel:${application?.employee.phone}`}>{application?.employee.phone}</a>{" "}
                    </p>
                    <img
                      src={require("../../assets/copy.png")}
                      className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                    />
                  </div>
                </div>

                <div className=" p-1">
                  <p className="font-semibold text-sm">Salary Sacrifice Terms</p>
                  {application.salarySacrificeTerms?.numberOfSacrifices > 0 ? <p className="">{application.salarySacrificeTerms?.numberOfSacrifices} months</p> : <p>Ongoing until cancelled</p>}

                </div>
                {application.activityLog && application.activityLog.length > 0 &&
                  <div className=" p-1 m-0">
                    <p className="font-semibold text-sm">Latest activity</p>
                    <p className="text-sm">{application.activityLog[application.activityLog.length - 1].action}</p>

                  </div>
                }

              </div>
            })}
            pagination={true}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      </div>
    </div >
  );

};

export default AllApplications;
