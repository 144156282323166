import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import ContactSection from './ContactSection';

interface EditContactsModalProps {
    open: boolean;
    onClose: () => void;
    company: any;
    onSave: (updatedCompany: any) => Promise<void>;
}

const EditContactsModal: React.FC<EditContactsModalProps> = ({
    open,
    onClose,
    company,
    onSave,
}) => {
    const [formData, setFormData] = useState({
        hrContacts: company.hrContacts || [{ name: "", email: "", phone: "" }],
        financeContacts: company.financeContacts || [{ name: "", email: "", phone: "" }],
        payrollContacts: company.payrollContacts || [{ name: "", email: "", phone: "" }],
    });

    const [formErrors, setFormErrors] = useState({
        hrContacts: [{ name: "", email: "", phone: "" }],
        financeContacts: [{ name: "", email: "", phone: "" }],
        payrollContacts: [{ name: "", email: "", phone: "" }],
    });

    const [saving, setSaving] = useState(false);

    const handleSave = async () => {
        setSaving(true);
        try {
            await onSave({
                ...company,
                hrContacts: formData.hrContacts,
                financeContacts: formData.financeContacts,
                payrollContacts: formData.payrollContacts,
            });
            onClose();
        } catch (error) {
            console.error('Error saving contacts:', error);
        } finally {
            setSaving(false);
        }
    };

    const handleCopyFrom = (sectionKey: string, contactData: any) => {
        const updatedFormData = { ...formData };

        // If there's only one empty contact in the target section, replace it
        // Otherwise, append the new contact
        if (sectionKey === 'hr') {
            if (updatedFormData.hrContacts.length === 1 &&
                !updatedFormData.hrContacts[0].name &&
                !updatedFormData.hrContacts[0].email &&
                !updatedFormData.hrContacts[0].phone) {
                updatedFormData.hrContacts = [{ ...contactData }];
            } else {
                updatedFormData.hrContacts = [...updatedFormData.hrContacts, { ...contactData }];
            }
        } else if (sectionKey === 'finance') {
            if (updatedFormData.financeContacts.length === 1 &&
                !updatedFormData.financeContacts[0].name &&
                !updatedFormData.financeContacts[0].email &&
                !updatedFormData.financeContacts[0].phone) {
                updatedFormData.financeContacts = [{ ...contactData }];
            } else {
                updatedFormData.financeContacts = [...updatedFormData.financeContacts, { ...contactData }];
            }
        } else if (sectionKey === 'payroll') {
            if (updatedFormData.payrollContacts.length === 1 &&
                !updatedFormData.payrollContacts[0].name &&
                !updatedFormData.payrollContacts[0].email &&
                !updatedFormData.payrollContacts[0].phone) {
                updatedFormData.payrollContacts = [{ ...contactData }];
            } else {
                updatedFormData.payrollContacts = [...updatedFormData.payrollContacts, { ...contactData }];
            }
        }

        setFormData(updatedFormData);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <div className="p-6">
                <h2 className="text-2xl mb-4">Edit Company Contacts</h2>

                <ContactSection
                    title="HR Contact Details"
                    description={<p className="text-sm text-primary italic">Key contact(s) that will <strong>review and approve employee applications</strong></p>}
                    contacts={formData.hrContacts}
                    errors={formErrors.hrContacts}
                    onContactChange={(index, field, value) => {
                        const updatedContacts = [...formData.hrContacts];
                        updatedContacts[index] = { ...updatedContacts[index], [field]: value };
                        setFormData({ ...formData, hrContacts: updatedContacts });
                    }}
                    onAddContact={() => {
                        setFormData({
                            ...formData,
                            hrContacts: [...formData.hrContacts, { name: "", email: "", phone: "" }],
                        });
                    }}
                    onRemoveContact={(index) => {
                        setFormData({
                            ...formData,
                            hrContacts: formData.hrContacts.filter((_: any, i: number) => i !== index),
                        });
                    }}
                    submitting={saving}
                    sectionKey="hr"
                    availableContacts={{
                        finance: formData.financeContacts,
                        payroll: formData.payrollContacts
                    }}
                    onCopyFrom={(contact) => handleCopyFrom('hr', contact)}
                />

                <ContactSection
                    title="Finance Contact Details"
                    description={<p className="text-sm text-primary italic">Key contact(s) that will handle <strong>invoicing related queries</strong></p>}
                    contacts={formData.financeContacts}
                    errors={formErrors.financeContacts}
                    onContactChange={(index, field, value) => {
                        const updatedContacts = [...formData.financeContacts];
                        updatedContacts[index] = { ...updatedContacts[index], [field]: value };
                        setFormData({ ...formData, financeContacts: updatedContacts });
                    }}
                    onAddContact={() => {
                        setFormData({
                            ...formData,
                            financeContacts: [...formData.financeContacts, { name: "", email: "", phone: "" }],
                        });
                    }}
                    onRemoveContact={(index) => {
                        setFormData({
                            ...formData,
                            financeContacts: formData.financeContacts.filter((_: any, i: number) => i !== index),
                        });
                    }}
                    submitting={saving}
                    sectionKey="finance"
                    availableContacts={{
                        hr: formData.hrContacts,
                        payroll: formData.payrollContacts
                    }}
                    onCopyFrom={(contact) => handleCopyFrom('finance', contact)}
                />

                <ContactSection
                    title="Payroll Contact Details"
                    description={<p className="text-sm text-primary italic">Key contact(s) that will handle <strong>payroll and salary sacrifice</strong> related queries</p>}
                    contacts={formData.payrollContacts}
                    errors={formErrors.payrollContacts}
                    onContactChange={(index, field, value) => {
                        const updatedContacts = [...formData.payrollContacts];
                        updatedContacts[index] = { ...updatedContacts[index], [field]: value };
                        setFormData({ ...formData, payrollContacts: updatedContacts });
                    }}
                    onAddContact={() => {
                        setFormData({
                            ...formData,
                            payrollContacts: [...formData.payrollContacts, { name: "", email: "", phone: "" }],
                        });
                    }}
                    onRemoveContact={(index) => {
                        setFormData({
                            ...formData,
                            payrollContacts: formData.payrollContacts.filter((_: any, i: number) => i !== index),
                        });
                    }}
                    submitting={saving}
                    sectionKey="payroll"
                    availableContacts={{
                        hr: formData.hrContacts,
                        finance: formData.financeContacts
                    }}
                    onCopyFrom={(contact) => handleCopyFrom('payroll', contact)}
                />

                <div className="flex justify-end gap-3 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border rounded-md hover:bg-gray-100"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        disabled={saving}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-blue-300"
                    >
                        {saving ? 'Saving...' : 'Save Changes'}
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default EditContactsModal; 