import React from 'react';
import { Switch } from '@mui/material';

interface BatchProcessingToggleProps {
    isEnabled: boolean;
    onToggle: (enabled: boolean) => void;
    label: string;
}

const BatchProcessingToggle: React.FC<BatchProcessingToggleProps> = ({ isEnabled, onToggle, label }) => {
    return (
        <div className="flex flex-row items-center  bg-white justify-center rounded-lg px-2 border">

            <p >
                {label}
            </p>
            <Switch
                checked={isEnabled}
                onChange={(e) => onToggle(e.target.checked)}
                color="primary"
            />
        </div>
    );
};

export default BatchProcessingToggle; 